import { updateAssetsQueryDataByUpdatingAsset } from "domains/assets/api/updateAssetsQueryByUpdatingAsset";
import { updateAssetsQueryByUploadingAsset } from "domains/assets/api/updateAssetsQueryByUploadingAsset";
import { updateAssetsQueryDataByCopyingAsset } from "domains/assets/api/updateAssetsQueryDataByCopyingAsset";
import { updateAssetsQueryDataByUpdatingCollections } from "domains/collections/api/updateQueryDataByUpdatingCollections";
import { updateQueryDataByUpdatingDescription } from "domains/generators/api/updateQueryDataByUpdatingDescription";
import { inferencesTags } from "domains/inference/api/endpoints";
import { updateQueryDataByDeletingImages } from "domains/inference/api/updateQueryDataByDeletingImages";
import { updateQueryDataByUpdatingTags } from "domains/tags/api/updateQueryDataByUpdatingTags";
import { Endpoints } from "infra/store/apiSlice";
import { isEqual, unionBy } from "lodash";

export const assetsTags = {
  asset: "asset",
} as const;

export const assetsEndpoints: Endpoints = {
  getAssets: {
    merge(existing, incoming) {
      existing.assets = unionBy(existing.assets, incoming.assets, "id");

      // Has not reached the end of the pagination
      if (!existing?.assets || existing.nextPaginationToken) {
        existing.nextPaginationToken = incoming.nextPaginationToken;
      }
    },
    serializeQueryArgs: ({ endpointName, queryArgs }) => {
      return (
        endpointName +
        queryArgs.teamId +
        queryArgs.inferenceId +
        queryArgs.modelId +
        queryArgs.rootAssetId +
        queryArgs.type +
        queryArgs.collectionId +
        queryArgs.sortBy +
        queryArgs.sortDirection
      );
    },
    forceRefetch({ currentArg, previousArg }) {
      return !isEqual(currentArg, previousArg);
    },
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: assetsTags.asset,
          id: `assetId:${arg.rootAssetId}`,
        },
        {
          type: assetsTags.asset,
          id: `collectionId:${arg.collectionId}`,
        },
        {
          type: assetsTags.asset,
          id: `inferenceId:${arg.inferenceId}`,
        },
        {
          type: assetsTags.asset,
        },
        {
          type: assetsTags.asset,
          id: `type:${arg.type}`,
        },
      ];
    },
  },

  getAssetsByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        updateAssetsQueryDataByUpdatingAsset(data.asset, {
          dispatch,
          getState,
        });
      }
    },
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: assetsTags.asset,
          id: `assetId:${arg.assetId}`,
        },
      ];
    },
  },

  postAsset: {
    invalidatesTags: (_result, _error, arg) => {
      if (arg.body.canvas) {
        return [
          {
            type: assetsTags.asset,
            id: "type:canvas",
          },
        ];
      }
      return [];
    },
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data && !arg.body.canvas) {
        updateAssetsQueryByUploadingAsset(
          data.asset,
          arg.body.collectionIds ?? [],
          {
            dispatch,
            getState,
          }
        );
      }
    },
  },

  putAssetByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      if (arg.body.description) {
        const undo = updateQueryDataByUpdatingDescription({
          assetId: arg.assetId,
          description: arg.body.description,
          dispatch,
          getState,
        }).undo;
        queryFulfilled.catch(() => undo());
      }
    },
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: assetsTags.asset,
          id: `assetId:${arg.assetId}`,
        },
      ];
    },
  },

  putAssetsTagsByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateQueryDataByUpdatingTags(
        arg.teamId ?? "",
        "asset",
        arg.assetId,
        arg.body.add ?? [],
        arg.body.delete ?? [],
        {
          dispatch,
          getState,
        }
      ).undo;
      queryFulfilled.catch(() => undo());
    },
  },

  deleteAsset: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateQueryDataByDeletingImages(arg.body.assetIds, {
        dispatch,
        getState,
      }).undo;

      queryFulfilled.catch(() => undo());
    },

    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: inferencesTags.inference,
        },
        ...arg.body.assetIds.map((assetId) => ({
          type: assetsTags.asset,
          id: `assetId:${assetId}`,
        })),
      ];
    },
  },

  putImagesPixelate: {
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: assetsTags.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.assetId}`,
        },
      ];
    },
  },

  putImageUpscale: {
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: assetsTags.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.assetId}`,
        },
      ];
    },
  },

  putImagesEraseBackground: {
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: assetsTags.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.assetId}`,
        },
      ];
    },
  },

  copyAssetByAssetId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        updateAssetsQueryDataByCopyingAsset(data.asset, arg.assetId, {
          dispatch,
          getState,
        });
      }
    },
  },

  putAssetsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateAssetsQueryDataByUpdatingCollections(
        arg.teamId,
        "add",
        arg.body.assetIds,
        arg.collectionId,
        {
          dispatch,
          getState,
        }
      ).undo;

      queryFulfilled.catch(() => undo());
    },
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: assetsTags.asset,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },

  deleteAssetsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateAssetsQueryDataByUpdatingCollections(
        arg.teamId,
        "remove",
        arg.body.assetIds,
        arg.collectionId,
        {
          dispatch,
          getState,
        }
      ).undo;

      queryFulfilled.catch(() => undo());
    },
  },
};
