import {
  ButtonsField,
  SwitchField,
} from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomEditPanelCommons";
import Button from "domains/ui/components/Button";

import { VStack } from "@chakra-ui/react";

const SCALING_FACTORS = [2, 4];

interface UpscaleSettingsProps {
  scalingFactor: number;
  setScalingFactor: React.Dispatch<React.SetStateAction<number>>;
  forceFaceRestoration: boolean;
  setForceFaceRestoration: React.Dispatch<React.SetStateAction<boolean>>;
  photorealist: boolean;
  setPhotorealist: React.Dispatch<React.SetStateAction<boolean>>;
  magic: boolean;
  setMagic: React.Dispatch<React.SetStateAction<boolean>>;
  onUpscale: () => void;
  isUpscaleLoading: boolean;
  withMagicUpscale?: boolean;
}

export default function AssetUpscaleSettings({
  scalingFactor,
  setScalingFactor,
  forceFaceRestoration,
  setForceFaceRestoration,
  photorealist,
  setPhotorealist,
  magic,
  setMagic,
  onUpscale,
  isUpscaleLoading,
  withMagicUpscale = true,
}: UpscaleSettingsProps) {
  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <ButtonsField
        title={"Increase Size"}
        options={SCALING_FACTORS}
        selectedOption={scalingFactor}
        setSelectedOption={setScalingFactor}
        textToAppend={"x"}
      />
      <SwitchField
        title={"Face Restoration"}
        isChecked={forceFaceRestoration}
        setIsChecked={setForceFaceRestoration}
      />
      <SwitchField
        title={"Photo Optimized"}
        isChecked={photorealist}
        setIsChecked={setPhotorealist}
      />
      {withMagicUpscale && (
        <SwitchField
          title={"Enhanced Upscale"}
          isChecked={magic}
          setIsChecked={setMagic}
        />
      )}
      <Button onClick={onUpscale} isLoading={isUpscaleLoading} w={"100%"}>
        Upscale
      </Button>
    </VStack>
  );
}
