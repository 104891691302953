import React, { useCallback, useState } from "react";
import { useRouter } from "next/router";
import { ACTIONS } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import Button from "domains/ui/components/Button";
import ColorPicker from "domains/ui/components/ColorPicker";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  usePutImagesEraseBackgroundMutation,
} from "infra/api/generated/api";
import { Color } from "react-color";

import {
  Box,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";

export interface AssetZoomEraseBackgroundProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  updateCurrentAction: (action: ACTIONS | undefined) => void;
}

const AssetZoomEraseBackground = ({
  asset,
  updateCurrentAction,
}: AssetZoomEraseBackgroundProps) => {
  const router = useRouter();
  const { infoToast, successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { selectedTeam } = useTeamContext();
  const { showLimitModal } = usePlanContext();

  const [color, setColor] = useState<Color | undefined>();

  const [removeBackgroundTrigger, { isLoading: removeBackgroundIsLoading }] =
    usePutImagesEraseBackgroundMutation();

  const handleRemoveBackground = useCallback(async () => {
    if (!asset) {
      return;
    }
    infoToast({
      title: "Removing background...",
    });

    try {
      const imageWithRemovedBackground = await removeBackgroundTrigger({
        teamId: selectedTeam.id,
        body: {
          assetId: asset.id,
          returnImage: false,
          format: "png",
          backgroundColor: color as string,
        },
      }).unwrap();

      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: imageWithRemovedBackground.asset.id,
        },
      });
      updateCurrentAction(undefined);

      successToast({
        title: "Background removed",
      });

      Track(AnalyticsEvents.ImageLibrary.RemovedBackground, {
        assetId: asset.id,
        color: color ?? "transparent",
      });
    } catch (error) {
      handleApiError(error, "Error removing background", {
        quota: () => {
          showLimitModal("planBackgroundRemovals");
        },
      });
    }
  }, [
    updateCurrentAction,
    router,
    asset,
    infoToast,
    removeBackgroundTrigger,
    selectedTeam.id,
    color,
    successToast,
    handleApiError,
    showLimitModal,
  ]);

  const handleSelectColor = useCallback(
    (color: string, onClose: () => void) => {
      setColor(color);
      onClose();
    },
    []
  );

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <Popover>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <ScenarioInput
                setValue={(value: string) => {
                  if (value === "") {
                    setColor(undefined);
                  }
                }}
                leftComponent={
                  <Box
                    className={color ? undefined : "checkerboard"}
                    overflow="hidden"
                    w="5"
                    h="5"
                    borderRadius="full"
                    bgColor={
                      typeof color === "string" ? color : `rgba(0, 0, 0, 0)`
                    }
                  />
                }
                value={color as string}
                placeholder={"Transparent"}
              />
            </PopoverTrigger>
            <PopoverContent borderWidth={0}>
              <ColorPicker
                withAlpha={true}
                color={color}
                onChange={(color: any) => setColor(color.hex)}
                onSubmit={(color: any) => handleSelectColor(color, onClose)}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
      <Button
        onClick={handleRemoveBackground}
        isLoading={removeBackgroundIsLoading}
        w={"100%"}
      >
        Remove Background
      </Button>
    </VStack>
  );
};

export default AssetZoomEraseBackground;
