import { PostSearchApiTransformedResponse } from "domains/search/interfaces/Search";

import { api } from "../baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAssets: build.query<GetAssetsApiResponse, GetAssetsApiArg>({
      query: (queryArg) => ({
        url: `/assets`,
        params: {
          updatedBefore: queryArg.updatedBefore,
          sortDirection: queryArg.sortDirection,
          teamId: queryArg.teamId,
          inferenceId: queryArg.inferenceId,
          modelId: queryArg.modelId,
          updatedAfter: queryArg.updatedAfter,
          createdBefore: queryArg.createdBefore,
          sortBy: queryArg.sortBy,
          createdAfter: queryArg.createdAfter,
          pageSize: queryArg.pageSize,
          rootAssetId: queryArg.rootAssetId,
          type: queryArg["type"],
          paginationToken: queryArg.paginationToken,
          collectionId: queryArg.collectionId,
        },
      }),
    }),
    postAsset: build.mutation<PostAssetApiResponse, PostAssetApiArg>({
      query: (queryArg) => ({
        url: `/assets`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteAsset: build.mutation<DeleteAssetApiResponse, DeleteAssetApiArg>({
      query: (queryArg) => ({
        url: `/assets`,
        method: "DELETE",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    downloadAssets: build.mutation<
      DownloadAssetsApiResponse,
      DownloadAssetsApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/download`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getAssetsByAssetId: build.query<
      GetAssetsByAssetIdApiResponse,
      GetAssetsByAssetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/${queryArg.assetId}`,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putAssetByAssetId: build.mutation<
      PutAssetByAssetIdApiResponse,
      PutAssetByAssetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/${queryArg.assetId}`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    copyAssetByAssetId: build.mutation<
      CopyAssetByAssetIdApiResponse,
      CopyAssetByAssetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/${queryArg.assetId}/copy`,
        method: "POST",
        params: { teamId: queryArg.teamId },
      }),
    }),
    lockAssetByAssetId: build.mutation<
      LockAssetByAssetIdApiResponse,
      LockAssetByAssetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/${queryArg.assetId}/lock`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getCanvasAssetSnapshots: build.query<
      GetCanvasAssetSnapshotsApiResponse,
      GetCanvasAssetSnapshotsApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/${queryArg.assetId}/snapshots`,
        params: {
          teamId: queryArg.teamId,
          pageSize: queryArg.pageSize,
          paginationToken: queryArg.paginationToken,
        },
      }),
    }),
    putAssetsTagsByAssetId: build.mutation<
      PutAssetsTagsByAssetIdApiResponse,
      PutAssetsTagsByAssetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/${queryArg.assetId}/tags`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    unlockAssetByAssetId: build.mutation<
      UnlockAssetByAssetIdApiResponse,
      UnlockAssetByAssetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/assets/${queryArg.assetId}/unlock`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getCollections: build.query<
      GetCollectionsApiResponse,
      GetCollectionsApiArg
    >({
      query: (queryArg) => ({
        url: `/collections`,
        params: {
          pageSize: queryArg.pageSize,
          teamId: queryArg.teamId,
          paginationToken: queryArg.paginationToken,
        },
      }),
    }),
    postCollection: build.mutation<
      PostCollectionApiResponse,
      PostCollectionApiArg
    >({
      query: (queryArg) => ({
        url: `/collections`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getCollectionsByCollectionId: build.query<
      GetCollectionsByCollectionIdApiResponse,
      GetCollectionsByCollectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}`,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putCollectionsByCollectionId: build.mutation<
      PutCollectionsByCollectionIdApiResponse,
      PutCollectionsByCollectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteCollectionsByCollectionId: build.mutation<
      DeleteCollectionsByCollectionIdApiResponse,
      DeleteCollectionsByCollectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}`,
        method: "DELETE",
        params: { teamId: queryArg.teamId },
      }),
    }),
    putAssetsByCollectionId: build.mutation<
      PutAssetsByCollectionIdApiResponse,
      PutAssetsByCollectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/assets`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteAssetsByCollectionId: build.mutation<
      DeleteAssetsByCollectionIdApiResponse,
      DeleteAssetsByCollectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/assets`,
        method: "DELETE",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putModelsByCollectionId: build.mutation<
      PutModelsByCollectionIdApiResponse,
      PutModelsByCollectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/models`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteModelsByCollectionId: build.mutation<
      DeleteModelsByCollectionIdApiResponse,
      DeleteModelsByCollectionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/collections/${queryArg.collectionId}/models`,
        method: "DELETE",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putImagesCaptioning: build.mutation<
      PutImagesCaptioningApiResponse,
      PutImagesCaptioningApiArg
    >({
      query: (queryArg) => ({
        url: `/images/captioning`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putImagesDetection: build.mutation<
      PutImagesDetectionApiResponse,
      PutImagesDetectionApiArg
    >({
      query: (queryArg) => ({
        url: `/images/detection`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putImagesEraseBackground: build.mutation<
      PutImagesEraseBackgroundApiResponse,
      PutImagesEraseBackgroundApiArg
    >({
      query: (queryArg) => ({
        url: `/images/erase-background`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putImagesPixelate: build.mutation<
      PutImagesPixelateApiResponse,
      PutImagesPixelateApiArg
    >({
      query: (queryArg) => ({
        url: `/images/pixelate`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putImagesRemoveBackground: build.mutation<
      PutImagesRemoveBackgroundApiResponse,
      PutImagesRemoveBackgroundApiArg
    >({
      query: (queryArg) => ({
        url: `/images/remove-background`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putImageUpscale: build.mutation<
      PutImageUpscaleApiResponse,
      PutImageUpscaleApiArg
    >({
      query: (queryArg) => ({
        url: `/images/upscale`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getInferences: build.query<GetInferencesApiResponse, GetInferencesApiArg>({
      query: (queryArg) => ({
        url: `/inferences`,
        params: {
          pageSize: queryArg.pageSize,
          teamId: queryArg.teamId,
          paginationToken: queryArg.paginationToken,
        },
      }),
    }),
    putMagicPrompt: build.mutation<
      PutMagicPromptApiResponse,
      PutMagicPromptApiArg
    >({
      query: (queryArg) => ({
        url: `/magic-prompt`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getMe: build.query<GetMeApiResponse, GetMeApiArg>({
      query: () => ({ url: `/me` }),
    }),
    putMe: build.mutation<PutMeApiResponse, PutMeApiArg>({
      query: (queryArg) => ({ url: `/me`, method: "PUT", body: queryArg.body }),
    }),
    deleteMe: build.mutation<DeleteMeApiResponse, DeleteMeApiArg>({
      query: () => ({ url: `/me`, method: "DELETE" }),
    }),
    getModels: build.query<GetModelsApiResponse, GetModelsApiArg>({
      query: (queryArg) => ({
        url: `/models`,
        params: {
          status: queryArg.status,
          privacy: queryArg.privacy,
          teamId: queryArg.teamId,
          pageSize: queryArg.pageSize,
          collectionId: queryArg.collectionId,
          paginationToken: queryArg.paginationToken,
        },
      }),
    }),
    postModels: build.mutation<PostModelsApiResponse, PostModelsApiArg>({
      query: (queryArg) => ({
        url: `/models`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getModelsByModelId: build.query<
      GetModelsByModelIdApiResponse,
      GetModelsByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}`,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putModelsByModelId: build.mutation<
      PutModelsByModelIdApiResponse,
      PutModelsByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteModelsByModelId: build.mutation<
      DeleteModelsByModelIdApiResponse,
      DeleteModelsByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}`,
        method: "DELETE",
        params: { teamId: queryArg.teamId },
      }),
    }),
    getModelsCategoriesByModelId: build.query<
      GetModelsCategoriesByModelIdApiResponse,
      GetModelsCategoriesByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/categories`,
        params: { teamId: queryArg.teamId },
      }),
    }),
    getModelsClassesByModelId: build.query<
      GetModelsClassesByModelIdApiResponse,
      GetModelsClassesByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/classes`,
        params: {
          category: queryArg.category,
          teamId: queryArg.teamId,
          pageSize: queryArg.pageSize,
          paginationToken: queryArg.paginationToken,
        },
      }),
    }),
    postModelsCopyByModelId: build.mutation<
      PostModelsCopyByModelIdApiResponse,
      PostModelsCopyByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/copy`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteModelsImagesByModelId: build.mutation<
      DeleteModelsImagesByModelIdApiResponse,
      DeleteModelsImagesByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/images`,
        method: "DELETE",
        params: { ids: queryArg.ids, teamId: queryArg.teamId },
      }),
    }),
    getModelsInferencesByModelId: build.query<
      GetModelsInferencesByModelIdApiResponse,
      GetModelsInferencesByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/inferences`,
        params: {
          teamId: queryArg.teamId,
          pageSize: queryArg.pageSize,
          paginationToken: queryArg.paginationToken,
        },
      }),
    }),
    postModelsInferencesByModelId: build.mutation<
      PostModelsInferencesByModelIdApiResponse,
      PostModelsInferencesByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/inferences`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteModelsInferencesByModelId: build.mutation<
      DeleteModelsInferencesByModelIdApiResponse,
      DeleteModelsInferencesByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/inferences`,
        method: "DELETE",
        params: {
          inferenceIds: queryArg.inferenceIds,
          teamId: queryArg.teamId,
        },
      }),
    }),
    deleteModelsInferencesAllByModelId: build.mutation<
      DeleteModelsInferencesAllByModelIdApiResponse,
      DeleteModelsInferencesAllByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/inferences/all`,
        method: "DELETE",
        params: { teamId: queryArg.teamId },
      }),
    }),
    getModelsInferencesByModelIdAndInferenceId: build.query<
      GetModelsInferencesByModelIdAndInferenceIdApiResponse,
      GetModelsInferencesByModelIdAndInferenceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/inferences/${queryArg.inferenceId}`,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteModelsInferencesByModelIdAndInferenceId: build.mutation<
      DeleteModelsInferencesByModelIdAndInferenceIdApiResponse,
      DeleteModelsInferencesByModelIdAndInferenceIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/inferences/${queryArg.inferenceId}`,
        method: "DELETE",
        params: { teamId: queryArg.teamId },
      }),
    }),
    getModelsInferencesImagesByModelIdAndInferenceIdAndImageId: build.query<
      GetModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiResponse,
      GetModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/inferences/${queryArg.inferenceId}/images/${queryArg.imageId}`,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteModelsInferencesImagesByModelIdAndInferenceIdAndImageId:
      build.mutation<
        DeleteModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiResponse,
        DeleteModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiArg
      >({
        query: (queryArg) => ({
          url: `/models/${queryArg.modelId}/inferences/${queryArg.inferenceId}/images/${queryArg.imageId}`,
          method: "DELETE",
          params: { teamId: queryArg.teamId },
        }),
      }),
    putModelsTagsByModelId: build.mutation<
      PutModelsTagsByModelIdApiResponse,
      PutModelsTagsByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/tags`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putModelsTrainByModelId: build.mutation<
      PutModelsTrainByModelIdApiResponse,
      PutModelsTrainByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/train`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    postModelsTrainingImagesByModelId: build.mutation<
      PostModelsTrainingImagesByModelIdApiResponse,
      PostModelsTrainingImagesByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/training-images`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putModelsTrainingImagesByModelIdAndTrainingImageId: build.mutation<
      PutModelsTrainingImagesByModelIdAndTrainingImageIdApiResponse,
      PutModelsTrainingImagesByModelIdAndTrainingImageIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/training-images/${queryArg.trainingImageId}`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    deleteModelsTrainingImagesByModelIdAndTrainingImageId: build.mutation<
      DeleteModelsTrainingImagesByModelIdAndTrainingImageIdApiResponse,
      DeleteModelsTrainingImagesByModelIdAndTrainingImageIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/training-images/${queryArg.trainingImageId}`,
        method: "DELETE",
        params: { teamId: queryArg.teamId },
      }),
    }),
    postModelsTransferByModelId: build.mutation<
      PostModelsTransferByModelIdApiResponse,
      PostModelsTransferByModelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/models/${queryArg.modelId}/transfer`,
        method: "POST",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    putPromptGenerator: build.mutation<
      PutPromptGeneratorApiResponse,
      PutPromptGeneratorApiArg
    >({
      query: (queryArg) => ({
        url: `/prompt-generator`,
        method: "PUT",
        body: queryArg.body,
        params: { teamId: queryArg.teamId },
      }),
    }),
    postSearch: build.query<PostSearchApiTransformedResponse, PostSearchApiArg>(
      {
        query: (queryArg) => ({
          url: `/search`,
          method: "POST",
          body: queryArg.body,
          params: {
            pageSize: queryArg.pageSize,
            pageNumber: queryArg.pageNumber,
            teamId: queryArg.teamId,
          },
        }),
      }
    ),
    getTags: build.query<GetTagsApiResponse, GetTagsApiArg>({
      query: (queryArg) => ({
        url: `/tags`,
        params: {
          pageSize: queryArg.pageSize,
          teamId: queryArg.teamId,
          paginationToken: queryArg.paginationToken,
        },
      }),
    }),
    getTeams: build.query<GetTeamsApiResponse, GetTeamsApiArg>({
      query: () => ({ url: `/teams` }),
    }),
    postTeams: build.mutation<PostTeamsApiResponse, PostTeamsApiArg>({
      query: (queryArg) => ({
        url: `/teams`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getTeamsByTeamId: build.query<
      GetTeamsByTeamIdApiResponse,
      GetTeamsByTeamIdApiArg
    >({
      query: (queryArg) => ({ url: `/teams/${queryArg.teamId}` }),
    }),
    putTeamsByTeamId: build.mutation<
      PutTeamsByTeamIdApiResponse,
      PutTeamsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteTeamsByTeamId: build.mutation<
      DeleteTeamsByTeamIdApiResponse,
      DeleteTeamsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}`,
        method: "DELETE",
      }),
    }),
    postTeamsApiKeysByTeamId: build.mutation<
      PostTeamsApiKeysByTeamIdApiResponse,
      PostTeamsApiKeysByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/api-keys`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    putTeamsApiKeyByTeamIdAndKeyId: build.mutation<
      PutTeamsApiKeyByTeamIdAndKeyIdApiResponse,
      PutTeamsApiKeyByTeamIdAndKeyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/api-keys/${queryArg.keyId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteTeamsApiKeyByTeamIdAndKeyId: build.mutation<
      DeleteTeamsApiKeyByTeamIdAndKeyIdApiResponse,
      DeleteTeamsApiKeyByTeamIdAndKeyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/api-keys/${queryArg.keyId}`,
        method: "DELETE",
      }),
    }),
    deleteTeamsInvitationsByTeamId: build.mutation<
      DeleteTeamsInvitationsByTeamIdApiResponse,
      DeleteTeamsInvitationsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/invitations`,
        method: "DELETE",
        params: { hash: queryArg.hash },
      }),
    }),
    getTeamsLimitsByTeamId: build.query<
      GetTeamsLimitsByTeamIdApiResponse,
      GetTeamsLimitsByTeamIdApiArg
    >({
      query: (queryArg) => ({ url: `/teams/${queryArg.teamId}/limits` }),
    }),
    postTeamsMembersByTeamId: build.mutation<
      PostTeamsMembersByTeamIdApiResponse,
      PostTeamsMembersByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/members`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    putTeamsMembersByTeamIdAndMemberId: build.mutation<
      PutTeamsMembersByTeamIdAndMemberIdApiResponse,
      PutTeamsMembersByTeamIdAndMemberIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/members/${queryArg.memberId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteTeamsMembersByTeamIdAndMemberId: build.mutation<
      DeleteTeamsMembersByTeamIdAndMemberIdApiResponse,
      DeleteTeamsMembersByTeamIdAndMemberIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/members/${queryArg.memberId}`,
        method: "DELETE",
      }),
    }),
    getTeamsSubscriptionsByTeamId: build.query<
      GetTeamsSubscriptionsByTeamIdApiResponse,
      GetTeamsSubscriptionsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/subscriptions`,
        params: { paymentProvider: queryArg.paymentProvider },
      }),
    }),
    postTeamsSubscriptionsByTeamId: build.mutation<
      PostTeamsSubscriptionsByTeamIdApiResponse,
      PostTeamsSubscriptionsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/subscriptions`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    putTeamsSubscriptionsByTeamId: build.mutation<
      PutTeamsSubscriptionsByTeamIdApiResponse,
      PutTeamsSubscriptionsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/subscriptions`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteTeamsSubscriptionsByTeamId: build.mutation<
      DeleteTeamsSubscriptionsByTeamIdApiResponse,
      DeleteTeamsSubscriptionsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/subscriptions`,
        method: "DELETE",
        body: queryArg.body,
      }),
    }),
    postTeamsSubscriptionsApiByTeamId: build.mutation<
      PostTeamsSubscriptionsApiByTeamIdApiResponse,
      PostTeamsSubscriptionsApiByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/subscriptions/api`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getTeamsSubscriptionsCustomerPortalByTeamId: build.query<
      GetTeamsSubscriptionsCustomerPortalByTeamIdApiResponse,
      GetTeamsSubscriptionsCustomerPortalByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/subscriptions/customer-portal`,
        params: { paymentProvider: queryArg.paymentProvider },
      }),
    }),
    postTeamsSubscriptionsSeatsByTeamId: build.mutation<
      PostTeamsSubscriptionsSeatsByTeamIdApiResponse,
      PostTeamsSubscriptionsSeatsByTeamIdApiArg
    >({
      query: (queryArg) => ({
        url: `/teams/${queryArg.teamId}/subscriptions/seats`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsages: build.query<GetUsagesApiResponse, GetUsagesApiArg>({
      query: (queryArg) => ({
        url: `/usages`,
        params: {
          userId: queryArg.userId,
          timeRange: queryArg.timeRange,
          teamId: queryArg.teamId,
          startDate: queryArg.startDate,
          endDate: queryArg.endDate,
        },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as scenarioApi };
export type GetAssetsApiResponse = /** status 200 200 response */ {
  nextPaginationToken?: string;
  assets: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  }[];
};
export type GetAssetsApiArg = {
  /** Filter results to only return assets updated before the specified ISO string date (exclusive). Requires the sortBy parameter to be "updatedAt" */
  updatedBefore?: string;
  /** Sort results in ascending (asc) or descending (desc) order */
  sortDirection?: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** List assets generated from a specific inference */
  inferenceId?: string;
  /** List assets generated from all inferences coming from a specific model (this is not the training images) */
  modelId?: string;
  /** Filter results to only return assets updated after the specified ISO string date (exclusive). Requires the sortBy parameter to be "updatedAt" */
  updatedAfter?: string;
  /** Filter results to only return assets created before the specified ISO string date (exclusive). Requires the sortBy parameter to be "createdAt" */
  createdBefore?: string;
  /** Sort results by the createdAt or updatedAt */
  sortBy?: string;
  /** Filter results to only return assets created after the specified ISO string date (exclusive). Requires the sortBy parameter to be "createdAt" */
  createdAfter?: string;
  /** The number of items to return in the response. The default value is 10, maximum value is 100, minimum value is 1 */
  pageSize?: string;
  /** List all the children assets that were generated from a specific root asset */
  rootAssetId?: string;
  /** List all the assets of a specific type. Can be any of the following values: 'background-removal', 'inference-controlnet', 'inference-img2img', 'inference-inpaint', 'inference-txt2img', 'pixelization', 'uploaded', 'upscale', 'inference' or 'canvas'. When passing 'inference', assets with a type starting with "inference-" will be returned */
  type?: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
  collectionId?: string;
};
export type PostAssetApiResponse = /** status 200 200 response */ {
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type PostAssetApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    image?: string;
    canvas?: string;
    thumbnail?: string;
    hide?: boolean;
    collectionIds?: string[];
    name: string;
    parentId?: string;
  };
};
export type DeleteAssetApiResponse = /** status 200 200 response */ any;
export type DeleteAssetApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    assetIds: string[];
  };
};
export type DownloadAssetsApiResponse = /** status 200 200 response */ {
  jobId: string;
};
export type DownloadAssetsApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    query: {
      modelIds: string[];
      inferenceIds: string[];
      assetIds: string[];
    };
    options: {
      fileNameTemplate: string;
      flat?: boolean;
    };
  };
};
export type GetAssetsByAssetIdApiResponse = /** status 200 200 response */ {
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type GetAssetsByAssetIdApiArg = {
  /** The asset ID to retrieve */
  assetId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type PutAssetByAssetIdApiResponse = /** status 200 200 response */ {
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type PutAssetByAssetIdApiArg = {
  assetId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    lockId?: string;
    lockExpiresAt?: string;
    canvas?: string;
    thumbnail?: string;
    name?: string;
    description?: string;
    disableSnapshot?: boolean;
  };
};
export type CopyAssetByAssetIdApiResponse = /** status 200 200 response */ {
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type CopyAssetByAssetIdApiArg = {
  /** The ID of the asset to duplicate */
  assetId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type LockAssetByAssetIdApiResponse = /** status 200 200 response */ {
  lockId?: string;
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type LockAssetByAssetIdApiArg = {
  /** The ID of the canvas to lock */
  assetId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    lockExpiresAt: string;
  };
};
export type GetCanvasAssetSnapshotsApiResponse =
  /** status 200 200 response */ {
    snapshots: {
      takenAt: number;
      rawData: string;
      authorId: string;
      hash: string;
    }[];
    nextPaginationToken?: string;
  };
export type GetCanvasAssetSnapshotsApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** The number of items to return in the response. The default value is 10, maximum value is 100, minimum value is 10 */
  pageSize?: string;
  /** The ID of the canvas asset to list snapshots for */
  assetId: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
};
export type PutAssetsTagsByAssetIdApiResponse = /** status 200 200 response */ {
  deleted: string[];
  added: string[];
};
export type PutAssetsTagsByAssetIdApiArg = {
  /** The ID of the asset to update its tags */
  assetId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    add?: string[];
    delete?: string[];
  };
};
export type UnlockAssetByAssetIdApiResponse = /** status 200 200 response */ {
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type UnlockAssetByAssetIdApiArg = {
  /** The ID of the canvas to unlock */
  assetId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    lockId?: string;
    forceUnlock?: boolean;
  };
};
export type GetCollectionsApiResponse = /** status 200 200 response */ {
  nextPaginationToken?: string;
  collections: {
    createdAt: string;
    name: string;
    assetCount: number;
    id: string;
    ownerId: string;
    modelCount: number;
    itemCount: number;
    updatedAt: string;
  }[];
};
export type GetCollectionsApiArg = {
  /** The number of items to return in the response. The default value is 10, maximum value is 100, minimum value is 1 */
  pageSize?: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
};
export type PostCollectionApiResponse = /** status 200 200 response */ {
  collection: {
    createdAt: string;
    name: string;
    assetCount: number;
    id: string;
    ownerId: string;
    modelCount: number;
    itemCount: number;
    updatedAt: string;
  };
};
export type PostCollectionApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    name: string;
  };
};
export type GetCollectionsByCollectionIdApiResponse =
  /** status 200 200 response */ {
    collection: {
      createdAt: string;
      name: string;
      assetCount: number;
      id: string;
      ownerId: string;
      modelCount: number;
      itemCount: number;
      updatedAt: string;
    };
  };
export type GetCollectionsByCollectionIdApiArg = {
  /** The collection ID to retrieve */
  collectionId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type PutCollectionsByCollectionIdApiResponse =
  /** status 200 200 response */ {
    collection: {
      createdAt: string;
      name: string;
      assetCount: number;
      id: string;
      ownerId: string;
      modelCount: number;
      itemCount: number;
      updatedAt: string;
    };
  };
export type PutCollectionsByCollectionIdApiArg = {
  /** The collectionId to update */
  collectionId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    name: string;
  };
};
export type DeleteCollectionsByCollectionIdApiResponse =
  /** status 200 200 response */ any;
export type DeleteCollectionsByCollectionIdApiArg = {
  /** The collection ID to delete */
  collectionId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type PutAssetsByCollectionIdApiResponse =
  /** status 200 200 response */ {
    collection: {
      createdAt: string;
      name: string;
      assetCount: number;
      id: string;
      ownerId: string;
      modelCount: number;
      itemCount: number;
      updatedAt: string;
    };
  };
export type PutAssetsByCollectionIdApiArg = {
  /** The ID of the collection to add assets to */
  collectionId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    assetIds: string[];
  };
};
export type DeleteAssetsByCollectionIdApiResponse =
  /** status 200 200 response */ {
    collection: {
      createdAt: string;
      name: string;
      assetCount: number;
      id: string;
      ownerId: string;
      modelCount: number;
      itemCount: number;
      updatedAt: string;
    };
  };
export type DeleteAssetsByCollectionIdApiArg = {
  /** The ID of the collection to remove the assets from */
  collectionId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    assetIds: string[];
  };
};
export type PutModelsByCollectionIdApiResponse =
  /** status 200 200 response */ {
    collection: {
      createdAt: string;
      name: string;
      assetCount: number;
      id: string;
      ownerId: string;
      modelCount: number;
      itemCount: number;
      updatedAt: string;
    };
  };
export type PutModelsByCollectionIdApiArg = {
  /** The ID of the collection to add models to */
  collectionId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    modelIds: string[];
  };
};
export type DeleteModelsByCollectionIdApiResponse =
  /** status 200 200 response */ {
    collection: {
      createdAt: string;
      name: string;
      assetCount: number;
      id: string;
      ownerId: string;
      modelCount: number;
      itemCount: number;
      updatedAt: string;
    };
  };
export type DeleteModelsByCollectionIdApiArg = {
  /** The ID of the collection to remove the models from */
  collectionId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    modelIds: string[];
  };
};
export type PutImagesCaptioningApiResponse = /** status 200 200 response */ {
  caption: string;
};
export type PutImagesCaptioningApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    image?: string;
    assetId?: string;
    name?: string;
  };
};
export type PutImagesDetectionApiResponse = /** status 200 200 response */ {
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type PutImagesDetectionApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    image?: string;
    modality:
      | "canny"
      | "depth"
      | "lineart"
      | "lines"
      | "normal-map"
      | "pose"
      | "scribble"
      | "seg";
    assetId?: string;
    name?: string;
    parameters?: {
      includeHands?: boolean;
      includeFace?: boolean;
      lowThreshold?: number;
      highThreshold?: number;
    };
  };
};
export type PutImagesEraseBackgroundApiResponse =
  /** status 200 200 response */ {
    image?: string;
    asset: {
      metadata: {
        magic?: boolean;
        rootParentId?: string;
        thumbnail?: {
          assetId: string;
          url: string;
        };
        modality?: string;
        seed?: string;
        modelId?: string;
        kind: "image" | "json";
        inferenceId?: string;
        scalingFactor?: number;
        type:
          | "background-removal"
          | "canvas"
          | "detection"
          | "inference-controlnet"
          | "inference-img2img"
          | "inference-inpaint"
          | "inference-txt2img"
          | "pixelization"
          | "uploaded"
          | "upscale";
        parentId?: string;
        negativePrompt?: string;
        lockExpiresAt?: string;
        forceFaceRestoration?: boolean;
        size?: number;
        name?: string;
        width?: number;
        progressPercent?: number;
        photorealist?: boolean;
        prompt?: string;
        height?: number;
      };
      nsfw?: string[];
      description?: string;
      privacy: "private" | "public" | "unlisted";
      mimeType: string;
      authorId: string;
      ownerId: string;
      url: string;
      tags: string[];
      createdAt: string;
      collectionIds: string[];
      id: string;
      status: "error" | "pending" | "success";
      updatedAt: string;
    };
  };
export type PutImagesEraseBackgroundApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    image?: string;
    backgroundColor?: string;
    assetId?: string;
    format?: "jpeg" | "png";
    name?: string;
    returnImage?: boolean;
  };
};
export type PutImagesPixelateApiResponse = /** status 200 200 response */ any;
export type PutImagesPixelateApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    image?: string;
    assetId?: string;
    pixelGridSize: number;
    name?: string;
    removeNoise: boolean;
    removeBackground: boolean;
    returnImage: boolean;
    colorPalette?: number[][];
    colorPaletteSize?: number;
  };
};
export type PutImagesRemoveBackgroundApiResponse =
  /** status 200 200 response */ any;
export type PutImagesRemoveBackgroundApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    backgroundColor?: string;
    data: string;
    format?: "jpeg" | "png";
  };
};
export type PutImageUpscaleApiResponse = /** status 200 200 response */ {
  image?: string;
  asset: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
};
export type PutImageUpscaleApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    magic?: boolean;
    image: string;
    forceFaceRestoration?: boolean;
    assetId: string;
    name: string;
    scalingFactor?: number;
    returnImage?: boolean;
    photorealist?: boolean;
  };
};
export type GetInferencesApiResponse = /** status 200 200 response */ {
  nextPaginationToken?: string;
  inferences: {
    createdAt: string;
    images: {
      seed: string;
      id: string;
      url: string;
    }[];
    modelId: string;
    progress?: number;
    id: string;
    imagesNumber: number;
    authorId: string;
    displayPrompt: string;
    ownerId: string;
    parameters: {
      image?: string;
      imageId?: string;
      modality?: string;
      seed?: string;
      strength?: number;
      hideResults?: boolean;
      maskId?: string;
      type: "controlnet" | "img2img" | "inpaint" | "txt2img";
      negativePrompt?: string;
      scheduler?:
        | "DDIMInverseScheduler"
        | "DDIMScheduler"
        | "DDPMScheduler"
        | "DEISMultistepScheduler"
        | "DPMSolverMultistepInverseScheduler"
        | "DPMSolverMultistepScheduler"
        | "DPMSolverSDEScheduler"
        | "DPMSolverSinglestepScheduler"
        | "EulerAncestralDiscreteScheduler"
        | "EulerDiscreteScheduler"
        | "HeunDiscreteScheduler"
        | "IPNDMScheduler"
        | "KDPM2AncestralDiscreteScheduler"
        | "KDPM2DiscreteScheduler"
        | "KarrasVeScheduler"
        | "LMSDiscreteScheduler"
        | "PNDMScheduler"
        | "ScoreSdeVeScheduler"
        | "UniPCMultistepScheduler";
      intermediateImages?: boolean;
      disableMerging?: boolean;
      disableModalityDetection?: boolean;
      guidance?: number;
      numInferenceSteps?: number;
      numSamples?: number;
      tokenMerging?: number;
      width?: number;
      prompt: string;
      height?: number;
      mask?: string;
    };
    userId: string;
    status: "failed" | "in-progress" | "new" | "queued" | "succeeded";
  }[];
};
export type GetInferencesApiArg = {
  /** The number of items to return in the response. The default value is 10, maximum value is 50, minimum value is 1 */
  pageSize?: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
};
export type PutMagicPromptApiResponse = /** status 200 200 response */ {
  newPredictions: string[];
};
export type PutMagicPromptApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    minNewTokens?: number;
    seed?: string;
    maxNewTokens?: number;
    numResults?: number;
    prompt: string;
  };
};
export type GetMeApiResponse = /** status 200 200 response */ {
  user: {
    settings: object;
    teams: string[];
    fullName?: string;
    createdAt: string;
    features: string[];
    phoneNumber?: string;
    countryCode?: string;
    referralCode: string;
    id: string;
    email: string;
    notifications?: {
      "model-transfer": {
        mobile: boolean;
        email: boolean;
      };
    };
    status: "active" | "deletion-in-progress" | "error";
    updatedAt: string;
    username: string;
  };
};
export type GetMeApiArg = void;
export type PutMeApiResponse = /** status 200 200 response */ any;
export type PutMeApiArg = {
  body: {
    settings?: object;
    phoneNumber?: string;
    countryCode?: string;
    fullName?: string;
    notifications?: {
      "model-transfer": {
        mobile: boolean;
        email: boolean;
      };
    };
  };
};
export type DeleteMeApiResponse = /** status 202 202 response */ any;
export type DeleteMeApiArg = void;
export type GetModelsApiResponse = /** status 200 200 response */ {
  models: {
    thumbnail?: {
      assetId: string;
      url: string;
    };
    parentModelId?: string;
    trainingImages: {
      createdAt: string;
      downloadUrl: string;
      name: string;
      description?: string;
      id: string;
    }[];
    trainingImagesNumber: number;
    modelKeyword?: string;
    privacy: "private" | "public" | "unlisted";
    authorId?: string;
    ownerId?: string;
    type: "sd-1_5" | "sd-xl" | "sd-xl-composition" | "sd-xl-lora";
    userId?: string;
    tags: string[];
    negativePromptEmbedding?: string;
    createdAt: string;
    concepts?: {
      modelId: string;
      scale: number;
    }[];
    collectionIds: string[];
    name?: string;
    promptEmbedding?: string;
    trainingProgress?: {
      stage:
        | "pending"
        | "queued-for-generate-class"
        | "queued-for-train"
        | "running-generate-class"
        | "running-train"
        | "starting-generate-class"
        | "starting-train";
      progress?: number;
      startedAt?: number;
      position?: number;
      updatedAt: number;
    };
    id: string;
    class?: {
      modelId: string;
      name: string;
      category: string;
      thumbnails: string[];
      prompt: string;
      conceptPrompt: string;
      slug: string;
      status: "published" | "unpublished";
    };
    parameters?: {
      adamWeightDecay?: number;
      maxGradNorm?: number;
      adamBeta2?: number;
      adamBeta1?: number;
      adamEpsilon?: number;
      numClassImages?: number;
      priorLossWeight?: number;
      seed?: number;
      lrWarmupSteps?: number;
      learningRate?: number;
      randomCrop?: boolean;
      classPrompt?: string;
      trainTextEncoder?: boolean;
      lrScheduler?:
        | "constant"
        | "constant-with-warmup"
        | "cosine"
        | "cosine-with-restarts"
        | "linear"
        | "polynomial";
      conceptPrompt?: string;
      maxTrainSteps?: number;
    };
    status: "failed" | "new" | "trained" | "training";
    updatedAt: string;
  }[];
  nextPaginationToken?: string;
};
export type GetModelsApiArg = {
  /** The status of the models to return */
  status?: string;
  /** The privacy of the models to return. The default value is `private`, possible values are `private` and `public` */
  privacy?: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** The number of items to return in the response. The default value is 10, maximum value is 50, minimum value is 1 */
  pageSize?: string;
  /** When provided, only the models in the Collection will be returned */
  collectionId?: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
};
export type PostModelsApiResponse = /** status 200 200 response */ {
  model: {
    thumbnail?: {
      assetId: string;
      url: string;
    };
    parentModelId?: string;
    trainingImages: {
      createdAt: string;
      downloadUrl: string;
      name: string;
      description?: string;
      id: string;
    }[];
    trainingImagesNumber: number;
    modelKeyword?: string;
    privacy: "private" | "public" | "unlisted";
    authorId?: string;
    ownerId?: string;
    type: "sd-1_5" | "sd-xl" | "sd-xl-composition" | "sd-xl-lora";
    userId?: string;
    tags: string[];
    negativePromptEmbedding?: string;
    createdAt: string;
    concepts?: {
      modelId: string;
      scale: number;
    }[];
    collectionIds: string[];
    name?: string;
    promptEmbedding?: string;
    trainingProgress?: {
      stage:
        | "pending"
        | "queued-for-generate-class"
        | "queued-for-train"
        | "running-generate-class"
        | "running-train"
        | "starting-generate-class"
        | "starting-train";
      progress?: number;
      startedAt?: number;
      position?: number;
      updatedAt: number;
    };
    id: string;
    class?: {
      modelId: string;
      name: string;
      category: string;
      thumbnails: string[];
      prompt: string;
      conceptPrompt: string;
      slug: string;
      status: "published" | "unpublished";
    };
    parameters?: {
      adamWeightDecay?: number;
      maxGradNorm?: number;
      adamBeta2?: number;
      adamBeta1?: number;
      adamEpsilon?: number;
      numClassImages?: number;
      priorLossWeight?: number;
      seed?: number;
      lrWarmupSteps?: number;
      learningRate?: number;
      randomCrop?: boolean;
      classPrompt?: string;
      trainTextEncoder?: boolean;
      lrScheduler?:
        | "constant"
        | "constant-with-warmup"
        | "cosine"
        | "cosine-with-restarts"
        | "linear"
        | "polynomial";
      conceptPrompt?: string;
      maxTrainSteps?: number;
    };
    status: "failed" | "new" | "trained" | "training";
    updatedAt: string;
  };
};
export type PostModelsApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    name?: string;
    classSlug?: string;
  };
};
export type GetModelsByModelIdApiResponse = /** status 200 200 response */ {
  model: {
    thumbnail?: {
      assetId: string;
      url: string;
    };
    parentModelId?: string;
    trainingImages?: {
      createdAt: string;
      downloadUrl: string;
      name: string;
      description?: string;
      id: string;
    }[];
    trainingImagesNumber: number;
    modelKeyword?: string;
    privacy: "private" | "public" | "unlisted";
    authorId?: string;
    ownerId?: string;
    type: "sd-1_5" | "sd-xl" | "sd-xl-composition" | "sd-xl-lora";
    userId?: string;
    tags: string[];
    negativePromptEmbedding?: string;
    createdAt: string;
    concepts?: {
      modelId: string;
      scale: number;
    }[];
    collectionIds: string[];
    name?: string;
    promptEmbedding?: string;
    trainingProgress?: {
      stage:
        | "pending"
        | "queued-for-generate-class"
        | "queued-for-train"
        | "running-generate-class"
        | "running-train"
        | "starting-generate-class"
        | "starting-train";
      progress?: number;
      startedAt?: number;
      position?: number;
      updatedAt: number;
    };
    id: string;
    class?: {
      modelId: string;
      name: string;
      category: string;
      thumbnails: string[];
      prompt: string;
      conceptPrompt: string;
      slug: string;
      status: "published" | "unpublished";
    };
    parameters?: {
      adamWeightDecay?: number;
      maxGradNorm?: number;
      adamBeta2?: number;
      adamBeta1?: number;
      adamEpsilon?: number;
      numClassImages?: number;
      priorLossWeight?: number;
      seed?: number;
      lrWarmupSteps?: number;
      learningRate?: number;
      randomCrop?: boolean;
      classPrompt?: string;
      trainTextEncoder?: boolean;
      lrScheduler?:
        | "constant"
        | "constant-with-warmup"
        | "cosine"
        | "cosine-with-restarts"
        | "linear"
        | "polynomial";
      conceptPrompt?: string;
      maxTrainSteps?: number;
    };
    status: "failed" | "new" | "trained" | "training";
    updatedAt: string;
  };
};
export type GetModelsByModelIdApiArg = {
  /** The modelId to retrieve */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type PutModelsByModelIdApiResponse = /** status 200 200 response */ {
  model: {
    thumbnail?: {
      assetId: string;
      url: string;
    };
    parentModelId?: string;
    trainingImages: {
      createdAt: string;
      downloadUrl: string;
      name: string;
      description?: string;
      id: string;
    }[];
    trainingImagesNumber: number;
    modelKeyword?: string;
    privacy: "private" | "public" | "unlisted";
    authorId?: string;
    ownerId?: string;
    type: "sd-1_5" | "sd-xl" | "sd-xl-composition" | "sd-xl-lora";
    userId?: string;
    tags: string[];
    negativePromptEmbedding?: string;
    createdAt: string;
    concepts?: {
      modelId: string;
      scale: number;
    }[];
    collectionIds: string[];
    name?: string;
    promptEmbedding?: string;
    trainingProgress?: {
      stage:
        | "pending"
        | "queued-for-generate-class"
        | "queued-for-train"
        | "running-generate-class"
        | "running-train"
        | "starting-generate-class"
        | "starting-train";
      progress?: number;
      startedAt?: number;
      position?: number;
      updatedAt: number;
    };
    id: string;
    class?: {
      modelId: string;
      name: string;
      category: string;
      thumbnails: string[];
      prompt: string;
      conceptPrompt: string;
      slug: string;
      status: "published" | "unpublished";
    };
    parameters?: {
      adamWeightDecay?: number;
      maxGradNorm?: number;
      adamBeta2?: number;
      adamBeta1?: number;
      adamEpsilon?: number;
      numClassImages?: number;
      priorLossWeight?: number;
      seed?: number;
      lrWarmupSteps?: number;
      learningRate?: number;
      randomCrop?: boolean;
      classPrompt?: string;
      trainTextEncoder?: boolean;
      lrScheduler?:
        | "constant"
        | "constant-with-warmup"
        | "cosine"
        | "cosine-with-restarts"
        | "linear"
        | "polynomial";
      conceptPrompt?: string;
      maxTrainSteps?: number;
    };
    status: "failed" | "new" | "trained" | "training";
    updatedAt: string;
  };
};
export type PutModelsByModelIdApiArg = {
  /** The modelId to update */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    negativePromptEmbedding?: string;
    thumbnail?: string;
    classSlug?: string;
    name?: string;
    promptEmbedding?: string;
    parameters?: {
      adamWeightDecay?: number;
      maxGradNorm?: number;
      adamBeta2?: number;
      adamBeta1?: number;
      adamEpsilon?: number;
      numClassImages?: number;
      priorLossWeight?: number;
      seed?: number;
      lrWarmupSteps?: number;
      learningRate?: number;
      randomCrop?: boolean;
      classPrompt?: string;
      trainTextEncoder?: boolean;
      lrScheduler?:
        | "constant"
        | "constant-with-warmup"
        | "cosine"
        | "cosine-with-restarts"
        | "linear"
        | "polynomial";
      conceptPrompt?: string;
      maxTrainSteps?: number;
    };
  };
};
export type DeleteModelsByModelIdApiResponse =
  /** status 200 200 response */ any;
export type DeleteModelsByModelIdApiArg = {
  /** The modelId to delete */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type GetModelsCategoriesByModelIdApiResponse =
  /** status 200 200 response */ {
    categories: {
      name: string;
      publishedClassesNumber: number;
      slug: string;
    }[];
  };
export type GetModelsCategoriesByModelIdApiArg = {
  /** The categories available for that modelId */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type GetModelsClassesByModelIdApiResponse =
  /** status 200 200 response */ {
    nextPaginationToken?: string;
    classes: {
      modelId: string;
      name: string;
      category: string;
      thumbnails: string[];
      prompt: string;
      conceptPrompt: string;
      slug: string;
      status: "published" | "unpublished";
    }[];
  };
export type GetModelsClassesByModelIdApiArg = {
  /** The modelId of the category */
  modelId: string;
  /** The category of the classes */
  category?: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** The number of items to return in the response. The default value is 10, maximum value is 50, minimum value is 1 */
  pageSize?: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
};
export type PostModelsCopyByModelIdApiResponse =
  /** status 200 200 response */ {
    model: {
      thumbnail?: {
        assetId: string;
        url: string;
      };
      parentModelId?: string;
      trainingImages: {
        createdAt: string;
        downloadUrl: string;
        name: string;
        description?: string;
        id: string;
      }[];
      trainingImagesNumber: number;
      modelKeyword?: string;
      privacy: "private" | "public" | "unlisted";
      authorId?: string;
      ownerId?: string;
      type: "sd-1_5" | "sd-xl" | "sd-xl-composition" | "sd-xl-lora";
      userId?: string;
      tags: string[];
      negativePromptEmbedding?: string;
      createdAt: string;
      concepts?: {
        modelId: string;
        scale: number;
      }[];
      collectionIds: string[];
      name?: string;
      promptEmbedding?: string;
      trainingProgress?: {
        stage:
          | "pending"
          | "queued-for-generate-class"
          | "queued-for-train"
          | "running-generate-class"
          | "running-train"
          | "starting-generate-class"
          | "starting-train";
        progress?: number;
        startedAt?: number;
        position?: number;
        updatedAt: number;
      };
      id: string;
      class?: {
        modelId: string;
        name: string;
        category: string;
        thumbnails: string[];
        prompt: string;
        conceptPrompt: string;
        slug: string;
        status: "published" | "unpublished";
      };
      parameters?: {
        adamWeightDecay?: number;
        maxGradNorm?: number;
        adamBeta2?: number;
        adamBeta1?: number;
        adamEpsilon?: number;
        numClassImages?: number;
        priorLossWeight?: number;
        seed?: number;
        lrWarmupSteps?: number;
        learningRate?: number;
        randomCrop?: boolean;
        classPrompt?: string;
        trainTextEncoder?: boolean;
        lrScheduler?:
          | "constant"
          | "constant-with-warmup"
          | "cosine"
          | "cosine-with-restarts"
          | "linear"
          | "polynomial";
        conceptPrompt?: string;
        maxTrainSteps?: number;
      };
      status: "failed" | "new" | "trained" | "training";
      updatedAt: string;
    };
  };
export type PostModelsCopyByModelIdApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** The modelId to copy */
  modelId: string;
  body: {
    copyAsTrained?: boolean;
  };
};
export type DeleteModelsImagesByModelIdApiResponse =
  /** status 200 200 response */ any;
export type DeleteModelsImagesByModelIdApiArg = {
  /** The modelId for which images will be deleted */
  modelId: string;
  /** The ids of the images to delete */
  ids: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type GetModelsInferencesByModelIdApiResponse =
  /** status 200 200 response */ {
    nextPaginationToken?: string;
    inferences: {
      createdAt: string;
      images: {
        seed: string;
        id: string;
        url: string;
      }[];
      modelId: string;
      progress?: number;
      id: string;
      imagesNumber: number;
      authorId: string;
      displayPrompt: string;
      ownerId: string;
      parameters: {
        image?: string;
        imageId?: string;
        modality?: string;
        seed?: string;
        strength?: number;
        hideResults?: boolean;
        maskId?: string;
        type: "controlnet" | "img2img" | "inpaint" | "txt2img";
        negativePrompt?: string;
        scheduler?:
          | "DDIMInverseScheduler"
          | "DDIMScheduler"
          | "DDPMScheduler"
          | "DEISMultistepScheduler"
          | "DPMSolverMultistepInverseScheduler"
          | "DPMSolverMultistepScheduler"
          | "DPMSolverSDEScheduler"
          | "DPMSolverSinglestepScheduler"
          | "EulerAncestralDiscreteScheduler"
          | "EulerDiscreteScheduler"
          | "HeunDiscreteScheduler"
          | "IPNDMScheduler"
          | "KDPM2AncestralDiscreteScheduler"
          | "KDPM2DiscreteScheduler"
          | "KarrasVeScheduler"
          | "LMSDiscreteScheduler"
          | "PNDMScheduler"
          | "ScoreSdeVeScheduler"
          | "UniPCMultistepScheduler";
        intermediateImages?: boolean;
        disableMerging?: boolean;
        disableModalityDetection?: boolean;
        guidance?: number;
        numInferenceSteps?: number;
        numSamples?: number;
        tokenMerging?: number;
        width?: number;
        prompt: string;
        height?: number;
        mask?: string;
      };
      userId: string;
      status: "failed" | "in-progress" | "new" | "queued" | "succeeded";
    }[];
  };
export type GetModelsInferencesByModelIdApiArg = {
  /** The modelId for which to get Images */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** The number of items to return in the response. The default value is 10, maximum value is 50, minimum value is 1 */
  pageSize?: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
};
export type PostModelsInferencesByModelIdApiResponse =
  /** status 200 200 response */ {
    inference: {
      createdAt: string;
      images: {
        seed: string;
        id: string;
        url: string;
      }[];
      modelId: string;
      progress?: number;
      id: string;
      imagesNumber: number;
      authorId: string;
      displayPrompt: string;
      ownerId: string;
      parameters: {
        image?: string;
        imageId?: string;
        modality?: string;
        seed?: string;
        strength?: number;
        hideResults?: boolean;
        maskId?: string;
        type: "controlnet" | "img2img" | "inpaint" | "txt2img";
        negativePrompt?: string;
        scheduler?:
          | "DDIMInverseScheduler"
          | "DDIMScheduler"
          | "DDPMScheduler"
          | "DEISMultistepScheduler"
          | "DPMSolverMultistepInverseScheduler"
          | "DPMSolverMultistepScheduler"
          | "DPMSolverSDEScheduler"
          | "DPMSolverSinglestepScheduler"
          | "EulerAncestralDiscreteScheduler"
          | "EulerDiscreteScheduler"
          | "HeunDiscreteScheduler"
          | "IPNDMScheduler"
          | "KDPM2AncestralDiscreteScheduler"
          | "KDPM2DiscreteScheduler"
          | "KarrasVeScheduler"
          | "LMSDiscreteScheduler"
          | "PNDMScheduler"
          | "ScoreSdeVeScheduler"
          | "UniPCMultistepScheduler";
        intermediateImages?: boolean;
        disableMerging?: boolean;
        disableModalityDetection?: boolean;
        guidance?: number;
        numInferenceSteps?: number;
        numSamples?: number;
        tokenMerging?: number;
        width?: number;
        prompt: string;
        height?: number;
        mask?: string;
      };
      userId: string;
      status: "failed" | "in-progress" | "new" | "queued" | "succeeded";
    };
  };
export type PostModelsInferencesByModelIdApiArg = {
  /** The modelId used to generate new images */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    parameters: {
      image?: string;
      imageId?: string;
      modality?: string;
      seed?: string;
      strength?: number;
      hideResults?: boolean;
      maskId?: string;
      type: "controlnet" | "img2img" | "inpaint" | "txt2img";
      negativePrompt?: string;
      scheduler?:
        | "DDIMInverseScheduler"
        | "DDIMScheduler"
        | "DDPMScheduler"
        | "DEISMultistepScheduler"
        | "DPMSolverMultistepInverseScheduler"
        | "DPMSolverMultistepScheduler"
        | "DPMSolverSDEScheduler"
        | "DPMSolverSinglestepScheduler"
        | "EulerAncestralDiscreteScheduler"
        | "EulerDiscreteScheduler"
        | "HeunDiscreteScheduler"
        | "IPNDMScheduler"
        | "KDPM2AncestralDiscreteScheduler"
        | "KDPM2DiscreteScheduler"
        | "KarrasVeScheduler"
        | "LMSDiscreteScheduler"
        | "PNDMScheduler"
        | "ScoreSdeVeScheduler"
        | "UniPCMultistepScheduler";
      intermediateImages?: boolean;
      disableMerging?: boolean;
      disableModalityDetection?: boolean;
      guidance?: number;
      numInferenceSteps?: number;
      numSamples?: number;
      tokenMerging?: number;
      width?: number;
      prompt: string;
      height?: number;
      mask?: string;
    };
  };
};
export type DeleteModelsInferencesByModelIdApiResponse =
  /** status 200 200 response */ any;
export type DeleteModelsInferencesByModelIdApiArg = {
  inferenceIds?: string;
  /** The modelId of the generated images to delete */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type DeleteModelsInferencesAllByModelIdApiResponse =
  /** status 202 202 response */ any;
export type DeleteModelsInferencesAllByModelIdApiArg = {
  /** The modelId for which all inferences will be deleted */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type GetModelsInferencesByModelIdAndInferenceIdApiResponse =
  /** status 200 200 response */ {
    inference: {
      createdAt: string;
      images: {
        seed: string;
        id: string;
        url: string;
      }[];
      modelId: string;
      progress?: number;
      id: string;
      imagesNumber: number;
      authorId: string;
      displayPrompt: string;
      ownerId: string;
      parameters: {
        image?: string;
        imageId?: string;
        modality?: string;
        seed?: string;
        strength?: number;
        hideResults?: boolean;
        maskId?: string;
        type: "controlnet" | "img2img" | "inpaint" | "txt2img";
        negativePrompt?: string;
        scheduler?:
          | "DDIMInverseScheduler"
          | "DDIMScheduler"
          | "DDPMScheduler"
          | "DEISMultistepScheduler"
          | "DPMSolverMultistepInverseScheduler"
          | "DPMSolverMultistepScheduler"
          | "DPMSolverSDEScheduler"
          | "DPMSolverSinglestepScheduler"
          | "EulerAncestralDiscreteScheduler"
          | "EulerDiscreteScheduler"
          | "HeunDiscreteScheduler"
          | "IPNDMScheduler"
          | "KDPM2AncestralDiscreteScheduler"
          | "KDPM2DiscreteScheduler"
          | "KarrasVeScheduler"
          | "LMSDiscreteScheduler"
          | "PNDMScheduler"
          | "ScoreSdeVeScheduler"
          | "UniPCMultistepScheduler";
        intermediateImages?: boolean;
        disableMerging?: boolean;
        disableModalityDetection?: boolean;
        guidance?: number;
        numInferenceSteps?: number;
        numSamples?: number;
        tokenMerging?: number;
        width?: number;
        prompt: string;
        height?: number;
        mask?: string;
      };
      userId: string;
      status: "failed" | "in-progress" | "new" | "queued" | "succeeded";
    };
  };
export type GetModelsInferencesByModelIdAndInferenceIdApiArg = {
  /** The inferenceId to retrieve */
  inferenceId: string;
  /** The modelId for that inferenceId */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type DeleteModelsInferencesByModelIdAndInferenceIdApiResponse =
  /** status 200 200 response */ any;
export type DeleteModelsInferencesByModelIdAndInferenceIdApiArg = {
  /** The inferenceId to delete */
  inferenceId: string;
  /** The modelId for that inferenceId */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type GetModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiResponse =
  /** status 200 200 response */ {
    image: {
      seed: string;
      id: string;
      url: string;
    };
  };
export type GetModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiArg = {
  /** The inferenceId for that imageId */
  inferenceId: string;
  /** The modelId for that inferenceId */
  modelId: string;
  /** The imageId to retrieve */
  imageId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type DeleteModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiResponse =
  /** status 200 200 response */ any;
export type DeleteModelsInferencesImagesByModelIdAndInferenceIdAndImageIdApiArg =
  {
    /** The inferenceId for that imageId */
    inferenceId: string;
    /** The modelId for that inferenceId */
    modelId: string;
    /** The imageId to delete */
    imageId: string;
    /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
    teamId: string;
  };
export type PutModelsTagsByModelIdApiResponse = /** status 200 200 response */ {
  deleted: string[];
  added: string[];
};
export type PutModelsTagsByModelIdApiArg = {
  /** The ID of the model to update its tags */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    add?: string[];
    delete?: string[];
  };
};
export type PutModelsTrainByModelIdApiResponse =
  /** status 200 200 response */ {
    model: {
      thumbnail?: {
        assetId: string;
        url: string;
      };
      parentModelId?: string;
      trainingImages: {
        createdAt: string;
        downloadUrl: string;
        name: string;
        description?: string;
        id: string;
      }[];
      trainingImagesNumber: number;
      modelKeyword?: string;
      privacy: "private" | "public" | "unlisted";
      authorId?: string;
      ownerId?: string;
      type: "sd-1_5" | "sd-xl" | "sd-xl-composition" | "sd-xl-lora";
      userId?: string;
      tags: string[];
      negativePromptEmbedding?: string;
      createdAt: string;
      concepts?: {
        modelId: string;
        scale: number;
      }[];
      collectionIds: string[];
      name?: string;
      promptEmbedding?: string;
      trainingProgress?: {
        stage:
          | "pending"
          | "queued-for-generate-class"
          | "queued-for-train"
          | "running-generate-class"
          | "running-train"
          | "starting-generate-class"
          | "starting-train";
        progress?: number;
        startedAt?: number;
        position?: number;
        updatedAt: number;
      };
      id: string;
      class?: {
        modelId: string;
        name: string;
        category: string;
        thumbnails: string[];
        prompt: string;
        conceptPrompt: string;
        slug: string;
        status: "published" | "unpublished";
      };
      parameters?: {
        adamWeightDecay?: number;
        maxGradNorm?: number;
        adamBeta2?: number;
        adamBeta1?: number;
        adamEpsilon?: number;
        numClassImages?: number;
        priorLossWeight?: number;
        seed?: number;
        lrWarmupSteps?: number;
        learningRate?: number;
        randomCrop?: boolean;
        classPrompt?: string;
        trainTextEncoder?: boolean;
        lrScheduler?:
          | "constant"
          | "constant-with-warmup"
          | "cosine"
          | "cosine-with-restarts"
          | "linear"
          | "polynomial";
        conceptPrompt?: string;
        maxTrainSteps?: number;
      };
      status: "failed" | "new" | "trained" | "training";
      updatedAt: string;
    };
  };
export type PutModelsTrainByModelIdApiArg = {
  /** The model Id for which the training will be triggered */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    parameters?: {
      adamWeightDecay?: number;
      maxGradNorm?: number;
      adamBeta2?: number;
      adamBeta1?: number;
      adamEpsilon?: number;
      numClassImages?: number;
      priorLossWeight?: number;
      seed?: number;
      lrWarmupSteps?: number;
      learningRate?: number;
      randomCrop?: boolean;
      classPrompt?: string;
      trainTextEncoder?: boolean;
      lrScheduler?:
        | "constant"
        | "constant-with-warmup"
        | "cosine"
        | "cosine-with-restarts"
        | "linear"
        | "polynomial";
      conceptPrompt?: string;
      maxTrainSteps?: number;
    };
  };
};
export type PostModelsTrainingImagesByModelIdApiResponse =
  /** status 200 200 response */ {
    trainingImage: {
      createdAt: string;
      downloadUrl: string;
      name: string;
      description?: string;
      id: string;
    };
  };
export type PostModelsTrainingImagesByModelIdApiArg = {
  /** The model Id where the training image will be stored */
  modelId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    data: string;
    name: string;
  };
};
export type PutModelsTrainingImagesByModelIdAndTrainingImageIdApiResponse =
  /** status 200 200 response */ {
    trainingImage: {
      createdAt: string;
      downloadUrl: string;
      name: string;
      description?: string;
      id: string;
    };
  };
export type PutModelsTrainingImagesByModelIdAndTrainingImageIdApiArg = {
  /** The model Id of the training image id */
  modelId: string;
  /** The training image Id that will be replaced */
  trainingImageId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    data: string;
    name: string;
  };
};
export type DeleteModelsTrainingImagesByModelIdAndTrainingImageIdApiResponse =
  /** status 200 200 response */ any;
export type DeleteModelsTrainingImagesByModelIdAndTrainingImageIdApiArg = {
  /** The model Id of the training image id */
  modelId: string;
  /** The training image Id that will be deleted */
  trainingImageId: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
};
export type PostModelsTransferByModelIdApiResponse =
  /** status 200 200 response */ {
    model: {
      thumbnail?: {
        assetId: string;
        url: string;
      };
      parentModelId?: string;
      trainingImages: {
        createdAt: string;
        downloadUrl: string;
        name: string;
        description?: string;
        id: string;
      }[];
      trainingImagesNumber: number;
      modelKeyword?: string;
      privacy: "private" | "public" | "unlisted";
      authorId?: string;
      ownerId?: string;
      type: "sd-1_5" | "sd-xl" | "sd-xl-composition" | "sd-xl-lora";
      userId?: string;
      tags: string[];
      negativePromptEmbedding?: string;
      createdAt: string;
      concepts?: {
        modelId: string;
        scale: number;
      }[];
      collectionIds: string[];
      name?: string;
      promptEmbedding?: string;
      trainingProgress?: {
        stage:
          | "pending"
          | "queued-for-generate-class"
          | "queued-for-train"
          | "running-generate-class"
          | "running-train"
          | "starting-generate-class"
          | "starting-train";
        progress?: number;
        startedAt?: number;
        position?: number;
        updatedAt: number;
      };
      id: string;
      class?: {
        modelId: string;
        name: string;
        category: string;
        thumbnails: string[];
        prompt: string;
        conceptPrompt: string;
        slug: string;
        status: "published" | "unpublished";
      };
      parameters?: {
        adamWeightDecay?: number;
        maxGradNorm?: number;
        adamBeta2?: number;
        adamBeta1?: number;
        adamEpsilon?: number;
        numClassImages?: number;
        priorLossWeight?: number;
        seed?: number;
        lrWarmupSteps?: number;
        learningRate?: number;
        randomCrop?: boolean;
        classPrompt?: string;
        trainTextEncoder?: boolean;
        lrScheduler?:
          | "constant"
          | "constant-with-warmup"
          | "cosine"
          | "cosine-with-restarts"
          | "linear"
          | "polynomial";
        conceptPrompt?: string;
        maxTrainSteps?: number;
      };
      status: "failed" | "new" | "trained" | "training";
      updatedAt: string;
    };
  };
export type PostModelsTransferByModelIdApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  modelId: string;
  body: {
    destinationTeamId: string;
    copyAsTrained?: boolean;
    asCopy: boolean;
  };
};
export type PutPromptGeneratorApiResponse = /** status 200 200 response */ {
  mode: "completion" | "inventive";
  newPredictions: string[];
};
export type PutPromptGeneratorApiArg = {
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    mode?: "completion" | "inventive" | "structured";
    ensureIPCleared?: boolean;
    minNewTokens?: number;
    seed?: number;
    maxNewTokens?: number;
    numResults?: number;
    prompt?: string;
  };
};
export type PostSearchApiResponse = /** status 200 200 response */ {
  asset?: {
    metadata: {
      magic?: boolean;
      rootParentId?: string;
      thumbnail?: {
        assetId: string;
        url: string;
      };
      modality?: string;
      seed?: string;
      modelId?: string;
      kind: "image" | "json";
      inferenceId?: string;
      scalingFactor?: number;
      type:
        | "background-removal"
        | "canvas"
        | "detection"
        | "inference-controlnet"
        | "inference-img2img"
        | "inference-inpaint"
        | "inference-txt2img"
        | "pixelization"
        | "uploaded"
        | "upscale";
      parentId?: string;
      negativePrompt?: string;
      lockExpiresAt?: string;
      forceFaceRestoration?: boolean;
      size?: number;
      name?: string;
      width?: number;
      progressPercent?: number;
      photorealist?: boolean;
      prompt?: string;
      height?: number;
    };
    nsfw?: string[];
    description?: string;
    privacy: "private" | "public" | "unlisted";
    mimeType: string;
    authorId: string;
    ownerId: string;
    url: string;
    tags: string[];
    createdAt: string;
    collectionIds: string[];
    id: string;
    status: "error" | "pending" | "success";
    updatedAt: string;
  };
  results: {
    nsfw?: string[];
    type: "asset" | "model";
    assetType?:
      | "background-removal"
      | "canvas"
      | "detection"
      | "inference-controlnet"
      | "inference-img2img"
      | "inference-inpaint"
      | "inference-txt2img"
      | "pixelization"
      | "uploaded"
      | "upscale";
    negativePrompt?: string;
    tags: string[];
    size?: number;
    collectionIds?: string[];
    name?: string;
    width?: number;
    captioning?: string;
    id: string;
    prompt?: string;
    height?: number;
    thumbnailUrl?: string;
  }[];
};
export type PostSearchApiArg = {
  pageSize?: string;
  pageNumber?: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  body: {
    image?: string;
    public?: boolean;
    assetId?: string;
    query?: string;
    assetIds?: {
      like?: string[];
      unlike?: string[];
    };
    fields?: string[];
  };
};
export type GetTagsApiResponse = /** status 200 200 response */ {
  nextPaginationToken?: string;
  tags: {
    createdAt: string;
    ownerId: string;
    tagName: string;
    itemCount: number;
    updatedAt: string;
  }[];
};
export type GetTagsApiArg = {
  /** The number of items to return in the response. The default value is 10, maximum value is 50, minimum value is 1 */
  pageSize?: string;
  /** The teamId used for ownership resource management. Either to assert ownership or to set the owner of the resource(s) */
  teamId: string;
  /** A token you received in a previous request to query the next page of items */
  paginationToken?: string;
};
export type GetTeamsApiResponse = /** status 200 200 response */ {
  teams: {
    createdAt: string;
    context: {
      invitationsCount: number;
      usersCount: number;
      userRole: "admin" | "key" | "member";
      keysCount: number;
    };
    name: string;
    id: string;
    type: "personal" | "shared";
    plan: "enterprise" | "essential" | "free" | "no-limits" | "premium" | "pro";
    updatedAt: string;
  }[];
};
export type GetTeamsApiArg = void;
export type PostTeamsApiResponse = /** status 200 200 response */ {
  team: {
    createdAt: string;
    context: {
      invitationsCount: number;
      usersCount: number;
      userRole: "admin" | "key" | "member";
      keysCount: number;
    };
    name: string;
    id: string;
    type: "personal" | "shared";
    plan: "enterprise" | "essential" | "free" | "no-limits" | "premium" | "pro";
    updatedAt: string;
  };
};
export type PostTeamsApiArg = {
  body: {
    name?: string;
  };
};
export type GetTeamsByTeamIdApiResponse = /** status 200 200 response */ {
  team: {
    createdAt: string;
    invitations: {
      createdAt: string;
      role: "admin" | "key" | "member";
      email: string;
      hash: string;
      status: "accepted" | "expired" | "pending" | "withdrawn";
    }[];
    keys: {
      createdAt: string;
      apiKeyStatus: "active" | "inactive";
      name: string;
      apiKeyId: string;
      id: string;
      limits: {
        detection?: number;
        pixelate?: number;
        upscale?: number;
        trainModel?: number;
        inferenceImages?: number;
        removeBackground?: number;
        captioning?: number;
      };
      updatedAt: string;
    }[];
    name: string;
    id: string;
    type: "personal" | "shared";
    plan: "enterprise" | "essential" | "free" | "no-limits" | "premium" | "pro";
    users: {
      createdAt: string;
      role: "admin" | "key" | "member";
      id: string;
      email: string;
      updatedAt: string;
    }[];
    updatedAt: string;
  };
};
export type GetTeamsByTeamIdApiArg = {
  /** The team Id to retrieve */
  teamId: string;
};
export type PutTeamsByTeamIdApiResponse = /** status 200 200 response */ any;
export type PutTeamsByTeamIdApiArg = {
  /** The team Id to update */
  teamId: string;
  body: {
    name?: string;
  };
};
export type DeleteTeamsByTeamIdApiResponse = /** status 200 200 response */ any;
export type DeleteTeamsByTeamIdApiArg = {
  /** The team Id to delete */
  teamId: string;
};
export type PostTeamsApiKeysByTeamIdApiResponse =
  /** status 200 200 response */ {
    createdAt: string;
    id: string;
    secret: string;
    authorId: string;
    ownerId: string;
  };
export type PostTeamsApiKeysByTeamIdApiArg = {
  /** The team Id on which an api key will be created */
  teamId: string;
  body: {
    name?: string;
  };
};
export type PutTeamsApiKeyByTeamIdAndKeyIdApiResponse =
  /** status 200 200 response */ any;
export type PutTeamsApiKeyByTeamIdAndKeyIdApiArg = {
  /** The key Id to update */
  keyId: string;
  /** The team Id on which the api key exists */
  teamId: string;
  body: {
    apiKeyStatus?: "active" | "inactive";
    name?: string;
    limits?: {
      upscale?: number;
      pixelate?: number;
      trainModel?: number;
      inferenceImages?: number;
      removeBackground?: number;
    };
  };
};
export type DeleteTeamsApiKeyByTeamIdAndKeyIdApiResponse =
  /** status 200 200 response */ object;
export type DeleteTeamsApiKeyByTeamIdAndKeyIdApiArg = {
  /** The key Id to delete */
  keyId: string;
  /** The team Id on which the api key exists */
  teamId: string;
};
export type DeleteTeamsInvitationsByTeamIdApiResponse =
  /** status 200 200 response */ object;
export type DeleteTeamsInvitationsByTeamIdApiArg = {
  /** The invitation hash to withdraw from the team */
  hash: string;
  /** The team id concerned by the invitation withdrawal */
  teamId: string;
};
export type GetTeamsLimitsByTeamIdApiResponse = /** status 200 200 response */ {
  period: {
    start: string;
    end: string;
  };
  values: {
    collections: number;
    pixelate: number;
    upscale: number;
    trainModel: number;
    retainedModels: number;
    inferenceImages: number;
    removeBackground: number;
    seats: number;
  };
  plan: "enterprise" | "essential" | "free" | "no-limits" | "premium" | "pro";
  limits: {
    detection: number;
    trainModel: number;
    apiKeys: number;
    inferenceImages: number;
    maxTrainingDatasets: number;
    snapshotsRetention: number;
    canvasAssets: number;
    inferenceBatchSize: number;
    collections: number;
    pixelate: number;
    upscale: number;
    retainedModels: number;
    removeBackground: number;
    canvasAssetLayers: number;
    captioning: number;
  };
};
export type GetTeamsLimitsByTeamIdApiArg = {
  /** The team id to retrieve limits for */
  teamId: string;
};
export type PostTeamsMembersByTeamIdApiResponse =
  /** status 200 200 response */ any;
export type PostTeamsMembersByTeamIdApiArg = {
  /** The team Id on which to add the member */
  teamId: string;
  body: {
    role?: string;
    email?: string;
  };
};
export type PutTeamsMembersByTeamIdAndMemberIdApiResponse =
  /** status 200 200 response */ any;
export type PutTeamsMembersByTeamIdAndMemberIdApiArg = {
  /** The member Id concerned by the new role */
  memberId: string;
  /** The team Id concerned by the role change */
  teamId: string;
  body: {
    role?: string;
  };
};
export type DeleteTeamsMembersByTeamIdAndMemberIdApiResponse =
  /** status 200 200 response */ object;
export type DeleteTeamsMembersByTeamIdAndMemberIdApiArg = {
  /** The member Id to remove */
  memberId: string;
  /** The team Id concerned by the member removal */
  teamId: string;
};
export type GetTeamsSubscriptionsByTeamIdApiResponse =
  /** status 200 200 response */ {
    customerPortalUrl?: string;
    period?: {
      endAt: string;
      startAt: string;
    };
    freeTrial?: string;
    paymentProvider: "applepay" | "scenario" | "stripe";
    usage?: {
      tiers?: {
        flat_amount: number | null;
        flat_amount_decimal: string | null;
        unit_amount: number | null;
        unit_amount_decimal: string | null;
        up_to: 100000 | null;
      }[];
      currency?: string;
      plan?: "api" | "custom" | "none";
    };
    overcap?: {
      amount?: number;
      currency?: string;
      plan?: "custom" | "default" | "none";
    };
    subscription?: {
      amount?: number;
      cancelAtPeriodEnd?: boolean;
      currency?: string;
      interval?: "month" | "year";
      plan?:
        | "enterprise"
        | "essential"
        | "free"
        | "no-limits"
        | "premium"
        | "pro";
      seats: number;
    };
    coming?: {
      amount?: number;
      currency?: string;
      interval?: "month" | "year";
      plan?:
        | "enterprise"
        | "essential"
        | "free"
        | "no-limits"
        | "premium"
        | "pro";
      seats?: number;
      startAt?: string;
    };
  };
export type GetTeamsSubscriptionsByTeamIdApiArg = {
  /** The team id concerned by the subscription plan */
  teamId: string;
  paymentProvider: string;
};
export type PostTeamsSubscriptionsByTeamIdApiResponse =
  /** status 200 200 response */ {
    redirectUrl: string;
  };
export type PostTeamsSubscriptionsByTeamIdApiArg = {
  /** The team id concerned by the subscription plan */
  teamId: string;
  body: {
    paymentProvider: string;
    interval?: string;
    callbackUrl?: {
      cancel?: string;
      success?: string;
    };
    plan: string;
    seats: number;
    toltReferral?: string;
  };
};
export type PutTeamsSubscriptionsByTeamIdApiResponse =
  /** status 200 200 response */ object;
export type PutTeamsSubscriptionsByTeamIdApiArg = {
  /** The team id concerned by the subscription plan */
  teamId: string;
  body: {
    paymentProvider: string;
    interval: string;
    plan: string;
  };
};
export type DeleteTeamsSubscriptionsByTeamIdApiResponse =
  /** status 200 200 response */ object;
export type DeleteTeamsSubscriptionsByTeamIdApiArg = {
  /** The team id concerned by the subscription plan */
  teamId: string;
  body: {
    paymentProvider: string;
  };
};
export type PostTeamsSubscriptionsApiByTeamIdApiResponse =
  /** status 200 200 response */ {
    redirectUrl: string;
  };
export type PostTeamsSubscriptionsApiByTeamIdApiArg = {
  /** The team id concerned by the subscription plan */
  teamId: string;
  body: {
    paymentProvider: string;
  };
};
export type GetTeamsSubscriptionsCustomerPortalByTeamIdApiResponse =
  /** status 200 200 response */ {
    redirectUrl: string;
  };
export type GetTeamsSubscriptionsCustomerPortalByTeamIdApiArg = {
  /** The teamId to use */
  teamId: string;
  paymentProvider: string;
};
export type PostTeamsSubscriptionsSeatsByTeamIdApiResponse = unknown;
export type PostTeamsSubscriptionsSeatsByTeamIdApiArg = {
  /** The team id concerned by the seats update */
  teamId: string;
  body: {
    paymentProvider: string;
    seats: number;
  };
};
export type GetUsagesApiResponse = /** status 200 200 response */ {
  usages: {
    granularity: "15m" | "1d" | "1h" | "1m" | "30m" | "5m";
    usageName:
      | "background-removal"
      | "generators-training"
      | "images-generation"
      | "upscale";
    points: {
      time: string;
      value: string;
    }[];
  }[];
};
export type GetUsagesApiArg = {
  /** The user id of the usage. If not provided, use default user. */
  userId?: string;
  /** The time range of the usage. If not provided, use default timeRange. If startDate and endDate provided, timeRange is ignored. */
  timeRange?: string;
  /** The team id of the usage. If not provided, use default team. */
  teamId?: string;
  /** The start date of the usage. If not provided, use default timeRange. If provided, endDate is required. */
  startDate?: string;
  /** The end date of the usage. If not provided, use default timeRange. If provided, startDate is required. */
  endDate?: string;
};
export const {
  useGetAssetsQuery,
  useLazyGetAssetsQuery,
  usePostAssetMutation,
  useDeleteAssetMutation,
  useDownloadAssetsMutation,
  useGetAssetsByAssetIdQuery,
  useLazyGetAssetsByAssetIdQuery,
  usePutAssetByAssetIdMutation,
  useCopyAssetByAssetIdMutation,
  useLockAssetByAssetIdMutation,
  useGetCanvasAssetSnapshotsQuery,
  useLazyGetCanvasAssetSnapshotsQuery,
  usePutAssetsTagsByAssetIdMutation,
  useUnlockAssetByAssetIdMutation,
  useGetCollectionsQuery,
  useLazyGetCollectionsQuery,
  usePostCollectionMutation,
  useGetCollectionsByCollectionIdQuery,
  useLazyGetCollectionsByCollectionIdQuery,
  usePutCollectionsByCollectionIdMutation,
  useDeleteCollectionsByCollectionIdMutation,
  usePutAssetsByCollectionIdMutation,
  useDeleteAssetsByCollectionIdMutation,
  usePutModelsByCollectionIdMutation,
  useDeleteModelsByCollectionIdMutation,
  usePutImagesCaptioningMutation,
  usePutImagesDetectionMutation,
  usePutImagesEraseBackgroundMutation,
  usePutImagesPixelateMutation,
  usePutImagesRemoveBackgroundMutation,
  usePutImageUpscaleMutation,
  useGetInferencesQuery,
  useLazyGetInferencesQuery,
  usePutMagicPromptMutation,
  useGetMeQuery,
  useLazyGetMeQuery,
  usePutMeMutation,
  useDeleteMeMutation,
  useGetModelsQuery,
  useLazyGetModelsQuery,
  usePostModelsMutation,
  useGetModelsByModelIdQuery,
  useLazyGetModelsByModelIdQuery,
  usePutModelsByModelIdMutation,
  useDeleteModelsByModelIdMutation,
  useGetModelsCategoriesByModelIdQuery,
  useLazyGetModelsCategoriesByModelIdQuery,
  useGetModelsClassesByModelIdQuery,
  useLazyGetModelsClassesByModelIdQuery,
  usePostModelsCopyByModelIdMutation,
  useDeleteModelsImagesByModelIdMutation,
  useGetModelsInferencesByModelIdQuery,
  useLazyGetModelsInferencesByModelIdQuery,
  usePostModelsInferencesByModelIdMutation,
  useDeleteModelsInferencesByModelIdMutation,
  useDeleteModelsInferencesAllByModelIdMutation,
  useGetModelsInferencesByModelIdAndInferenceIdQuery,
  useLazyGetModelsInferencesByModelIdAndInferenceIdQuery,
  useDeleteModelsInferencesByModelIdAndInferenceIdMutation,
  useGetModelsInferencesImagesByModelIdAndInferenceIdAndImageIdQuery,
  useLazyGetModelsInferencesImagesByModelIdAndInferenceIdAndImageIdQuery,
  useDeleteModelsInferencesImagesByModelIdAndInferenceIdAndImageIdMutation,
  usePutModelsTagsByModelIdMutation,
  usePutModelsTrainByModelIdMutation,
  usePostModelsTrainingImagesByModelIdMutation,
  usePutModelsTrainingImagesByModelIdAndTrainingImageIdMutation,
  useDeleteModelsTrainingImagesByModelIdAndTrainingImageIdMutation,
  usePostModelsTransferByModelIdMutation,
  usePutPromptGeneratorMutation,
  usePostSearchQuery,
  useGetTagsQuery,
  useLazyGetTagsQuery,
  useGetTeamsQuery,
  useLazyGetTeamsQuery,
  usePostTeamsMutation,
  useGetTeamsByTeamIdQuery,
  useLazyGetTeamsByTeamIdQuery,
  usePutTeamsByTeamIdMutation,
  useDeleteTeamsByTeamIdMutation,
  usePostTeamsApiKeysByTeamIdMutation,
  usePutTeamsApiKeyByTeamIdAndKeyIdMutation,
  useDeleteTeamsApiKeyByTeamIdAndKeyIdMutation,
  useDeleteTeamsInvitationsByTeamIdMutation,
  useGetTeamsLimitsByTeamIdQuery,
  useLazyGetTeamsLimitsByTeamIdQuery,
  usePostTeamsMembersByTeamIdMutation,
  usePutTeamsMembersByTeamIdAndMemberIdMutation,
  useDeleteTeamsMembersByTeamIdAndMemberIdMutation,
  useGetTeamsSubscriptionsByTeamIdQuery,
  useLazyGetTeamsSubscriptionsByTeamIdQuery,
  usePostTeamsSubscriptionsByTeamIdMutation,
  usePutTeamsSubscriptionsByTeamIdMutation,
  useDeleteTeamsSubscriptionsByTeamIdMutation,
  usePostTeamsSubscriptionsApiByTeamIdMutation,
  useGetTeamsSubscriptionsCustomerPortalByTeamIdQuery,
  useLazyGetTeamsSubscriptionsCustomerPortalByTeamIdQuery,
  usePostTeamsSubscriptionsSeatsByTeamIdMutation,
  useGetUsagesQuery,
  useLazyGetUsagesQuery,
} = injectedRtkApi;
