import { assetsTags } from "domains/assets/api/endpoints";
import { searchTags } from "domains/search/api/endpoints";
import { GetInferencesApiResponse } from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { AppRootState } from "infra/store/store";
import { memoize } from "lodash";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

import { inferencesTags } from "./endpoints";

const getInferenceFromList = (
  imageId: string,
  inferences: GetInferencesApiResponse["inferences"]
): GetInferencesApiResponse["inferences"][number] | undefined => {
  return inferences.find((inference) =>
    inference.images.find((image) => image.id === imageId)
  );
};

/** Delete images from all existing queries */
export const updateQueryDataByDeletingImages = (
  imagesToDelete: string[],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  /** Collect all the undo in case of errors */
  const undoList: (() => void)[] = [];
  /** Undo all the changes */
  const undo = () => {
    undoList.forEach((undo) => undo());
  };

  // TODO: use a file redux store instead of the query cache so we can delete the image from the cache at only 1 location
  // Go through all the queries that use the `inference` tag, remove the deleted inference images from the cache
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    inferencesTags.inference,
    searchTags.search,
    assetsTags.asset,
  ]) as SelectedInvalidatedByTag[]) {
    // For every query that uses the `inference` tag, remove the deleted inference images from the cache, here we get the endpoint
    if (
      endpointName === "getInferences" ||
      endpointName === "getModelsInferencesByModelId" ||
      endpointName === "getModelsInferencesByModelIdAndInferenceId"
    ) {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          const memoizedGetInferenceFromList = memoize(getInferenceFromList);

          // For every image to delete, remove it from the cache in one single cache update
          for (const imageToDelete of imagesToDelete) {
            // Narrow the type of the draft to the type of the query
            const inferenceList =
              "inferences" in draft ? draft.inferences : [draft?.inference];

            const inference = memoizedGetInferenceFromList(
              imageToDelete,
              inferenceList
            );
            if (inference) {
              inference.images = inference.images.filter(
                (image) => image.id !== imageToDelete
              );
              inference.parameters.numSamples = inference.images.length;
              inference.imagesNumber = inference.images.length;
            }
          }
        })
      );
      undoList.push(update.undo);
    }

    if (endpointName === "postSearch") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          draft.results = draft.results.filter(
            (result) => !imagesToDelete.includes(result.id)
          );
        })
      );
      undoList.push(update.undo);
    }

    if (endpointName === "getAssets") {
      const update = dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          draft.assets = draft.assets.filter(
            (asset) => !imagesToDelete.includes(asset.id)
          );
        })
      );
      undoList.push(update.undo);
    }
  }

  return { undo };
};
