import React, { useCallback } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { EditButton } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetParent from "domains/assets/hooks/useAssetParent";
import useAssetRootParent from "domains/assets/hooks/useAssetRootParent";
import {
  FileImageType,
  getImageThumbnail,
} from "domains/file-manager/interfaces";
import { getModelThumbnail } from "domains/generators/utils";
import { ALL_SCHEDULERS } from "domains/inference/constants/Schedulers";
import {
  getControlNetInfluenceFromString,
  getControlNetPresetFromString,
} from "domains/inference/enum/ControlNetPreset";
import Icon from "domains/ui/components/Icon";
import PanelInfoGridLayout from "domains/ui/components/Panel/PanelInfoGridLayout";
import { useUser } from "domains/user/hooks/useUser";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsByModelIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";

import {
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  Image,
  Spinner,
  Text,
} from "@chakra-ui/react";

export interface AssetZoomInfoPanelProps {
  model: GetModelsByModelIdApiResponse["model"] | undefined;
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
}

const DerivedChildren = ({ assets }: { assets: FileImageType[] }) => {
  const router = useRouter();

  return (
    <Grid gap={0.5} templateColumns="repeat(3, 1fr)">
      {assets.slice(0, 9).map((asset) => (
        <GridItem key={asset.id}>
          <Link
            style={{
              width: "100%",
            }}
            href={{
              pathname: router.pathname,
              query: {
                ...router.query,
                openAssetId: asset.id,
              },
            }}
          >
            <Image
              h={"100%"}
              objectFit={"cover"}
              alt={"icon"}
              aspectRatio={"1/1"}
              src={asset.thumbnail}
            />
          </Link>
        </GridItem>
      ))}
    </Grid>
  );
};

const AssetZoomInfoPanel = ({
  model,
  inference,
  asset,
}: AssetZoomInfoPanelProps) => {
  const router = useRouter();
  const isInference = asset?.metadata.type.includes("inference");
  const rootParent = useAssetRootParent(asset);
  const parent = useAssetParent(asset);
  const { files: children } = useAllAssets({
    parent: asset,
  });
  const { isNsfwFilterEnabled } = useUser();

  const getInferenceInfo = useCallback(() => {
    if (!inference || !isInference || !asset) {
      return [];
    }

    const info: {
      label: string;
      value: string | number | React.ReactElement;
      fullRow?: boolean;
    }[] = [
      {
        label: "Prompt",
        value: inference.displayPrompt,
        fullRow: true,
      },
    ];

    if (inference.parameters.negativePrompt) {
      info.push({
        label: "Negative Prompt",
        value: inference.parameters.negativePrompt,
        fullRow: true,
      });
    }

    info.push(
      {
        label: "Steps",
        value: inference.parameters.numInferenceSteps || "Unknown",
      },
      {
        label: "Size",
        value:
          asset.metadata.width && asset.metadata.height
            ? `${asset.metadata.width}x${asset.metadata.height}px`
            : "Unknown",
      },
      {
        label: "Guidance",
        value: inference.parameters.guidance || "Unknown",
      },
      {
        label: "Scheduler",
        value:
          (inference.parameters.scheduler &&
            ALL_SCHEDULERS[inference.parameters.scheduler]) ||
          "Default",
      },
      {
        label: "Seed",
        value: asset?.metadata.seed ?? "Unknown",
        fullRow: true,
      }
    );

    if (parent) {
      info.push(
        {
          label: "Reference",
          value: inference.parameters.modality
            ? `Composition Control - ${
                getControlNetPresetFromString(inference.parameters.modality)
                  ?.label
              } mode`
            : "image2image",
          fullRow: true,
        },
        {
          label: "Reference Image",
          value: (
            <Link
              href={{
                pathname: router.pathname,
                query: {
                  ...router.query,
                  openAssetId: parent.id,
                },
              }}
            >
              <Image
                alt={"reference image"}
                src={getImageThumbnail({
                  isNsfwFilterEnabled,
                  url: parent.url,
                  nsfw: parent.nsfw ?? [],
                  type: parent.metadata.type,
                })}
              />
            </Link>
          ),
          fullRow: true,
        }
      );
      if (inference.parameters.modality) {
        info.push(
          {
            label: "Influence",
            value:
              getControlNetInfluenceFromString(inference.parameters.modality) *
              100,
          },
          {
            label: "Mode Mapping",
            value: inference.parameters.disableModalityDetection
              ? "Disabled"
              : "Enabled",
          }
        );
      } else {
        info.push({
          label: "Influence",
          value: inference.parameters.strength
            ? Math.round((1 - inference.parameters.strength) * 100)
            : "Unknown",
        });
      }
    }

    return info;
  }, [
    asset?.metadata.seed,
    inference,
    isInference,
    router.pathname,
    router.query,
    parent,
    isNsfwFilterEnabled,
  ]);

  if (!asset || (!inference && isInference)) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Flex direction={"column"} gap={5}>
      {model ? (
        <Link
          href={{
            pathname: "/generators/[id]",
            query: {
              id: model.id,
            },
          }}
        >
          <EditButton
            text={model.name ?? "Untitled"}
            icon={getModelThumbnail(model)}
            rightComponent={<Icon id={"Ui/ChevronRight"} pr={1.5} />}
            onClick={() => {}}
          />
        </Link>
      ) : isInference ? (
        <Text size={"body.lg"}>Model unavailable</Text>
      ) : null}
      {rootParent && rootParent.id !== parent?.id ? (
        <Link
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              openAssetId: rootParent.id,
            },
          }}
        >
          <EditButton
            text={"Original Reference"}
            icon={getImageThumbnail({
              isNsfwFilterEnabled,
              url: rootParent.url,
              nsfw: rootParent.nsfw ?? [],
              type: rootParent.metadata.type,
            })}
            rightComponent={<Icon id={"Ui/ChevronRight"} pr={1.5} />}
            onClick={() => {}}
          />
        </Link>
      ) : null}
      {parent ? (
        <Link
          href={{
            pathname: router.pathname,
            query: {
              ...router.query,
              openAssetId: parent.id,
            },
          }}
        >
          <EditButton
            text={"Reference"}
            icon={getImageThumbnail({
              isNsfwFilterEnabled,
              url: parent.url,
              nsfw: parent.nsfw ?? [],
              type: parent.metadata.type,
            })}
            rightComponent={<Icon id={"Ui/ChevronRight"} pr={1.5} />}
            onClick={() => {}}
          />
        </Link>
      ) : null}
      <PanelInfoGridLayout
        items={[
          ...getInferenceInfo(),
          ...(!isInference
            ? [
                {
                  label: "Type",
                  value: (() => {
                    switch (asset.metadata.type) {
                      case "background-removal":
                        return "Background Removal";
                      case "pixelization":
                        return "Pixelization";
                      case "upscale":
                        return "Upscale";
                      case "uploaded":
                        return "Uploaded";
                      case "detection":
                        return "Mode map";
                      default:
                        return "Unknown";
                    }
                  })(),
                  fullRow: true,
                },
                {
                  label: "Size",
                  value:
                    asset.metadata.width && asset.metadata.height
                      ? `${asset.metadata.width}x${asset.metadata.height}px`
                      : "Unknown",
                },
              ]
            : []),
          ...(children?.length
            ? [
                {
                  label: (
                    <Link
                      href={{
                        pathname: "/images/[id]/children",
                        query: {
                          id: asset.id,
                        },
                      }}
                    >
                      <HStack align={"center"}>
                        <Text color="white.500" size="body.bold.md">
                          Derived Images
                        </Text>
                        <Icon id="Ui/Link" h="10px" />
                      </HStack>
                    </Link>
                  ),
                  value: <DerivedChildren assets={children} />,
                  fullRow: true,
                },
              ]
            : []),
        ]}
      />
    </Flex>
  );
};

export default AssetZoomInfoPanel;
