import { assetsTags } from "domains/assets/api/endpoints";
import { GetModelsInferencesByModelIdAndInferenceIdApiResponse } from "infra/api/generated/api";
import { apiSlice, Endpoints } from "infra/store/apiSlice";

export const inferencesTags = {
  inference: "inference",
} as const;

export const inferencesEndpoints: Endpoints = {
  postModelsInferencesByModelId: {
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: inferencesTags.inference,
          id: `modelId:${arg.modelId}`,
        },
        {
          type: assetsTags.asset,
          // TODO uncomment when the API allows get assets by parent id
          // id: `assetId:${arg.body.parameters.imageId}`,
        },
      ];
    },
  },

  getModelsInferencesByModelIdAndInferenceId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
      const { data } = await queryFulfilled;
      if (data) {
        dispatch(
          apiSlice.util.updateQueryData(
            "getModelsInferencesByModelIdAndInferenceId",
            arg,
            (draft) => {
              draft.inference = addEmptyImagesToInference(data.inference);
            }
          )
        );
      }
    },

    providesTags: (_result, _error, arg) => {
      return [
        {
          type: inferencesTags.inference,
          id: `modelId:${arg.modelId}-inferenceId:${arg.inferenceId}`,
        },
      ];
    },
  },
};

const addEmptyImagesToInference = (
  inference: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
) => {
  const referenceNumber =
    inference.status === "new" ||
    inference.status === "in-progress" ||
    inference.status === "queued"
      ? inference.parameters.numSamples!
      : inference.imagesNumber;
  const length = Math.max(referenceNumber - inference.images.length, 0);
  if (length === 0) {
    return inference;
  }
  return {
    ...inference,
    images: [
      ...inference.images,
      ...new Array(length).fill({}).map(() => ({
        id: inference.id + "-" + Math.random(),
        url: "",
        seed: "",
      })),
    ],
  };
};
