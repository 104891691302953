import { useEffect, useRef, useState } from "react";
import _ from "lodash";

/**
 * Debounce the passed value
 *
 * @param value The value to debounce.
 * @param delay Number of milliseconds to wait before debounce.
 * @param options.preventOnNullish If `value` is a nullish, the function return immediately the new value. This is an optional param.
 * @param options.resetBeforeDebounce Return immediately `undefined` on `value` change before the debounce. This optional param is only available if `value` can be `undefined`.
 */
export function useDebounce<T>(
  value: T,
  delay?: number,
  {
    preventOnNullish,
    resetBeforeDebounce,
  }: {
    preventOnNullish?: boolean;
    resetBeforeDebounce?: T extends undefined ? boolean : undefined | false;
  } = {}
): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const deps = [preventOnNullish, resetBeforeDebounce, delay, value];
  const depsRef = useRef(deps);

  if (!_.isEqual(depsRef.current, deps)) {
    depsRef.current = deps;
  }

  useEffect(() => {
    if (preventOnNullish && (value === undefined || value === null)) {
      setDebouncedValue(value);
    } else {
      if (resetBeforeDebounce) {
        setDebouncedValue(undefined as T);
      }
      const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
      return () => {
        clearTimeout(timer);
      };
    }
  }, depsRef.current); // eslint-disable-line react-hooks/exhaustive-deps

  return debouncedValue;
}
