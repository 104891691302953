import { Fragment, RefObject, useCallback, useMemo, useState } from "react";
import AssetZoom from "domains/assets/components/AssetZoom";
import useClickOutside from "domains/assets/hooks/useClickOutside";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import FileManagerTopBar from "domains/file-manager/components/FileManagerTopBar";
import FileView from "domains/file-manager/components/FileView";
import { FilterAssetTypeValue } from "domains/file-manager/constants/AssetFilter";
import { GridSortValue } from "domains/file-manager/constants/GridSort";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { useSelection } from "domains/file-manager/hooks/useSelection";
import {
  FileCanvasType,
  FileGeneratorType,
  FileHandler,
  FileImageType,
  FileType,
} from "domains/file-manager/interfaces";

import { Box, Center, FlexProps, Spinner, VStack } from "@chakra-ui/react";

import SelectionBar from "../SelectionBar";

export interface FileManagerProps {
  files: FileType[];

  fileHandlers: {
    image?: FileHandler<FileImageType>;
    canvas?: FileHandler<FileCanvasType>;
    generator?: FileHandler<FileGeneratorType>;
  };

  options?: {
    cacheKey?: PersistedStateKey;

    gridView?: GridViewKey;
    canChangeView?: boolean;

    numberOfColumns?: number;
    canChangeNumberOfColumns?: boolean;

    sort?: GridSortValue;
    sortOptions?: GridSortValue[];
    onSortChange?: (value: GridSortValue) => void;

    filterAssetType?: FilterAssetTypeValue;
    filterAssetTypeOptions?: FilterAssetTypeValue[];
    onFilterAssetTypeChange?: (value: FilterAssetTypeValue | undefined) => void;

    canSelect?: boolean;

    showFileNames?: "always" | "hover" | "never";

    withoutTopBar?: boolean;
  };

  hasMore?: boolean;
  loadMore?: () => void;
  isLoading?: boolean;

  styleProps?: {
    topbar?: FlexProps;
  };

  title?: string;

  scrollRef?: RefObject<HTMLDivElement>;

  loadingText?: string;

  topBarLeftContent?: React.ReactNode;
}

export type HandleSelect = (
  id: string,
  options: { shiftPressed: boolean }
) => void;

type FileManagerPersistedState = {
  gridView: GridViewKey;
  numberOfColumns: number;
};

const useFileManagerOptions = (
  _files: FileType[],
  options: FileManagerProps["options"]
) => {
  const [state, setState] = usePersistedState<FileManagerPersistedState>(
    options?.cacheKey || PersistedStateKey.FILE_MANAGER,
    {
      defaultValue: {
        gridView: options?.gridView ?? "fill",
        numberOfColumns: options?.numberOfColumns ?? 6,
      },
    }
  );

  const handleGridViewChange = (gridView: GridViewKey) => {
    setState((state) => ({ ...state, gridView }));
  };

  const handleNumberOfColumnsChange = (numberOfColumns: number) => {
    setState((state) => ({ ...state, numberOfColumns }));
  };

  // Used for selection area
  // const selectableFilesIds = files.map((file) => file.name);

  return {
    ...state,

    // Used for selection area
    // selectableFilesIds,
    handleGridViewChange,
    handleNumberOfColumnsChange,

    ...(_files.length && _files[0].type === "generator"
      ? {
          gridView: "fill" as GridViewKey,
        }
      : {}),
  };
};

const FileManager = ({
  files = [],
  hasMore = false,
  loadMore = () => {},
  fileHandlers,
  isLoading = false,

  options = {
    canChangeView: true,
    canChangeNumberOfColumns: true,
    withoutTopBar: false,
  },

  styleProps,

  title,

  scrollRef,

  loadingText,

  topBarLeftContent,
}: FileManagerProps) => {
  const {
    gridView,
    numberOfColumns,
    // selectableFilesIds,

    handleGridViewChange,
    handleNumberOfColumnsChange,
  } = useFileManagerOptions(files, options);

  const { selected, handleSelectAll, handleSelect, handleClearSelection } =
    useSelection(files);

  const [revealed, setRevealed] = useState<string[]>([]);
  const handleReveal = useCallback(
    (id: string) => {
      if (revealed.includes(id)) {
        setRevealed(revealed.filter((item) => item !== id));
      } else {
        setRevealed([...revealed, id]);
      }
    },
    [revealed]
  );

  const selectedFiles = useMemo(
    () => files.filter((file) => selected.has(file.id)),
    [files, selected]
  );

  useClickOutside(() => {
    handleClearSelection();
  });

  // const {
  // selected,
  // handleSelectAll,
  // handleDeselectAll,
  //   ...selectionAreaProps
  // } = useSelectionArea({
  // selectable: selectableFilesIds,
  // });

  // TODO: Temporary fix for images that open in popup
  const assets = useMemo(() => {
    return files
      .map((file) => file.type === "image" && file)
      .filter(Boolean) as FileImageType[];
  }, [files]);

  const handleAdvancedSelect = useCallback(
    (id: string, { shiftPressed }: { shiftPressed: boolean }) => {
      // onSelect();
      handleSelect(id, { shiftPressed });
    },
    [handleSelect]
  );

  return (
    <Box pos="relative" h="full">
      {/* // TODO: Temporary fix for images that open in popup */}
      <AssetZoom
        assets={assets}
        hasMore={hasMore || false}
        loadMore={loadMore}
        revealed={revealed}
        onReveal={handleReveal}
      />

      {!options.withoutTopBar && (
        <FileManagerTopBar
          styleProps={styleProps?.topbar}
          gridView={gridView}
          canChangeView={options.canChangeView}
          onGridViewChange={handleGridViewChange}
          numberOfColumns={numberOfColumns}
          canChangeNumberOfColumns={options.canChangeNumberOfColumns}
          onNumberOfColumnsChange={handleNumberOfColumnsChange}
          sort={options.sort}
          sortOptions={options.sortOptions}
          onSortChange={options.onSortChange}
          filterAssetType={options.filterAssetType}
          filterAssetTypeOptions={options.filterAssetTypeOptions}
          onFilterAssetTypeChange={options.onFilterAssetTypeChange}
          leftContent={topBarLeftContent}
          title={title}
        />
      )}

      {isLoading ? (
        // Loading state
        <Center>
          <Spinner />
        </Center>
      ) : !files.length ? (
        // Empty state
        <VStack>
          {Object.keys(fileHandlers).map((key) => (
            <Fragment key={key}>
              {fileHandlers[key as keyof typeof fileHandlers]?.EmptyState}
            </Fragment>
          ))}
        </VStack>
      ) : (
        /* <SelectionArea {...selectionAreaProps}> */
        <FileView
          hasMore={hasMore}
          onLoadMore={loadMore}
          fileHandlers={fileHandlers}
          onSelect={handleSelect}
          selected={selected}
          canSelect={options.canSelect}
          files={files}
          gridView={gridView}
          numberOfColumns={numberOfColumns}
          showFileNames={options.showFileNames}
          scrollRef={scrollRef}
          loadingText={loadingText}
          revealed={revealed}
          onReveal={handleReveal}
        />
        /* </SelectionArea> */
      )}

      <SelectionBar
        fileHandlers={fileHandlers}
        onDeselectAll={handleClearSelection}
        onSelectAll={handleSelectAll}
        files={files}
        selectedFiles={selectedFiles}
      />
    </Box>
  );
};

export default FileManager;
