var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"52263a0525350376e27c3bdbbaf1657247ab7f2b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.NODE_ENV !== "development") {
  // This prevents the SDK from sending any events to Sentry while you are
  // developing your application.

  Sentry.init({
    dsn:
      SENTRY_DSN ||
      "https://074df11ff2d2463d9e42f7afbe95bb0a@o1053117.ingest.sentry.io/4504254817370112",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,

    environment: process.env.NEXT_PUBLIC_ENV,

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [new Sentry.Replay()],

    ignoreErrors: [
      // Occurred when route.navigate is called without teamApi query argument
      /^Abort route change. Please ignore this error\.$/,
      // RTKQuery reject certain Promises with an object instead of an Error instance (not blocking the app)
      /^Non-Error promise rejection captured with keys: error, isUnhandledError, meta$/,
      // Thrown when a user try to access the app through an iframe, witch it's forbidden (occurred also on old Android Phone)
      /^Failed to read the 'contentDocument' property from 'HTMLIFrameElement': Blocked a frame with origin "https:\/\/app.scenario.com" from accessing a cross-origin frame\.$/,
    ],
  });
}
