import React, { useCallback, useEffect, useState } from "react";
import { FileImageType } from "domains/file-manager/interfaces";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import FileTree, { LinkTreeStructure } from "domains/ui/components/FileTree";
import ScenarioInput from "domains/ui/components/ScenarioInput";
import { useUser } from "domains/user/hooks/useUser";
import { useHandleApiError } from "infra/api/error";
import { useDownloadAssetsMutation } from "infra/api/generated/api";
import { uniqueId } from "lodash";

import {
  Center,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface AssetDownloadModalProps {
  onClose: () => void;
  files: FileImageType[];
}

const templateStringAvailable: string[] = [
  "<seed>",
  "<num>",
  "<prompt>",
  "<generator>",
];

const SEED_EXAMPLE = "4121138286829";
const GENERATOR_NAME_EXAMPLE = "character_portrait";
const PROMPT_EXAMPLE_1 = "hat_wearing_llama";
const PROMPT_EXAMPLE_2 = "ugly_mountain_troll";

function createDummyFiles(
  validTemplateNameString: string,
  promptExample?: string
): Array<{ id: string; name: string; type: string }> {
  return Array(5)
    .fill(0)
    .map((_, index) => {
      const replacedName = validTemplateNameString
        .replace(/<seed>/g, SEED_EXAMPLE + uniqueId())
        .replace(/<num>/g, String(index + 1))
        .replace(/<prompt>/g, promptExample ?? PROMPT_EXAMPLE_1)
        .replace(/<generator>/g, GENERATOR_NAME_EXAMPLE);

      return {
        id: uniqueId(),
        name: replacedName + ".png",
        type: "file",
      };
    });
}

const AssetDownloadModal = ({ onClose, files }: AssetDownloadModalProps) => {
  const { selectedTeam } = useTeamContext();
  const [triggerDownloadAssets, { isLoading: isLoadingDownloadAssets }] =
    useDownloadAssetsMutation();
  const { successToast } = useScenarioToast();
  const [fileNameTemplate, setFileNameTemplate] = useState("<prompt>-<num>");
  const [withSubFolders, setWithSubFolders] = useState(false);
  const handleApiError = useHandleApiError();
  const { updateUserSettings, userSettings } = useUser();

  const [structure, setStructure] = useState<LinkTreeStructure[]>([]);

  const assetDownloadFormat = userSettings["asset-download-format"] ?? "";

  const saveFileNameTemplate = useCallback(async () => {
    try {
      await updateUserSettings({
        "asset-download-format": fileNameTemplate,
      });
    } catch (error) {
      handleApiError(
        error,
        "There was an error saving your asset download format"
      );
    }
  }, [updateUserSettings, fileNameTemplate, handleApiError]);

  useEffect(() => {
    if (assetDownloadFormat.length) {
      setFileNameTemplate(assetDownloadFormat);
    }
  }, [assetDownloadFormat]);

  const getFileStructure = useCallback(() => {
    let validTemplateNameString = fileNameTemplate;

    if (!fileNameTemplate) {
      validTemplateNameString = "<prompt>-<num>";
    }

    const dummyFiles = createDummyFiles(validTemplateNameString);

    const scenarioFolder: LinkTreeStructure = {
      id: uniqueId(),
      name: "scenario_export",
      type: "folder",
      children: [],
    };

    if (withSubFolders) {
      scenarioFolder.children = []; // reset the array before adding new items
      [GENERATOR_NAME_EXAMPLE].forEach((generatorName) => {
        const generatorFolder: LinkTreeStructure = {
          id: uniqueId(),
          name: generatorName,
          type: "folder",
          children: [],
        };
        [PROMPT_EXAMPLE_1, PROMPT_EXAMPLE_2].forEach((promptName) => {
          const dummyFilesWithCurrentPrompt = dummyFiles.map((file) => ({
            ...file,
            name: file.name
              .replaceAll(GENERATOR_NAME_EXAMPLE, generatorName)
              .replaceAll(PROMPT_EXAMPLE_1, promptName),
          }));
          generatorFolder?.children?.push({
            id: uniqueId(),
            name: promptName,
            type: "folder",
            children: dummyFilesWithCurrentPrompt,
          });
        });
        scenarioFolder.children?.push(generatorFolder);
      });
    } else {
      scenarioFolder.children = [
        ...dummyFiles,
        ...createDummyFiles(validTemplateNameString, PROMPT_EXAMPLE_2),
      ];
    }

    setStructure([scenarioFolder]);
  }, [withSubFolders, fileNameTemplate]);

  const handleDownload = useCallback(async () => {
    if (!files) {
      return;
    }
    try {
      await triggerDownloadAssets({
        teamId: selectedTeam.id,
        body: {
          query: {
            assetIds: files.map((file) => file.id),
            modelIds: [],
            inferenceIds: [],
          },
          options: {
            flat: !withSubFolders,
            fileNameTemplate: fileNameTemplate,
          },
        },
      }).unwrap();
      void saveFileNameTemplate();
      successToast({
        title: "Download Initiated",
        description:
          "An email with your download link will be sent to you once available.",
      });
      onClose();
    } catch (error) {
      handleApiError(error, "There was an error initiating your download");
    }
  }, [
    files,
    triggerDownloadAssets,
    selectedTeam.id,
    withSubFolders,
    fileNameTemplate,
    saveFileNameTemplate,
    successToast,
    onClose,
    handleApiError,
  ]);

  const handleAppendTemplateString = useCallback((templateString: string) => {
    setFileNameTemplate((prev) =>
      !prev.length ? templateString : `${prev}-${templateString}`
    );
  }, []);

  useEffect(() => {
    if (fileNameTemplate === "") {
      setStructure([]);
    }
  }, [fileNameTemplate, withSubFolders]);

  useEffect(() => {
    getFileStructure();
  }, [fileNameTemplate, withSubFolders, getFileStructure]);

  return (
    <Modal
      closeOnEsc={true}
      isOpen={!!files.length}
      onClose={onClose}
      variant="modern"
    >
      <ModalOverlay />
      <ModalContent
        maxW="900px"
        p={0}
        bgColor="backgroundTertiary.500"
        data-outside-click-excluded={true}
      >
        <ModalBody p={0} data-testid="download-images-modal">
          <Flex dir="column">
            <VStack
              align="left"
              w="55%"
              px={8}
              py={6}
              textAlign="left"
              borderRightWidth={1}
              borderRightColor="border.500"
            >
              <Text w="100%" mb={3} size="title.lg">
                Export Images
              </Text>
              <Flex
                gap={2}
                p={3}
                borderWidth={1}
                borderColor="border.500"
                borderRadius="lg"
                _hover={{
                  bg: "backgroundSecondary.900",
                }}
                cursor={"pointer"}
                onClick={() => {
                  setWithSubFolders(!withSubFolders);
                }}
              >
                <Checkbox
                  pointerEvents="none"
                  colorScheme="primary"
                  isChecked={withSubFolders}
                  size="lg"
                />
                <Flex direction="column" gap={0}>
                  <Text size="body.bold.lg">Include Sub-Folders</Text>
                  <Text size="body.sm">
                    If selected, the assets will be organized into directories
                    according to the generator name and prompt.
                  </Text>
                </Flex>
              </Flex>
              <Text size="title.sm">File name</Text>
              <ScenarioInput
                dataTestId="download-images-modal-filename"
                value={fileNameTemplate}
                setValue={setFileNameTemplate}
                borderColor={
                  fileNameTemplate.length === 0 ? "red.500" : undefined
                }
                rightComponent={
                  <Flex
                    h="38px"
                    p={2}
                    borderLeftWidth={1}
                    borderLeftColor="border.500"
                  >
                    <Center>
                      <Text color="textTertiary" size="body.md">
                        .png
                      </Text>
                    </Center>
                  </Flex>
                }
                type="text"
              />
              <Flex gap={2} py={2}>
                {templateStringAvailable.map((templateString) => (
                  <Button
                    data-testid={`download-images-modal-filename-button-${
                      templateString?.match(/<([^>]+)>/)?.[1]
                    }`}
                    onClick={() => handleAppendTemplateString(templateString)}
                    key={templateString}
                    variant="secondary"
                    size="sm"
                  >
                    {templateString?.match(/<([^>]+)>/)?.[1] ?? ""}
                  </Button>
                ))}
              </Flex>
              <Button
                data-testid="download-images-modal-export-button"
                onClick={handleDownload}
                isLoading={isLoadingDownloadAssets}
                isDisabled={fileNameTemplate.length === 0}
                w="full"
              >
                Export images
              </Button>
            </VStack>
            <VStack
              overflow={"auto"}
              w="45%"
              maxH={"80vh"}
              px={8}
              py={6}
              bgColor="backgroundSecondary.900"
            >
              <Text align="left" w="full" size="title.md">
                Example
              </Text>
              <FileTree structure={structure} />
            </VStack>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AssetDownloadModal;
