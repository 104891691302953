import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import AssetZoomHeader, {
  HEADER_HEIGHT,
} from "domains/assets/components/AssetZoom/AssetZoomHeader";
import AssetZoomImageList, {
  GAP,
  IMAGE_HEIGHT,
} from "domains/assets/components/AssetZoom/AssetZoomImageList";
import AssetZoomNsfw from "domains/assets/components/AssetZoom/AssetZoomNsfw";
import AssetZoomEditPanel from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import AssetZoomInfoPanel from "domains/assets/components/AssetZoom/Panels/AssetZoomInfoPanel";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { useScrollbarSize } from "domains/commons/hooks/useScrollbarSize";
import {
  FileImageType,
  getImageThumbnail,
} from "domains/file-manager/interfaces";
import ManageTags from "domains/tags/components/ManageTags";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import UiPanel from "domains/ui/components/Panel";
import { useUser } from "domains/user/hooks/useUser";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import {
  GetAssetsByAssetIdApiResponse,
  useGetAssetsByAssetIdQuery,
  useGetModelsByModelIdQuery,
  useGetModelsInferencesByModelIdAndInferenceIdQuery,
} from "infra/api/generated/api";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useHotkeys } from "react-hotkeys-hook";

import {
  Box,
  Flex,
  Image as ChakraImage,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export interface AssetZoomProps {
  assets: FileImageType[];
  hasMore: boolean;
  loadMore: () => void | undefined;
  revealed: string[];
  onReveal: (id: string) => void;
}

const AssetZoom = ({
  assets,
  hasMore,
  loadMore,
  revealed,
  onReveal,
}: AssetZoomProps) => {
  const router = useRouter();
  const { selectedTeam } = useTeamContext();
  const scrollbarSize = useScrollbarSize();
  const { isNsfwFilterEnabled } = useUser();

  const [isHovering, setIsHovering] = useState(false);
  const [showEditPanelHeader, setShowEditPanelHeader] = useState(true);

  const [assetFromList, setAssetFromList] = useState<
    GetAssetsByAssetIdApiResponse["asset"] | undefined
  >();
  const [assetId, setAssetId] = useState<string | undefined>();
  const debouncedAssetId = useDebounce(assetId, 500, {
    resetBeforeDebounce: true,
    preventOnNullish: true,
  });

  const remoteAsset = useGetAssetsByAssetIdQuery(
    debouncedAssetId
      ? { assetId: debouncedAssetId, teamId: selectedTeam.id }
      : skipToken
  );

  const asset = useMemo(() => {
    if (assetId && assetId === remoteAsset?.data?.asset.id) {
      return remoteAsset.data.asset;
    } else if (assetId && assetId === assetFromList?.id) {
      return assetFromList;
    } else {
      return undefined;
    }
  }, [assetId, remoteAsset?.data?.asset, assetFromList]);

  /** @deprecated
   * the old way of doing it was openAssetId = ${modelId}|${inferenceId}|${assetIndex}
   * now we do it with just the assetId
   * but we need to keep this for people who bookmarked the old url
   */
  const [deprecatedAsset, setDeprecatedAsset] = useState<
    | {
        modelId: string;
        inferenceId: string;
        assetIndex: number;
      }
    | undefined
  >();

  const deprecatedInference =
    useGetModelsInferencesByModelIdAndInferenceIdQuery(
      deprecatedAsset && debouncedAssetId
        ? {
            modelId: deprecatedAsset.modelId ?? "",
            inferenceId: deprecatedAsset.inferenceId ?? "",
            teamId: selectedTeam.id,
          }
        : skipToken
    );
  useEffect(() => {
    if (deprecatedInference && deprecatedInference.data && deprecatedAsset) {
      const loadedAssetId =
        deprecatedInference.data.inference.images[deprecatedAsset.assetIndex]
          .id;
      if (loadedAssetId) {
        router.query.openAssetId = loadedAssetId;
        void router.push(
          {
            query: router.query,
          },
          undefined,
          { scroll: false }
        );
      }
    }
  }, [deprecatedInference, deprecatedAsset, router]);

  useEffect(() => {
    if (!router.query.openAssetId) {
      setAssetId(undefined);
      setDeprecatedAsset(undefined);
      return;
    }

    const loadedAssetId = router.query.openAssetId as string;
    if (loadedAssetId.includes("|")) {
      const [modelId, inferenceId, assetIndex] = loadedAssetId.split("|");
      if (modelId && inferenceId && assetIndex) {
        Track(AnalyticsEvents.AssetZoom.UsedDeprecatedOpenAssetId);
        setAssetId(undefined);
        setDeprecatedAsset({
          modelId,
          inferenceId,
          assetIndex: parseInt(assetIndex),
        });
      }
    } else {
      if (loadedAssetId !== assetId) {
        const targetAsset = assets.find((item) => item.id === loadedAssetId);
        if (targetAsset) {
          setAssetFromList(targetAsset.meta);
        } else {
          setAssetFromList(undefined);
        }
        setAssetId(loadedAssetId);
        setDeprecatedAsset(undefined);
      }
    }
  }, [assets, assetId, router.query]);

  const close = useCallback(async () => {
    delete router.query.openAssetId;
    void router.push(
      {
        query: router.query,
      },
      undefined,
      { scroll: false }
    );
  }, [router]);

  const { data: inferenceData } =
    useGetModelsInferencesByModelIdAndInferenceIdQuery(
      debouncedAssetId && asset?.metadata.modelId && asset?.metadata.inferenceId
        ? {
            modelId: asset.metadata.modelId,
            inferenceId: asset.metadata.inferenceId,
            teamId: selectedTeam.id,
          }
        : skipToken
    );
  const inference = useMemo(() => {
    if (
      inferenceData &&
      inferenceData.inference.id === asset?.metadata.inferenceId
    ) {
      return inferenceData.inference;
    }
    return undefined;
  }, [inferenceData, asset]);

  const { data: modelData } = useGetModelsByModelIdQuery(
    debouncedAssetId && asset?.metadata.modelId
      ? { modelId: asset.metadata.modelId, teamId: selectedTeam.id }
      : skipToken
  );
  const model = useMemo(() => {
    if (modelData && modelData.model.id === asset?.metadata.modelId) {
      return modelData.model;
    }
    return undefined;
  }, [modelData, asset]);

  const [isPanelExtended, setIsPanelExtended] = useState(true);

  const fullScreenHandle = useFullScreenHandle();

  const switchImage = useCallback(
    (direction: "next" | "previous") => {
      const currentIndex = assets.findIndex((item) => item.id === asset?.id);
      if (
        currentIndex === -1 ||
        (currentIndex === 0 && direction === "previous") ||
        (currentIndex === assets.length - 1 && direction === "next")
      ) {
        return;
      }
      const targetAsset =
        assets[currentIndex + (direction === "previous" ? -1 : 1)];
      if (!targetAsset) {
        return;
      }
      setAssetFromList(targetAsset.meta);
      setAssetId(targetAsset.id);
      router.query.openAssetId = targetAsset.id;
      void router.push(
        {
          query: router.query,
        },
        undefined,
        { scroll: false }
      );
    },
    [assets, asset, router]
  );

  useHotkeys("Escape", close, [close]);

  useHotkeys("ArrowLeft", () => switchImage("previous"), [switchImage]);
  useHotkeys("ArrowRight", () => switchImage("next"), [switchImage]);

  useHotkeys(
    "f",
    () => {
      if (fullScreenHandle.active) {
        void fullScreenHandle.exit();
      } else {
        void fullScreenHandle.enter();
      }
    },
    [fullScreenHandle]
  );

  return (
    <Modal
      autoFocus={false}
      closeOnEsc={true}
      isOpen={!!assetId}
      onClose={close}
      size={"full"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={0} data-testid="asset-zoom-display">
          <AssetZoomHeader
            close={close}
            model={model}
            inference={inference}
            asset={asset}
          />
          <Flex w={"100%"} h={`calc(100vh - ${HEADER_HEIGHT}px)`}>
            <FullScreen
              handle={fullScreenHandle}
              className={"asset-zoom-fullscreen-component"}
            >
              <Flex direction={"column"} flex={1} h={"100%"}>
                <Flex
                  pos={"relative"}
                  align={"center"}
                  justify={"center"}
                  flex={1}
                  w={"100%"}
                  bgColor={"black"}
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  {asset ? (
                    <>
                      <Box
                        pos={"relative"}
                        overflow={"hidden"}
                        w={"80%"}
                        h={"80%"}
                        bgImage={getImageThumbnail({
                          isNsfwFilterEnabled:
                            isNsfwFilterEnabled && !revealed.includes(asset.id),
                          url: asset.url,
                          nsfw: asset.nsfw ?? [],
                          type: asset.metadata.type,
                        })}
                        bgSize={"contain"}
                        bgPosition={"center"}
                        bgRepeat={"no-repeat"}
                      >
                        <ChakraImage
                          pos={"absolute"}
                          w={"100%"}
                          h={"100%"}
                          opacity={0}
                          alt={"asset image"}
                          data-testid="asset-zoom-image"
                          src={asset.url}
                        />
                        <AssetZoomNsfw
                          asset={asset}
                          revealed={revealed}
                          onReveal={onReveal}
                        />
                      </Box>
                      {isHovering && (
                        <>
                          <Button
                            variant={"secondary"}
                            position={"absolute"}
                            top={0}
                            bottom={0}
                            margin={"auto"}
                            left={5}
                            colorScheme={"white"}
                            onClick={() => switchImage("previous")}
                          >
                            <Icon id={"Ui/ChevronLeft"} />
                          </Button>
                          <Button
                            variant={"secondary"}
                            position={"absolute"}
                            top={0}
                            bottom={0}
                            margin={"auto"}
                            right={5}
                            colorScheme={"white"}
                            onClick={() => switchImage("next")}
                          >
                            <Icon id={"Ui/ChevronRight"} />
                          </Button>
                          <Button
                            variant={"ghost"}
                            position={"absolute"}
                            bottom={5}
                            right={5}
                            colorScheme={"white"}
                            onClick={() => {
                              if (fullScreenHandle.active) {
                                void fullScreenHandle.exit();
                              } else {
                                void fullScreenHandle.enter();
                              }
                            }}
                          >
                            <Icon id={"Ui/Fullscreen"} />
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <Spinner />
                  )}
                </Flex>
                <Flex
                  align={"center"}
                  w={"100%"}
                  h={`${IMAGE_HEIGHT + GAP * 2 + scrollbarSize.height + 1}px`}
                  borderTopWidth={1}
                  bgColor={"secondary.900"}
                >
                  <AssetZoomImageList
                    currentAsset={asset}
                    isPanelExtended={isPanelExtended}
                    isFullScreenActive={fullScreenHandle.active}
                    assets={assets}
                    hasMore={hasMore}
                    loadMore={loadMore}
                    revealed={revealed}
                    onReveal={onReveal}
                  />
                </Flex>
              </Flex>
            </FullScreen>
            <UiPanel
              onExtend={() => setIsPanelExtended(true)}
              onCollapse={() => setIsPanelExtended(false)}
              onChange={() => setShowEditPanelHeader(true)}
              panels={[
                {
                  label: "Info",
                  button: {
                    dataTestId: "panel-info-button",
                    tooltip: "Info",
                    label: "extend panel to see info",
                    icon: "Nav/Info/Outline",
                  },
                  panel: (
                    <AssetZoomInfoPanel
                      model={model}
                      inference={inference}
                      asset={asset}
                    />
                  ),
                },
                {
                  label: showEditPanelHeader ? "Edit" : undefined,
                  button: {
                    dataTestId: "panel-edit-button",
                    tooltip: "Edit",
                    label: "extend panel to see edit image",
                    icon: "Ui/Pencil",
                  },
                  panel: (
                    <AssetZoomEditPanel
                      inference={inference}
                      asset={asset}
                      setShowEditPanelHeader={setShowEditPanelHeader}
                    />
                  ),
                },
                {
                  label: "Tags",
                  button: {
                    dataTestId: "panel-tags-button",
                    tooltip: "Tags",
                    label: "extend panel to see tags",
                    icon: "Nav/Tag/Outline",
                  },
                  panel: <ManageTags items={asset ? [asset] : []} />,
                },
              ]}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AssetZoom;
