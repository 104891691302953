import { PostModelsInferencesByModelIdApiArg } from "infra/api/generated/api";

type API_SCHEDULERS_TYPE = Exclude<
  PostModelsInferencesByModelIdApiArg["body"]["parameters"]["scheduler"],
  undefined
>;

export const PUBLIC_SCHEDULERS: Partial<{
  [key in API_SCHEDULERS_TYPE]: string;
}> = {
  DDIMScheduler: "DDIM",
  EulerAncestralDiscreteScheduler: "Euler Ancestral",
  EulerDiscreteScheduler: "Euler",
  HeunDiscreteScheduler: "Heun",
  UniPCMultistepScheduler: "UniPC",
};

export const PRIVATE_SCHEDULERS: Partial<{
  [key in API_SCHEDULERS_TYPE]: string;
}> = {
  DDIMInverseScheduler: "DDIM Inverse",
  DDPMScheduler: "DDPM",
  DEISMultistepScheduler: "DEIS",
  DPMSolverMultistepInverseScheduler: "DPM Multi steps Inverse",
  DPMSolverMultistepScheduler: "DPM Multi steps",
  DPMSolverSinglestepScheduler: "DPM Single step",
  IPNDMScheduler: "IPNDM",
  KarrasVeScheduler: "Karras",
  KDPM2AncestralDiscreteScheduler: "KDPM Ancestral",
  KDPM2DiscreteScheduler: "KDPM",
  LMSDiscreteScheduler: "LMS",
  PNDMScheduler: "PNDM",
  ScoreSdeVeScheduler: "VE-SDE",
};

export const ALL_SCHEDULERS = {
  ...PUBLIC_SCHEDULERS,
  ...PRIVATE_SCHEDULERS,
};

export type SCHEDULER_TYPE = keyof typeof ALL_SCHEDULERS | undefined;
