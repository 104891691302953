import { useCallback, useState } from "react";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  PutImageUpscaleApiResponse,
  useLazyGetAssetsByAssetIdQuery,
  usePutImageUpscaleMutation,
} from "infra/api/generated/api";

export default function useAssetUpscale() {
  const [isUpscaleLoading, setIsUpscaleLoading] = useState<boolean>(false);
  const [scalingFactor, setScalingFactor] = useState<number>(2);
  const [forceFaceRestoration, setForceFaceRestoration] =
    useState<boolean>(false);
  const [photorealist, setPhotorealist] = useState<boolean>(false);
  const [magic, setMagic] = useState<boolean>(false);
  const { selectedTeam } = useTeamContext();
  const [getAssetByAssetId] = useLazyGetAssetsByAssetIdQuery();
  const [triggerImageUpscale] = usePutImageUpscaleMutation();
  const { infoToast, successToast, errorToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { showLimitModal } = usePlanContext();

  const handleUpscale = useCallback(
    async ({
      assetId,
      afterUpscale,
      trackingEvent,
      trackingExtraParams,
    }: {
      assetId: string;
      afterUpscale?: (upscaledAsset: PutImageUpscaleApiResponse) => void;
      trackingEvent: string;
      trackingExtraParams?: Record<string, unknown>;
    }) => {
      setIsUpscaleLoading(true);
      infoToast({
        title: "Upscaling image...",
      });

      try {
        let upscaledAsset: PutImageUpscaleApiResponse | undefined =
          await triggerImageUpscale({
            teamId: selectedTeam.id,
            body: {
              assetId: assetId,
              image: "",
              returnImage: false,
              name: "",
              forceFaceRestoration: forceFaceRestoration,
              photorealist: photorealist,
              scalingFactor: scalingFactor,
              magic: magic,
              // image and name will be ignored since we pass assetId but are still required...
            },
          }).unwrap();

        while (upscaledAsset && upscaledAsset.asset.status === "pending") {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          try {
            upscaledAsset = await getAssetByAssetId({
              teamId: selectedTeam.id,
              assetId: upscaledAsset.asset.id,
            }).unwrap();
          } catch (_) {
            upscaledAsset = undefined;
          }
        }

        if (!upscaledAsset || upscaledAsset.asset.status !== "success") {
          errorToast({
            title: "Error upscaling image",
          });
          return;
        }

        if (afterUpscale) {
          afterUpscale(upscaledAsset);
        }

        Track(trackingEvent, {
          imageId: assetId,
          forceFaceRestoration: forceFaceRestoration,
          photorealist: photorealist,
          scalingFactor: scalingFactor,
          magic: magic,
          ...trackingExtraParams,
        });

        successToast({
          title: "The image has been upscaled",
        });
      } catch (error: unknown) {
        handleApiError(error, "Error upscaling image", {
          quota: () => {
            showLimitModal("planUpscales");
          },
        });
      } finally {
        setIsUpscaleLoading(false);
      }
    },
    [
      infoToast,
      selectedTeam.id,
      forceFaceRestoration,
      photorealist,
      scalingFactor,
      magic,
      getAssetByAssetId,
      successToast,
      handleApiError,
      showLimitModal,
      errorToast,
      triggerImageUpscale,
    ]
  );

  return {
    upscaleSettings: {
      isUpscaleLoading,
      setIsUpscaleLoading,
      scalingFactor,
      setScalingFactor,
      forceFaceRestoration,
      setForceFaceRestoration,
      photorealist,
      setPhotorealist,
      magic,
      setMagic,
    },
    handleUpscale,
  };
}
