import { assetsTags } from "domains/assets/api/endpoints";
import {
  GetAssetsApiArg,
  GetAssetsByAssetIdApiResponse,
} from "infra/api/generated/api";
import { apiSlice, SelectedInvalidatedByTag } from "infra/store/apiSlice";
import { AppRootState } from "infra/store/store";

import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";

const shouldUpdateQuery = (
  originalArgs: GetAssetsApiArg,
  collectionIds: string[]
) => {
  if (originalArgs.type === "uploaded" && !originalArgs.collectionId) {
    return true;
  }
  if (
    originalArgs.collectionId &&
    collectionIds.includes(originalArgs.collectionId)
  ) {
    return true;
  }
};

export const updateAssetsQueryByUploadingAsset = (
  asset: GetAssetsByAssetIdApiResponse["asset"],
  collectionIds: string[],
  {
    dispatch,
    getState,
  }: {
    dispatch: ThunkDispatch<any, any, AnyAction>;
    getState: () => AppRootState;
  }
) => {
  for (const {
    endpointName,
    originalArgs,
  } of apiSlice.util.selectInvalidatedBy(getState(), [
    assetsTags.asset,
  ]) as SelectedInvalidatedByTag[]) {
    if (
      endpointName === "getAssets" &&
      shouldUpdateQuery(originalArgs, collectionIds)
    ) {
      dispatch(
        apiSlice.util.updateQueryData(endpointName, originalArgs, (draft) => {
          if (draft) {
            const assetExists = draft.assets.some(
              (item) => item.id === asset.id
            );
            if (!assetExists) {
              draft.assets.unshift(asset);
            }
          }
        })
      );
    }
  }
};
