import { updateModelsQueryDataByUpdatingCollections } from "domains/collections/api/updateQueryDataByUpdatingCollections";
import { searchTags } from "domains/search/api/endpoints";
import { updateQueryDataByUpdatingTags } from "domains/tags/api/updateQueryDataByUpdatingTags";
import {
  apiSlice,
  Endpoints,
  SelectedInvalidatedByTag,
} from "infra/store/apiSlice";
import { isEqual, unionBy } from "lodash";

export const generatorsTags = {
  generator: "generator",
} as const;

export const generatorsEndpoints: Endpoints = {
  postModelsTrainingImagesByModelId: {
    extraOptions: {
      maxRetries: 5,
    },
    invalidatesTags: [generatorsTags.generator],
  },
  getModels: {
    merge(existing, incoming) {
      existing.models = unionBy(existing.models, incoming.models, "id");

      // Has not reached the end of the pagination
      if (!existing?.models || existing.nextPaginationToken) {
        existing.nextPaginationToken = incoming.nextPaginationToken;
      }
    },
    serializeQueryArgs: ({ endpointName, queryArgs }) => {
      return (
        endpointName +
        queryArgs.teamId +
        queryArgs.privacy +
        queryArgs.status +
        queryArgs.collectionId
      );
    },
    forceRefetch({ currentArg, previousArg }) {
      return !isEqual(currentArg, previousArg);
    },
    providesTags: (_result, _error, arg) => {
      return [
        {
          type: generatorsTags.generator,
        },
        {
          type: generatorsTags.generator,
          id: `collectionId:${arg.collectionId}`,
        },
      ];
    },
  },
  getModelsByModelId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const { data } = await queryFulfilled;
      if (data) {
        for (const {
          endpointName,
          originalArgs,
        } of apiSlice.util.selectInvalidatedBy(getState(), [
          searchTags.search,
        ]) as SelectedInvalidatedByTag[]) {
          if (endpointName === "postSearch") {
            dispatch(
              apiSlice.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  const model = draft?.results?.find(
                    (item) => item.type === "model" && item.id === arg.modelId
                  );
                  if (model) {
                    Object.assign(model, {
                      ...model,
                      trainingImages: data.model.trainingImages,
                    });
                  }
                }
              )
            );
          }
        }
      }
    },

    providesTags: (_result, _error, arg) => {
      return [
        {
          type: generatorsTags.generator,
          id: `modelId:${arg.modelId}`,
        },
      ];
    },
  },
  deleteModelsByModelId: {
    // Update the cache without refetching
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undoList: (() => void)[] = [];
      try {
        await queryFulfilled;
        // Go through all the queries that use the `inference` tag, remove the deleted inference images from the cache
        for (const {
          endpointName,
          originalArgs,
        } of apiSlice.util.selectInvalidatedBy(getState(), [
          generatorsTags.generator,
        ]) as SelectedInvalidatedByTag[]) {
          if (endpointName === "getModels") {
            const update = dispatch(
              apiSlice.util.updateQueryData(
                endpointName,
                originalArgs,
                (draft) => {
                  draft.models = draft?.models?.filter(
                    (model) => model.id !== arg.modelId
                  );
                }
              )
            );
            undoList.push(update.undo);
          }
          if (endpointName === "getModelsByModelId") {
            // removing the query is done by the invalidatesTags
          }
        }
      } catch (err) {
        // Undo all the updates on error
        for (const undo of undoList) {
          undo();
        }
      }
    },

    invalidatesTags: [generatorsTags.generator],
  },

  postModels: {
    invalidatesTags: [generatorsTags.generator],
  },
  postModelsCopyByModelId: {
    invalidatesTags: [generatorsTags.generator],
  },
  putModelsByModelId: {
    invalidatesTags: [generatorsTags.generator],
  },
  postModelsTransferByModelId: {
    invalidatesTags: [generatorsTags.generator],
  },

  putModelsTagsByModelId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateQueryDataByUpdatingTags(
        arg.teamId ?? "",
        "generator",
        arg.modelId,
        arg.body.add ?? [],
        arg.body.delete ?? [],
        {
          dispatch,
          getState,
        }
      ).undo;
      queryFulfilled.catch(() => undo());
    },
  },

  putModelsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateModelsQueryDataByUpdatingCollections(
        arg.teamId,
        "add",
        arg.body.modelIds,
        arg.collectionId,
        {
          dispatch,
          getState,
        }
      ).undo;

      queryFulfilled.catch(() => undo());
    },
    invalidatesTags: (_result, _error, arg) => {
      return [
        {
          type: generatorsTags.generator,
          id: `collection:${arg.collectionId}`,
        },
      ];
    },
  },

  deleteModelsByCollectionId: {
    onQueryStarted: async (arg, { dispatch, queryFulfilled, getState }) => {
      const undo = updateModelsQueryDataByUpdatingCollections(
        arg.teamId,
        "remove",
        arg.body.modelIds,
        arg.collectionId,
        {
          dispatch,
          getState,
        }
      ).undo;

      queryFulfilled.catch(() => undo());
    },
  },
};
