import React, { useCallback, useEffect } from "react";
import { FileType } from "domains/file-manager/interfaces";
import { keyBy } from "lodash";

export const useSelection = (files: FileType[]) => {
  const [selected, setSelected] = React.useState<Set<string>>(new Set());
  const [lastSelected, setLastSelected] = React.useState<string | null>(null);

  // When files are updated, we want to remove from selection all files that might not exists anymore
  useEffect(() => {
    let hasChanged = false;
    const newSelected = new Set(selected);
    const filesById = keyBy(files, "id");
    newSelected.forEach((id) => {
      if (!filesById[id]) {
        hasChanged = true;
        newSelected.delete(id);
      }
    });
    if (hasChanged) {
      setSelected(newSelected);
    }
  }, [selected, files]);

  const handleSelectAll = () => {
    setSelected((selected) => {
      const newSelected = new Set(selected);
      files.forEach((file) => newSelected.add(file.id));
      setLastSelected(null);
      return newSelected;
    });
  };

  const handleClearSelection = () => {
    setSelected(new Set());
    setLastSelected(null);
  };

  const handleSelectRange = useCallback(
    (from: string, to: string) => {
      setSelected((selected) => {
        const newSelected = new Set(selected);
        const fromIndex = files.findIndex((file) => file.id === from);
        const toIndex = files.findIndex((file) => file.id === to);

        // Shouldn't happen
        if (toIndex === -1 || fromIndex === -1) return selected;

        const [startIndex, endIndex] =
          fromIndex < toIndex ? [fromIndex, toIndex] : [toIndex, fromIndex];
        for (let i = startIndex; i <= endIndex; i++) {
          newSelected.add(files[i].id);
        }
        return newSelected;
      });

      setLastSelected(to);
    },
    [files]
  );

  const handleSelect = useCallback(
    (id: string, { shiftPressed }: { shiftPressed: boolean }) => {
      if (!shiftPressed || !lastSelected) {
        setSelected((selected) => {
          const newSelected = new Set(selected);
          if (selected.has(id)) {
            newSelected.delete(id);
            setLastSelected(null);
          } else {
            newSelected.add(id);
            setLastSelected(id);
          }

          return newSelected;
        });
      } else {
        handleSelectRange(lastSelected, id);
      }
    },
    [handleSelectRange, lastSelected]
  );

  return {
    selected,
    handleSelect,
    handleSelectAll,
    handleClearSelection,
    handleSelectRange,
  };
};
