import { useCallback } from "react";
import { useRouter } from "next/router";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";

import * as Sentry from "@sentry/react";

const Handlers = {
  429: "quota",
  413: "file_size",
} as const;
type HandlersKeys = keyof typeof Handlers;
type HandlersValues = (typeof Handlers)[HandlersKeys];

export const useHandleApiError = () => {
  const { errorToast } = useScenarioToast();
  const router = useRouter();

  return useCallback(
    (
      error: any,
      errorTitle: string,
      handlers?: Partial<{
        [key in HandlersValues]: () => void;
      }>
    ) => {
      if (
        error.status !== 429 &&
        error.status !== 503 &&
        error.status !== 403
      ) {
        Sentry.captureException(error);
      }

      if (error.status === 503) {
        void router.push({
          pathname: "/down",
        });
        return;
      }

      if (error.status && handlers) {
        for (const [handlerType, handler] of Object.entries(handlers)) {
          if (Handlers[error.status as HandlersKeys] === handlerType) {
            handler();
            return;
          }
        }
      }

      if (error.data?.reason) {
        errorToast({
          title: errorTitle,
          description: error.data.reason,
        });
        return;
      }

      errorToast({
        title: "Something went wrong, please try again later.",
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};
