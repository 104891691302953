import { useMemo, useRef } from "react";
import { useChatContext } from "domains/navigation/contexts/ChatProvider";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { MenuItem, MenuItemProps } from "domains/ui/components/Menu";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuList,
  Text,
} from "@chakra-ui/react";

import SupportFormModal from "../SupportFormModal";
import { SupportFormModalHandle } from "../SupportFormModal/index";

type ActionListItem =
  | {
      id: string;
      label: string;
      externalLink?: MenuItemProps["externalLink"];
      internalLink?: MenuItemProps["internalLink"];
      onClick?:
        | "toggleChat"
        | "openSupportFormModal"
        | MenuItemProps["onClick"];
    }
  | "divider";

const ACTION_LIST: ActionListItem[] = [
  {
    id: "support",
    label: "Contact Us",
    onClick: "openSupportFormModal",
  },
  {
    id: "knowledgeCenter",
    label: "Knowledge Center",
    externalLink: "https://help.scenario.com",
    onClick: () => {
      Track(AnalyticsEvents.Navigation.ClickedHelpCenter);
    },
  },
  {
    id: "chat",
    label: "Chat Support",
    onClick: "toggleChat",
  },
];

export default function HelpButtonDropdown() {
  const { selectedTeam } = useTeamContext();
  const { isChatVisible, setIsChatVisible } = useChatContext();
  const supportFormModalRef = useRef<SupportFormModalHandle>(null);

  const actionList = useMemo(
    () =>
      ACTION_LIST.filter((item) => {
        if (item === "divider") {
          return true;
        } else if (item.id === "support" && selectedTeam.plan === "free") {
          return false;
        } else {
          return true;
        }
      }),
    [selectedTeam.plan]
  );

  return (
    <>
      <SupportFormModal ref={supportFormModalRef} />

      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          pos="relative"
          w="36px"
          h="36px"
          p={0}
          _hover={{
            bgColor: "backgroundQuaternary.500",
          }}
          bgColor="backgroundTertiary.500"
          variant="unstyled"
        >
          <Icon id="Layout/Help" mt="-2px" />
        </MenuButton>
        <MenuList overflowY="auto" w="220px" maxH="80vh" px={2}>
          {actionList.map((item, idx) => {
            if (item === "divider") {
              return <MenuDivider key={`divider-${idx}`} mx={-2} />;
            }

            const onClick = (() => {
              if (item.onClick === "toggleChat") {
                return () => {
                  if (isChatVisible) {
                    setIsChatVisible(false);
                    return;
                  }
                  setIsChatVisible(true);
                  Track(AnalyticsEvents.Navigation.ClickedChat);
                };
              } else if (item.onClick === "openSupportFormModal") {
                return () => {
                  if (!supportFormModalRef.current) return;
                  supportFormModalRef.current.open();
                  Track(AnalyticsEvents.Navigation.ClickedSupport);
                };
              } else if (typeof item.onClick === "function") {
                return item.onClick;
              }
            })();

            return (
              <MenuItemAction
                key={item.id}
                p={2}
                internalLink={item.internalLink}
                externalLink={item.externalLink}
                onClick={onClick}
                borderRadius="md"
              >
                <Text size="body.md">{item.label}</Text>
              </MenuItemAction>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
}

interface MenuItemActionProps extends MenuItemProps {
  variant?: "ghost" | "primary";
}

function MenuItemAction({ variant, ...props }: MenuItemActionProps) {
  if (variant === "primary") {
    return (
      <MenuItem
        bgColor="primary.500"
        _hover={{
          bgColor: "primary.300",
        }}
        _active={{
          bgColor: "primary.400",
        }}
        {...props}
      />
    );
  } else {
    return (
      <MenuItem
        _hover={{
          bgColor: "backgroundTertiary.800",
        }}
        {...props}
      />
    );
  }
}
