import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FileImageType,
  mapInferencesToImagesFiles,
} from "domains/file-manager/interfaces";
import { InferenceStatus } from "domains/inference/enum/InferenceStatus";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import {
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
  useLazyGetModelsInferencesByModelIdAndInferenceIdQuery,
} from "infra/api/generated/api";

interface SessionProviderProps {
  files: FileImageType[];
  addInference: (
    inference: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
  ) => void;
  clearSession: () => void;
  deleteImages: (imagesIds: string[]) => void;
}

export const SessionContext = createContext<SessionProviderProps>({
  files: [],
  addInference: () => {},
  clearSession: () => {},
  deleteImages: () => {},
});

export function SessionProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const { selectedTeam } = useTeamContext();
  const [inferences, setInferences] = useState<
    GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"][]
  >([]);
  const [getInferenceTrigger] =
    useLazyGetModelsInferencesByModelIdAndInferenceIdQuery();
  const intervals = useRef<any[]>();
  const [deletedImagesIds, setDeletedImagesIds] = useState<string[]>([]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      intervals.current?.forEach((interval) => clearInterval(interval));
    };
  }, []);

  const refreshInference = useCallback(
    async (
      inference: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    ) => {
      const interval = setInterval(async () => {
        const { data: getInferenceData } = await getInferenceTrigger({
          modelId: inference.modelId,
          inferenceId: inference.id,
          teamId: selectedTeam.id,
        });
        if (!getInferenceData) return;

        const updatedInference = getInferenceData.inference;
        setInferences((inferences) => {
          const inferenceIndex = inferences.findIndex(
            (i) => i.id === inference.id
          );
          if (inferenceIndex === -1) {
            return inferences;
          }
          inferences[inferenceIndex] = {
            ...updatedInference,
            images: [
              ...updatedInference.images,
              ...new Array(
                Math.max(
                  inference.parameters.numSamples! -
                    updatedInference.images.length,
                  0
                )
              )
                .fill({})
                .map(() => ({
                  id: inference.id + "-" + Math.random(),
                  url: "",
                  seed: "",
                })),
            ],
          };
          return [...inferences];
        });

        if (
          updatedInference.status === InferenceStatus.Failed ||
          updatedInference.status === InferenceStatus.Succeeded
        ) {
          clearInterval(interval);
          intervals.current = intervals.current?.filter((i) => i !== interval);
        }
      }, 5000);
      intervals.current = [...(intervals.current || []), interval];
    },
    [getInferenceTrigger, selectedTeam.id]
  );

  const addInference = useCallback(
    async (
      inference: GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    ) => {
      setInferences((inferences) => [
        ...inferences,
        {
          ...inference,
          images: new Array(inference.parameters.numSamples)
            .fill({})
            .map(() => ({
              id: inference.id + "-" + Math.random(),
              url: "",
              seed: "",
            })),
        },
      ]);
      void refreshInference(inference);
    },
    [refreshInference]
  );

  const clearSession = useCallback(async () => {
    setInferences([]);
  }, []);

  const deleteImages = useCallback(async (imagesIds: string[]) => {
    setDeletedImagesIds((deletedImagesIds) => [
      ...deletedImagesIds,
      ...imagesIds,
    ]);
  }, []);

  const files = useMemo(
    () =>
      mapInferencesToImagesFiles(inferences)
        .filter((item) => !deletedImagesIds.includes(item.id))
        .reverse(),
    // The files are added in reverse order to the file manager, so we need to reverse them here to display the most recent first
    [deletedImagesIds, inferences]
  );

  return (
    <SessionContext.Provider
      value={{
        files,
        addInference,
        clearSession,
        deleteImages,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export function useSessionContext() {
  return useContext<SessionProviderProps>(SessionContext);
}
