import React, { useCallback, useState } from "react";
import { useRouter } from "next/router";
import AssetZoomEraseBackground from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomEraseBackground";
import AssetZoomPixelate from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomPixelate";
import AssetZoomUpscale from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomUpscale";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import {
  GetAssetsByAssetIdApiResponse,
  GetModelsInferencesByModelIdAndInferenceIdApiResponse,
} from "infra/api/generated/api";
import _ from "lodash";

import {
  Center,
  Flex,
  HStack,
  Image,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

export interface AssetZoomEditPanelProps {
  inference:
    | GetModelsInferencesByModelIdAndInferenceIdApiResponse["inference"]
    | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  setShowEditPanelHeader: (show: boolean) => void;
}

export const EditButton = (props: {
  text: string;
  icon: string;
  rightComponent: React.ReactElement;
  onClick: () => void;
  isDisabled?: boolean;
  dataTestId?: string;
}) => {
  return (
    <Button
      variant={"ghost"}
      justifyContent={"start"}
      colorScheme={"white"}
      size={"lg"}
      data-testid={props.dataTestId}
      leftIcon={
        <Center
          overflow={"hidden"}
          w={"36px"}
          h={"36px"}
          borderRadius={"lg"}
          bgColor={"backgroundQuaternary.500"}
        >
          <Image maxW={"36px"} maxH={"36px"} alt={"icon"} src={props.icon} />
        </Center>
      }
      rightIcon={props.rightComponent}
      onClick={props.onClick}
      gap={3}
      isDisabled={props.isDisabled}
      p={1.5}
      borderRadius={"base"}
      maxW={"unset"}
      w={"100%"}
    >
      <Text overflow={"hidden"} maxW={"80%"} textOverflow={"ellipsis"}>
        {props.text}
      </Text>
      <Flex flex={1} />
    </Button>
  );
};

export type ACTIONS = "pixelate" | "upscale" | "remove background";

const ShowCurrentActionPanel = ({
  currentAction,
  asset,
  updateCurrentAction,
}: {
  currentAction: ACTIONS | undefined;
  asset: GetAssetsByAssetIdApiResponse["asset"];
  updateCurrentAction: (action: ACTIONS | undefined) => void;
}) => {
  if (currentAction === "pixelate") {
    return (
      <AssetZoomPixelate
        asset={asset}
        updateCurrentAction={updateCurrentAction}
      />
    );
  }
  if (currentAction === "upscale") {
    return (
      <AssetZoomUpscale
        asset={asset}
        updateCurrentAction={updateCurrentAction}
      />
    );
  }
  if (currentAction === "remove background") {
    return (
      <AssetZoomEraseBackground
        asset={asset}
        updateCurrentAction={updateCurrentAction}
      />
    );
  }
  return null;
};

const AssetZoomEditPanel = ({
  inference,
  asset,
  setShowEditPanelHeader,
}: AssetZoomEditPanelProps) => {
  const router = useRouter();
  const [currentAction, setCurrentAction] = useState<ACTIONS | undefined>();

  const updateCurrentAction = useCallback(
    (action: ACTIONS | undefined) => {
      setCurrentAction(action);
      setShowEditPanelHeader(action === undefined);
    },
    [setShowEditPanelHeader]
  );

  if (!asset) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const chevronRightComponent = (
    <Icon
      id={"Ui/ChevronRight"}
      pr={1.5}
      h={"12px"}
      color={"white"}
      opacity={0.5}
    />
  );

  return currentAction ? (
    <VStack align={"start"} spacing={8}>
      <HStack
        w={"100%"}
        cursor={"pointer"}
        onClick={() => updateCurrentAction(undefined)}
        spacing={5}
      >
        <Icon id={"Ui/ChevronLeft"} height={"13px"} />
        <Text size={"title.sm"}>{_.capitalize(currentAction)}</Text>
      </HStack>
      <ShowCurrentActionPanel
        currentAction={currentAction}
        asset={asset}
        updateCurrentAction={updateCurrentAction}
      />
    </VStack>
  ) : (
    <Flex direction={"column"} gap={3.5}>
      <EditButton
        text={"Remove background"}
        icon="/removeBg.png"
        rightComponent={chevronRightComponent}
        onClick={() => updateCurrentAction("remove background")}
        dataTestId="edit-image-remove-background"
      />
      <EditButton
        text={"Refine"}
        icon="/refine.png"
        dataTestId="edit-image-refine"
        rightComponent={
          <Button
            tooltip="Use as a reference for new variants."
            _hover={{}}
            size="xs"
            _active={{}}
          >
            Refine
          </Button>
        }
        onClick={() => {
          void router.push(
            `/images/new?generatorId=${inference?.modelId}&importAssetId=${asset.id}`
          );
          if (inference?.modelId) {
            Track(AnalyticsEvents.Inference.SelectedGenerator, {
              id: inference.modelId,
            });
          }
        }}
      />
      <EditButton
        text={"Upscale"}
        icon="/upscale.png"
        dataTestId="edit-image-upscale"
        rightComponent={chevronRightComponent}
        onClick={() => updateCurrentAction("upscale")}
      />
      <EditButton
        text={"Pixelate"}
        icon="/pixelate.png"
        dataTestId="edit-image-pixelate"
        rightComponent={chevronRightComponent}
        onClick={() => updateCurrentAction("pixelate")}
      />
    </Flex>
  );
};

export default AssetZoomEditPanel;
