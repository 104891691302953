import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { GridViewKey } from "domains/file-manager/constants/GridView";
import { FileHandler, FileType } from "domains/file-manager/interfaces";
import { useHover } from "domains/ui/hooks/useHover";
import _ from "lodash";
import { isSafari } from "react-device-detect";

import {
  Box,
  Center,
  Checkbox,
  Fade,
  Flex,
  Grid,
  Image as ChakraImage,
  Skeleton,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { HandleSelect } from "../FileManager";

export type FilePreviewProps<F extends FileType> = {
  fileHandler: FileHandler<F>;

  file: F;

  onSelect?: HandleSelect;
  isSelected?: boolean;

  onDrag?: MouseEventHandler<HTMLDivElement>;

  canSelect?: boolean;

  selectModeEnabled?: boolean;

  isLoading?: boolean;

  gridView?: GridViewKey;
  showFileNames?: "always" | "hover" | "never";

  isRevealed?: boolean;
  onReveal?: (id: string) => void;
};

const FilePreview = React.memo(
  function FilePreview(props: FilePreviewProps<FileType>) {
    const {
      file,
      fileHandler,

      gridView,

      isLoading,

      selectModeEnabled,
      isSelected,
      onSelect,
      onDrag,
      canSelect,
      showFileNames,
    } = props;

    // const [imgHeight, setImgHeight] = useState<number[] | undefined>();
    const [hoverRef, isHovered] = useHover<HTMLDivElement>();

    const { imgRef, isLoadingImage, placeholderImage } = useImageLoading({
      width: file.width,
      height: file.height,
      url: file.thumbnail,
    });

    const imgHeightRatio = Math.round((file.height / file.width) * 100);

    const handleFileClick: MouseEventHandler<HTMLDivElement> = (event) => {
      if (selectModeEnabled) {
        onSelect?.(file.id, { shiftPressed: event.shiftKey });
      } else if (event.shiftKey) {
        onSelect?.(file.id, { shiftPressed: false });
      } else {
        fileHandler.onOpen?.(file);
      }

      event.stopPropagation();
    };

    const handleCheckboxChange: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        onSelect?.(file.id, {
          shiftPressed: event.shiftKey,
        });

        event.stopPropagation();
      },
      [onSelect, file.id]
    );

    const handleFileDrag: MouseEventHandler<HTMLDivElement> = useCallback(
      (event) => {
        onDrag?.(event);
        event.stopPropagation();
      },
      [onDrag]
    );

    return (
      <Box
        ref={hoverRef}
        pos="relative"
        zIndex={0}
        w="100%"
        h={0}
        pt={gridView === "masonry" ? `${imgHeightRatio}%` : "100%"}
        data-outside-click-excluded={true}
        draggable
        onClick={handleFileClick}
        onDragEnd={handleFileDrag}
      >
        {/* Image display */}
        <Grid
          pos="absolute"
          top={0}
          w="100%"
          h="100%"
          borderRadius="md"
          cursor="pointer"
          bgColor="backgroundSecondary.500"
        >
          <ChakraImage
            ref={imgRef}
            pos="relative"
            w="100%"
            h="100%"
            borderRadius="md"
            alt=""
            data-testid="asset-gallery-preview-image"
            fallbackSrc={placeholderImage}
            filter={isSelected ? "brightness(1.2)" : ""}
            loading={isSafari ? "eager" : "lazy"}
            src={
              isLoading && !file.thumbnail ? placeholderImage : file.thumbnail
            }
            {...(gridView !== "masonry"
              ? {
                  position: "absolute",
                  top: 0,
                  objectFit: gridView === "fill" ? "cover" : "contain",
                }
              : {
                  objectFit: "fill",
                })}
          />

          <Fade in={isLoadingImage} unmountOnExit>
            <Skeleton
              pos="absolute"
              zIndex={1}
              top={0}
              left={0}
              w="100%"
              h="100%"
            />
          </Fade>

          <Fade
            // Alway show on hover to make the checkbox visible on white images when its selectable
            in={canSelect && isHovered}
            style={{
              marginTop: gridView !== "masonry" ? `100%` : "",
            }}
            unmountOnExit
          >
            <Flex
              pos="absolute"
              zIndex={3}
              top={0}
              align="end"
              direction="column"
              overflow="hidden"
              w="100%"
              h="100%"
              borderRadius="md"
              pointerEvents={"none"}
            >
              <Flex
                className="bg-gradient-hover"
                direction={"column"}
                w="100%"
                h="100%"
              >
                {showFileNames === "hover" && isHovered && (
                  <Text
                    pos="absolute"
                    bottom={2}
                    left={2}
                    // overflow="hidden"
                    noOfLines={2}
                    size="body.sm"
                  >
                    {file.name}
                  </Text>
                )}
              </Flex>
            </Flex>
          </Fade>

          {canSelect && (isHovered || selectModeEnabled) && (
            <Flex
              pos="absolute"
              zIndex={4}
              top={0}
              direction={"column"}
              display={["none", "none", "flex"]}
              p={2}
              data-testid="asset-gallery-preview-image-checkbox"
              // this is to prevent the file from being open when clicking on the checkbox, stopping the propagation on the on onChange doesn't work
              onClick={handleCheckboxChange}
            >
              {!isLoading && (
                <Checkbox
                  pointerEvents="none"
                  colorScheme="primary"
                  isChecked={isSelected}
                  // Do not use the onChange as it doesn't transmit the shift key
                  size="xl"
                  variant="lightCircular"
                />
              )}
            </Flex>
          )}

          {isLoading && (
            <Center
              pos="absolute"
              zIndex={4}
              top={0}
              left={0}
              w="100%"
              h="100%"
            >
              <Spinner />
            </Center>
          )}

          {selectModeEnabled && isSelected && (
            <Box
              pos="absolute"
              zIndex={5}
              top={0}
              left={0}
              w="100%"
              h="100%"
              borderRadius="md"
              outline="3px solid"
              outlineColor="primary.500"
              outlineOffset="-3px"
            />
          )}
        </Grid>

        {showFileNames === "always" && (
          <Flex
            align="center"
            justify="center"
            w="100%"
            h="40px"
            mb="-40px"
            p={2}
          >
            <Text
              textColor={"textPrimary"}
              textAlign={"center"}
              isTruncated
              size={"body.md"}
            >
              {file.name}
            </Text>
          </Flex>
        )}
      </Box>
    );
  },
  (
    prevProps: FilePreviewProps<FileType>,
    props: FilePreviewProps<FileType>
  ) => {
    return _.isEqual(prevProps, props);
  }
);

export default FilePreview;

// ------------------------------------

const getPlaceholderImage = _.memoize(
  (width: number, height: number) => {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext("2d");
    context!.fillStyle = "#333";
    context!.fillRect(0, 0, width, height);
    return canvas.toDataURL();
  },
  (width, height) => `${width}x${height}`
);

function isCached(src: string) {
  const image = new Image();
  image.src = src;
  return image.complete;
}

function useImageLoading({
  width,
  height,
  url,
}: {
  width: number;
  height: number;
  url: string;
}) {
  const imgRef = useRef<HTMLImageElement>(null);

  const [isLoadingImage, setIsLoadingImage] = useState(!isCached(url));

  const placeholderImage = useMemo(() => {
    return getPlaceholderImage(width || 512, height || 512);
  }, [width, height]);

  useEffect(() => {
    if (!imgRef.current) return;

    imgRef.current.onload = () => setIsLoadingImage(false);
  }, [imgRef, url]);

  return {
    width,
    height,
    url,
    imgRef,
    isLoadingImage,
    placeholderImage,
  };
}
