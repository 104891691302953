import React, { useCallback, useState } from "react";
import { useRouter } from "next/router";
import { ACTIONS } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import {
  ButtonsField,
  SwitchField,
} from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomEditPanelCommons";
import PixelateSelectColorPalette from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel/AssetZoomPixelate/colorPalette";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { usePlanContext } from "domains/teams/hooks/usePlan";
import Button from "domains/ui/components/Button";
import ColorPicker from "domains/ui/components/ColorPicker";
import { ColorPalette } from "domains/ui/components/ColorPicker/defaultColorPalettes";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  GetAssetsByAssetIdApiResponse,
  usePutImagesPixelateMutation,
} from "infra/api/generated/api";
import { Color } from "react-color";

import {
  Box,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
} from "@chakra-ui/react";

const NewColorsField = ({
  newColorPalette,
  setNewColorPalette,
}: {
  newColorPalette: ColorPalette;
  setNewColorPalette: (newColorPalette: ColorPalette) => void;
}) => {
  const [newColor, setNewColor] = useState<Color | undefined>();

  return (
    <HStack wrap={"wrap"} gap={1} spacing={0}>
      {newColorPalette.colors.map((color, index) => (
        <Box
          key={color + index}
          w={"28px"}
          h={"28px"}
          borderRadius={"md"}
          cursor={"pointer"}
          bgColor={color}
          onClick={() => {
            const tmpColors = [...newColorPalette.colors];
            void tmpColors.splice(index, 1);
            setNewColorPalette({
              ...newColorPalette,
              colors: tmpColors,
            });
          }}
        />
      ))}
      <Popover>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                variant={"secondary"}
                leftIcon={<Icon id="Ui/Plus" />}
                h={"28px"}
                w={"28px"}
                minW={"unset"}
                p={0}
              />
            </PopoverTrigger>
            <PopoverContent borderWidth={0}>
              <ColorPicker
                withEditableInput={true}
                color={newColor}
                onChange={(color: any) => setNewColor(color.hsl)}
                onSubmit={(color: string) => {
                  setNewColorPalette({
                    ...newColorPalette,
                    colors: [...newColorPalette.colors, color],
                  });
                  setNewColor(undefined);
                  onClose();
                }}
              />
            </PopoverContent>
          </>
        )}
      </Popover>
    </HStack>
  );
};

export interface AssetZoomPixelateProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  updateCurrentAction: (action: ACTIONS | undefined) => void;
}

const PIXEL_GRID_SIZES = [32, 64, 128, 256];

const AssetZoomPixelate = ({
  asset,
  updateCurrentAction,
}: AssetZoomPixelateProps) => {
  const router = useRouter();
  const { infoToast, successToast } = useScenarioToast();
  const handleApiError = useHandleApiError();
  const { selectedTeam } = useTeamContext();
  const { showLimitModal } = usePlanContext();
  const [removeNoise, setRemoveNoise] = useState<boolean>(false);
  const [removeBackground, setRemoveBackground] = useState<boolean>(false);
  const [pixelGridSize, setPixelGridSize] = useState<number>(64);
  const [shouldUseNewColorPalette, setShouldUseNewColorPalette] =
    useState<boolean>(false);
  const [newColorPalette, setNewColorPalette] = useState<ColorPalette>({
    id: Math.random().toString(),
    name: "Custom palette",
    colors: [],
  });
  const [selectedColorPalette, setSelectedColorPalette] = useState<
    ColorPalette | undefined
  >();

  const [pixelateTrigger, { isLoading: pixelateIsLoading }] =
    usePutImagesPixelateMutation();
  const handlePixelate = useCallback(async () => {
    infoToast({
      title: "Pixelating image...",
    });

    const getColorPalette = () => {
      if (shouldUseNewColorPalette) {
        return newColorPalette.colors.map((color) => {
          return [
            parseInt(color.slice(1, 3), 16),
            parseInt(color.slice(3, 5), 16),
            parseInt(color.slice(5, 7), 16),
          ];
        });
      }
      if (selectedColorPalette) {
        return selectedColorPalette.colors.map((color) => {
          return [
            parseInt(color.slice(1, 3), 16),
            parseInt(color.slice(3, 5), 16),
            parseInt(color.slice(5, 7), 16),
          ];
        });
      }
      return undefined;
    };

    try {
      const pixelatedImage = await pixelateTrigger({
        teamId: selectedTeam.id,
        body: {
          assetId: asset.id,
          returnImage: true,
          pixelGridSize: pixelGridSize,
          removeNoise: removeNoise,
          removeBackground: removeBackground,
          colorPalette: getColorPalette(),
        },
      }).unwrap();

      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: pixelatedImage.asset.id,
        },
      });
      updateCurrentAction(undefined);

      successToast({
        title: "Image pixelated",
      });

      Track(AnalyticsEvents.ImageLibrary.PixelatedImage, {
        imageId: asset.id,
      });
    } catch (error: unknown) {
      handleApiError(error, "Error pixelating image", {
        quota: () => {
          showLimitModal("planPixelates");
        },
      });
    }
  }, [
    updateCurrentAction,
    router,
    infoToast,
    shouldUseNewColorPalette,
    selectedColorPalette,
    newColorPalette.colors,
    pixelateTrigger,
    selectedTeam.id,
    asset.id,
    pixelGridSize,
    removeNoise,
    removeBackground,
    successToast,
    handleApiError,
    showLimitModal,
  ]);

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <PixelateSelectColorPalette
        shouldUseNewColorPalette={shouldUseNewColorPalette}
        setShouldUseNewColorPalette={setShouldUseNewColorPalette}
        newColorPalette={newColorPalette}
        setNewColorPalette={setNewColorPalette}
        selectedColorPalette={selectedColorPalette}
        setSelectedColorPalette={setSelectedColorPalette}
        imgSrc={asset.url}
      />
      <SwitchField
        title={"Custom color palette"}
        description={"Change colors of your images to match a new palette"}
        isChecked={shouldUseNewColorPalette}
        setIsChecked={setShouldUseNewColorPalette}
      />
      {shouldUseNewColorPalette && (
        <NewColorsField
          newColorPalette={newColorPalette}
          setNewColorPalette={setNewColorPalette}
        />
      )}
      <ButtonsField
        title={"Pixel grid size"}
        options={PIXEL_GRID_SIZES}
        selectedOption={pixelGridSize}
        setSelectedOption={setPixelGridSize}
        textToAppend={"px"}
      />
      <SwitchField
        title={"Remove noise"}
        isChecked={removeNoise}
        setIsChecked={setRemoveNoise}
      />
      <SwitchField
        title={"Remove background"}
        isChecked={removeBackground}
        setIsChecked={setRemoveBackground}
      />
      <Button onClick={handlePixelate} isLoading={pixelateIsLoading} w={"100%"}>
        Pixelate
      </Button>
    </VStack>
  );
};

export default AssetZoomPixelate;
