import React from "react";

import { Box, Grid, GridItem, Text } from "@chakra-ui/react";

export interface PanelInfoGridLayoutProps {
  items: {
    label: string | React.ReactElement;
    value: string | number | React.ReactElement;
    fullRow?: boolean;
  }[];
}

const PanelInfoGridLayout = ({ items }: PanelInfoGridLayoutProps) => {
  return (
    <Grid gap={6} rowGap={6} templateColumns="repeat(2, 1fr)">
      {items.map((item, index) => (
        <GridItem
          key={`panelInfoGrid-${index}`}
          colSpan={item.fullRow ? 2 : undefined}
        >
          {typeof item.label === "function" ? (
            item.label
          ) : (
            <Box pb={2}>
              <Text color="white.500" size="body.bold.md">
                {item.label}
              </Text>
            </Box>
          )}
          {typeof item.value === "function" ? (
            item.value
          ) : (
            <Text color="textSecondary" size="body.md">
              {item.value}
            </Text>
          )}
        </GridItem>
      ))}
    </Grid>
  );
};

export default PanelInfoGridLayout;
