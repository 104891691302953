import React from "react";
import { useRouter } from "next/router";
import isBreakpointMobile from "domains/commons/isMobile";
import SideMenuCollections from "domains/navigation/components/SideMenu/Collections";
import { useSidebarContext } from "domains/navigation/contexts/SidebarProvider";
import CreateTeamModal from "domains/teams/components/CreateTeamModal";
import { extraTheme } from "domains/theme";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";

import {
  Avatar,
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Fade,
  Flex,
  HStack,
  Text,
  Tooltip,
  useBreakpoint,
} from "@chakra-ui/react";

import SideMenuLink from "./SideMenuLink";

const SIDEBAR_OPEN_WIDTH = "248px";
const SIDEBAR_CLOSED_WIDTH = "56px";

/** Depending on if the page should be displayed in fullscreen or not, the sideBarWidth will vary */
export function useSideBarWidth() {
  const { isOpen } = useSidebarContext();
  return isOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH;
}

export default function SideMenu() {
  const router = useRouter();

  const breakpoint = useBreakpoint();
  const isMobile = isBreakpointMobile(breakpoint);
  const { isOpen, close, open } = useSidebarContext();

  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const showFull = isOpen || isHovered;

  const path = router.pathname;

  const sideBarWidth = useSideBarWidth();
  const contents: JSX.Element = (
    <>
      <CreateTeamModal />
      <Flex
        ref={hoverRef}
        pos="fixed"
        zIndex="overlay"
        direction="column"
        overflowX="hidden"
        overflowY="auto"
        w={showFull ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH}
        h="100%"
        borderRightWidth="1px"
        borderRightColor="border.500"
        transition={extraTheme.transitions.fast}
        __css={{
          "&::-webkit-scrollbar": {
            width: "0",
          },
        }}
        bgColor="black.500"
        data-testid="sidemenu"
      >
        <HStack
          align="center"
          justify="start"
          h="56px"
          px={showFull ? "22px" : "16px"}
          borderBottomWidth="1px"
          borderBottomColor="border.500"
          transition={extraTheme.transitions.fast}
          spacing={3}
        >
          <Avatar w="24px" h="24px" name="Scenario" src="/logos/scenario.svg" />

          <Box flex={1}>
            <Fade in={showFull} unmountOnExit>
              <Text
                overflow="hidden"
                overflowX="hidden"
                w="100%"
                whiteSpace="nowrap"
                size="body.bold.lg"
              >
                Scenario
              </Text>
            </Fade>
          </Box>

          <Fade in={showFull} unmountOnExit>
            <Tooltip
              closeOnClick={false}
              hasArrow
              label={isOpen ? "Unpin Menu" : "Pin Menu"}
              placement="right"
            >
              <Button
                variant="ghost"
                colorScheme="white"
                px={0}
                w="36px"
                mr={-3}
                onClick={isOpen ? close : open}
                data-testid={
                  isOpen ? `sidemenu-unpin-button` : `sidemenu-pin-button`
                }
              >
                <Icon
                  id="Layout/Pin"
                  color={isOpen ? "textPrimary" : "textTertiary"}
                />
              </Button>
            </Tooltip>
          </Fade>
        </HStack>

        <Flex
          direction={"column"}
          gap={2}
          p={"16px"}
          transition={extraTheme.transitions.fast}
          paddingX={showFull ? "16px" : "2"}
        >
          <SideMenuLink
            short={!showFull}
            text="Home"
            icon="Nav/Home/Outline"
            iconActive="Nav/Home/Solid"
            link="/"
            active={path === "/"}
          />
          <SideMenuLink
            short={!showFull}
            text="Generators"
            icon="Nav/Generator/Outline"
            iconActive="Nav/Generator/Solid"
            link="/generators"
            plusLink="/generators/new"
            active={path.startsWith("/generators") && !path.includes("/new")}
            onClick={() => {
              Track(AnalyticsEvents.Navigation.ClickedGenerators);
            }}
          />
          <SideMenuLink
            short={!showFull}
            text="Images"
            icon="Nav/Image/Outline"
            iconActive="Nav/Image/Solid"
            link="/images"
            plusLink="/images/new"
            active={path === "/images"}
            onClick={() => {
              Track(AnalyticsEvents.Navigation.ClickedImages);
            }}
          />

          {!isMobile && (
            <SideMenuLink
              short={!showFull}
              text="Canvas"
              icon="Nav/Canvas/Outline"
              iconActive="Nav/Canvas/Solid"
              link="/canvas"
              plusLink="/canvas/new"
              active={path.startsWith("/canvas")}
              onClick={() => {
                Track(AnalyticsEvents.Navigation.ClickedCanvas);
              }}
            />
          )}
        </Flex>

        <SideMenuCollections showFull={showFull} />
      </Flex>

      {/* Spacer under the side menu to move the rest of the page like the side menu was on the left, prevent a glitch if we switch to absolute positioning only when needed */}
      <Flex
        w={isOpen ? SIDEBAR_OPEN_WIDTH : SIDEBAR_CLOSED_WIDTH}
        h="100%"
        transition={extraTheme.transitions.fast}
      />
    </>
  );

  if (isMobile) {
    return (
      <>
        <Drawer
          isOpen={isOpen}
          onClose={() => {
            close();
          }}
          placement="left"
        >
          <DrawerOverlay />
          <DrawerContent w={sideBarWidth} maxW={sideBarWidth} h="100vh">
            {contents}
          </DrawerContent>
        </Drawer>
      </>
    );
  }

  return contents;
}
