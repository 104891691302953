import React, { useCallback } from "react";
import { useRouter } from "next/router";
import AssetUpscaleSettings from "domains/assets/components/AssetUpscaleSettings";
import { ACTIONS } from "domains/assets/components/AssetZoom/Panels/AssetZoomEditPanel";
import useAssetUpscale from "domains/assets/hooks/useAssetUpscale";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import {
  GetAssetsByAssetIdApiResponse,
  PutImageUpscaleApiResponse,
} from "infra/api/generated/api";

import { VStack } from "@chakra-ui/react";

export interface AssetZoomUpscaleProps {
  asset: GetAssetsByAssetIdApiResponse["asset"];
  updateCurrentAction: (action: ACTIONS | undefined) => void;
}

const AssetZoomUpscale = ({
  asset,
  updateCurrentAction,
}: AssetZoomUpscaleProps) => {
  const router = useRouter();
  const { upscaleSettings, handleUpscale } = useAssetUpscale();

  const afterUpscale = useCallback(
    (upscaledAsset: PutImageUpscaleApiResponse) => {
      void router.push({
        pathname: router.pathname,
        query: {
          ...router.query,
          openAssetId: upscaledAsset.asset.id,
        },
      });
      updateCurrentAction(undefined);
    },
    [router, updateCurrentAction]
  );

  const onUpscale = useCallback(() => {
    void handleUpscale({
      assetId: asset.id,
      afterUpscale: afterUpscale,
      trackingEvent: AnalyticsEvents.ImageLibrary.UpscaledImage,
    });
  }, [asset, afterUpscale, handleUpscale]);

  return (
    <VStack align={"start"} w={"100%"} spacing={5}>
      <AssetUpscaleSettings {...upscaleSettings} onUpscale={onUpscale} />
    </VStack>
  );
};

export default AssetZoomUpscale;
