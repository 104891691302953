import React, { useCallback, useRef, useState } from "react";
import useAllAssets from "domains/assets/hooks/useAllAssets";
import useAssetFilterType from "domains/assets/hooks/useAssetFilterType";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import { PersistedStateKey } from "domains/commons/hooks/usePersistedState";
import FileManager from "domains/file-manager/components/FileManager";
import { useFileImageHandler } from "domains/file-manager/hooks/useFileImageHandler";
import { FileImageType } from "domains/file-manager/interfaces";
import SearchBar, {
  SearchQueryData,
} from "domains/search/components/SearchBar";
import { useAssetsSearch } from "domains/search/hooks/useSearch";
import Button from "domains/ui/components/Button";
import Divider from "domains/ui/components/Divider";
import Icon from "domains/ui/components/Icon";

import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";

export interface ImageUploadProps {
  isDraggingHover: boolean;
  onImageUpload: () => void;
  onDrop: (event: React.DragEvent<HTMLElement>) => void;
  onSelected?: (asset: FileImageType) => void;
  dragFunctions: {
    onDragEnter: (event: React.DragEvent<HTMLElement>) => void;
    onDragLeave: (event: React.DragEvent<HTMLElement>) => void;
    onDragOver: (event: React.DragEvent<HTMLElement>) => void;
  };
}

const ImageUpload = ({
  isDraggingHover,
  onImageUpload,
  onDrop,
  onSelected,
  dragFunctions,
}: ImageUploadProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [query, setQuery] = useState("");
  const [assetId, setAssetId] = useState<string | undefined>();
  const [searchQueryData, setSearchQueryData] = useState<SearchQueryData>({
    query: "",
    assetId: undefined,
  });
  const scrollRef = useRef<HTMLDivElement>(null);
  const { allAssetsSortArgs, assetFileManagerSortOptions } = useAssetSort();
  const { allAssetsTypeArgs, assetFileManagerTypeOptions } = useAssetFilterType(
    { defaultValue: "inference" }
  );
  const allAssetsResponse = useAllAssets({
    ...allAssetsSortArgs,
    ...allAssetsTypeArgs,
  });
  const searchResponse = useAssetsSearch({
    ...searchQueryData,
    ...allAssetsTypeArgs,
  });

  const onSearch = useCallback((searchData: SearchQueryData) => {
    setSearchQueryData(searchData);
  }, []);

  const imageFileHandler = useFileImageHandler({
    emptyState: <Heading size="md">No results</Heading>,
  });

  const showSearchResults = searchQueryData.query || searchQueryData.assetId;
  const fileManagerProps = {
    files: showSearchResults ? searchResponse.files : allAssetsResponse.files,
    isLoading: showSearchResults
      ? searchResponse.isLoading
      : allAssetsResponse.isLoading,
    loadMore: showSearchResults
      ? searchResponse.loadMore
      : allAssetsResponse.loadMore,
    hasMore: showSearchResults
      ? searchResponse.hasMore
      : allAssetsResponse.hasMore,
    loadingText: showSearchResults ? "Searching..." : "Loading...",
  };

  return (
    <>
      <VStack {...dragFunctions} gap={2} w="100%" onDrop={onDrop}>
        {onSelected && (
          <>
            <Button variant="secondary" size="sm" w="100%" onClick={onOpen}>
              Select from Library
            </Button>
            <Divider label="OR" w="100%" />
          </>
        )}
        <Flex
          direction={"column"}
          gap={2}
          w="100%"
          px={8}
          py={4}
          borderWidth={1}
          borderStyle={"dashed"}
          borderColor={isDraggingHover ? "primary.500" : "border.500"}
          borderRadius={"xl"}
          _hover={{
            bgColor: "whiteAlpha.200",
          }}
          cursor={"pointer"}
          bgColor={"whiteAlpha.50"}
          data-testid="drag-and-drop-image-upload-area"
          onClick={onImageUpload}
        >
          <Icon id="Ui/ImgFile" h={9} />
          <Text color={"textSecondary"} textAlign={"center"} size={"body.md"}>
            Drop image or{" "}
            <Text
              as={"span"}
              color={"primary.500"}
              textDecoration={"underline"}
              size={"body.bold.md"}
            >
              import your file
            </Text>
          </Text>
        </Flex>
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} variant={"modern"}>
        <ModalOverlay />
        <ModalContent
          overflow={"hidden"}
          w={"90vw"}
          maxW={"unset"}
          h={"80vh"}
          p={0}
        >
          <ModalCloseButton />
          <ModalBody w={"100%"} id={"pickImageModal"}>
            <VStack w={"100%"} h={"80vh"} spacing={0}>
              <Box w={"100%"} pr="80%" px={5} py={3} borderBottomWidth={1}>
                <Box w={"100%"} maxW={"500px"}>
                  <SearchBar
                    query={query}
                    setQuery={setQuery}
                    assetId={assetId}
                    setAssetId={setAssetId}
                    onSearch={onSearch}
                  />
                </Box>
              </Box>
              <Box ref={scrollRef} flex={1} overflow={"auto"} w="100%" px={5}>
                <FileManager
                  styleProps={{
                    topbar: {
                      top: "0px",
                      bg: "secondary.900",
                    },
                  }}
                  {...fileManagerProps}
                  fileHandlers={{
                    image: {
                      ...imageFileHandler,
                      onOpen: (asset: FileImageType) => {
                        onClose();
                        onSelected?.(asset);
                      },
                    },
                  }}
                  options={{
                    cacheKey: PersistedStateKey.FILE_MANAGER_IMAGE,
                    canSelect: false,
                    canChangeView: true,
                    canChangeNumberOfColumns: true,
                    ...assetFileManagerTypeOptions,
                    ...(showSearchResults ? {} : assetFileManagerSortOptions),
                  }}
                  scrollRef={scrollRef}
                />
              </Box>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ImageUpload;
