import React, { useEffect, useRef, useState } from "react";
import usePersistedState, {
  PersistedStateKey,
} from "domains/commons/hooks/usePersistedState";
import { TOP_BAR_HEIGHT } from "domains/navigation/components/TopBar";
import { extraTheme } from "domains/theme";
import Icon, { IconProps } from "domains/ui/components/Icon";
import { useHover } from "domains/ui/hooks/useHover";

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";

export const PANEL_EXTENDED_WIDTH = 420;
export const PANEL_COLLAPSED_WIDTH = 57;

export interface PanelProps {
  panelsHeader?: React.ReactNode;
  panels: {
    label: string | undefined;
    button: {
      icon: IconProps["id"];
      label: string;
      tooltip?: string;
      dataTestId?: string;
    };
    panel: React.ReactNode;
  }[];
  onExtend?: () => void;
  onCollapse?: () => void;
  onChange?: () => void;
}

export default function Panel({
  panels,
  panelsHeader,
  onExtend,
  onCollapse,
  onChange,
}: PanelProps) {
  const [isExtended, setIsExtended] = usePersistedState<boolean>(
    PersistedStateKey.PANEL_EXTENDED,
    { defaultValue: false }
  );
  const [panelIndex, setPanelIndex] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();

  const showFull = isExtended || isHovered;

  const handlePinClick = () => {
    setIsExtended(!isExtended);
    setTimeout(() => {
      if (scrollRef.current) scrollRef.current.scrollTo({ top: 0, left: 0 });
    }, 1);
  };

  const handleButtonClick = (index: number) => {
    if (index !== panelIndex) {
      setPanelIndex(index);
      onChange?.();
      setTimeout(() => {
        if (scrollRef.current) scrollRef.current.scrollTo({ top: 0, left: 0 });
      }, 1);
    }
  };

  useEffect(() => {
    if (isExtended && onExtend) {
      onExtend();
    }
    if (!isExtended && onCollapse) {
      onCollapse();
    }
  }, [isExtended, onExtend, onCollapse]);

  return (
    <>
      <VStack
        ref={hoverRef}
        pos="fixed"
        zIndex="sticky"
        top={`${TOP_BAR_HEIGHT}px`}
        right={0}
        align="flex-end"
        overflow={"hidden"}
        w={`${showFull ? PANEL_EXTENDED_WIDTH : PANEL_COLLAPSED_WIDTH}px`}
        h={`calc(100vh - ${TOP_BAR_HEIGHT}px)`}
        borderLeftWidth={1}
        transition={extraTheme.transitions.fast}
        bgColor="secondary.900"
        spacing={0}
      >
        <HStack
          ref={scrollRef}
          pos="relative"
          align={"start"}
          justify={"start"}
          flex="1"
          w={PANEL_EXTENDED_WIDTH}
          spacing={0}
        >
          <Box
            overflow={"auto"}
            w={`${PANEL_EXTENDED_WIDTH - PANEL_COLLAPSED_WIDTH}px`}
            h={`calc(100vh - ${TOP_BAR_HEIGHT}px)`}
            p={6}
            borderRightWidth={1}
            __css={{
              "&::-webkit-scrollbar": {
                width: "0",
              },
            }}
          >
            {panelsHeader && <Box pb={6}>{panelsHeader}</Box>}
            {panels[panelIndex].label !== undefined && (
              <Text pb={8} size={"title.sm"}>
                {panels[panelIndex].label}
              </Text>
            )}
            {panels[panelIndex].panel}
          </Box>
          <PanelButtons
            buttons={panels.map((panel) => panel.button)}
            onButtonClick={handleButtonClick}
            isExtended={isExtended}
            panelIndex={panelIndex}
            onPinClick={handlePinClick}
          />
        </HStack>
      </VStack>
      {/* Spacer under the side menu to move the rest of the page like the side menu was on the left, prevent a glitch if we switch to absolute positioning only when needed */}
      <Flex
        w={isExtended ? PANEL_EXTENDED_WIDTH : PANEL_COLLAPSED_WIDTH}
        h="100%"
        transition={extraTheme.transitions.fast}
      />
    </>
  );
}

interface PanelButtonsProps {
  buttons: {
    icon: IconProps["id"];
    label: string;
    tooltip?: string;
    dataTestId?: string;
  }[];
  onButtonClick: (index: number) => void;
  panelIndex: number;
  isExtended: boolean;
  onPinClick: () => void;
}

function PanelButtons({
  buttons,
  onButtonClick,
  panelIndex,
  isExtended,
  onPinClick,
}: PanelButtonsProps) {
  return (
    <VStack
      justify={"space-between"}
      w={`${PANEL_COLLAPSED_WIDTH}px`}
      h={"100%"}
      px={2.5}
      py={3}
    >
      <VStack spacing={4}>
        {buttons.map((button, idx) => (
          <Tooltip
            key={`${idx}`}
            isDisabled={!button.tooltip}
            label={button.tooltip}
            placement="left"
          >
            <IconButton
              borderWidth={panelIndex === idx ? 1 : 0}
              borderRadius={"lg"}
              aria-label={button.label}
              colorScheme={"white"}
              data-testid={button.dataTestId}
              icon={<Icon id={button.icon} />}
              isActive={panelIndex === idx}
              onClick={() => onButtonClick(idx)}
              variant="ghost"
            />
          </Tooltip>
        ))}
      </VStack>
      <IconButton
        aria-label={isExtended ? "unpin panel" : "pin panel"}
        colorScheme={"white"}
        data-testid="panel-pin-button"
        icon={
          <Icon
            id={isExtended ? "Ui/Pin/Solid" : "Ui/Pin/Outline"}
            h={"16px"}
          />
        }
        onClick={onPinClick}
        variant="ghost"
      />
    </VStack>
  );
}
