import { fetchInflight } from "infra/api/fetchInflight";

const loadBase64ImageFromUrl = async (
  url: string
): Promise<string | undefined> => {
  try {
    const data = await fetchInflight(url);
    const blob = await data.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    return new Promise((resolve) => {
      reader.onloadend = () => {
        const base64data = reader.result;
        if (base64data) {
          resolve(
            (base64data as string).replace("binary/octet-stream", "image/jpeg")
          );
        } else {
          resolve(undefined);
        }
      };
    });
  } catch (_) {
    return undefined;
  }
};

export default loadBase64ImageFromUrl;
