import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useRouter } from "next/router";
import getFutureDateByMinutes from "domains/canvasv2/utils/getFutureDateByMinutes";
import DecisionModal from "domains/ui/components/DecisionModal";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  useLockAssetByAssetIdMutation,
  useUnlockAssetByAssetIdMutation,
} from "infra/api/generated/api";

import { Text } from "@chakra-ui/react";

export const LOCK_ID_KEY = "lockId";
export const LOCKED_ASSET_ID_KEY = "lockedAssetId";
export const LOCKED_ASSET_ID_TO_FORCE_UNLOCK_KEY = "lockedAssetIdToForceUnlock";
export const LOCKED_ASSET_GENERATOR_ID = "lockedAssetGeneratorId";
interface CanvasLockProviderProps {
  lock: (assetId: string) => void;
  unlock: (assetId?: string, force?: boolean) => void;
  setDisplayLockModal: (display: boolean) => void;
  isAssetLocked: (assetId: string, lockExpireAt?: string) => boolean;
  clearCanvasLockSessionStorage: () => void;
}

export const CanvasLockContext = createContext<CanvasLockProviderProps>({
  lock: async () => {},
  unlock: async () => {},
  setDisplayLockModal: () => {},
  isAssetLocked: () => false,
  clearCanvasLockSessionStorage: () => {},
});

export function CanvasLockProvider({
  children = <></>,
}: {
  children?: React.ReactNode;
}) {
  const [displayLockModal, setDisplayLockModal] = useState(false);
  const router = useRouter();
  const handleApiError = useHandleApiError();
  const [putLockAssetTrigger] = useLockAssetByAssetIdMutation();
  const [putUnlockAssetTrigger] = useUnlockAssetByAssetIdMutation();

  const clearCanvasLockSessionStorage = useCallback(() => {
    sessionStorage.removeItem(LOCK_ID_KEY);
    sessionStorage.removeItem(LOCKED_ASSET_ID_KEY);
    sessionStorage.removeItem(LOCKED_ASSET_ID_TO_FORCE_UNLOCK_KEY);
    sessionStorage.removeItem(LOCKED_ASSET_GENERATOR_ID);
  }, []);

  useEffect(() => {
    if (displayLockModal) {
      Track(AnalyticsEvents.CanvasV2.DisplayLockModal);
    }
  }, [displayLockModal]);

  const isAssetLocked = useCallback(
    (assetId: string, lockExpireAt?: string): boolean => {
      const lockedAssetId = sessionStorage.getItem(LOCKED_ASSET_ID_KEY);
      if (!lockExpireAt) {
        return false;
      }

      if (lockedAssetId !== assetId) {
        const lockExpiresAt = new Date(lockExpireAt);
        const now = new Date();
        if (lockExpiresAt > now) {
          return true;
        }
      }
      return false;
    },
    []
  );

  const lock = useCallback(
    async (assetId: string) => {
      const lockId = sessionStorage.getItem(LOCK_ID_KEY);
      if (!lockId) {
        sessionStorage.setItem(LOCK_ID_KEY, "LOCK_REQUEST_STARTED");
        try {
          const response = await putLockAssetTrigger({
            assetId: assetId,
            teamId: router.query.teamId as string,
            body: {
              lockExpiresAt: getFutureDateByMinutes(5).toISOString(),
            },
          }).unwrap();
          sessionStorage.setItem(LOCK_ID_KEY, response.lockId as string);
          sessionStorage.setItem(LOCKED_ASSET_ID_KEY, assetId);
        } catch (_) {}
      }
    },
    [handleApiError, putLockAssetTrigger, router.query.teamId]
  );

  const unlock = useCallback(
    async (assetId?: string, force = false) => {
      const assetIdToUnlock =
        assetId ?? sessionStorage.getItem(LOCKED_ASSET_ID_KEY);
      const lockId = sessionStorage.getItem(LOCK_ID_KEY) as string;
      if (assetIdToUnlock) {
        try {
          await putUnlockAssetTrigger({
            assetId: assetIdToUnlock,
            teamId: router.query.teamId as string,
            body: {
              lockId: force ? undefined : lockId,
              forceUnlock: force,
            },
          }).unwrap();
          clearCanvasLockSessionStorage();
        } catch (error) {
          handleApiError(error, "Error unlocking canvas");
        }
      }
    },
    [
      putUnlockAssetTrigger,
      router.query.teamId,
      clearCanvasLockSessionStorage,
      handleApiError,
    ]
  );

  const handleForceUnlock = useCallback(async () => {
    let assetId = router.query.id as string;
    const generatorId = sessionStorage.getItem(LOCKED_ASSET_GENERATOR_ID);
    if (!assetId) {
      assetId = sessionStorage.getItem(
        LOCKED_ASSET_ID_TO_FORCE_UNLOCK_KEY
      ) as string;
      await unlock(assetId, true);
      void router.push({
        pathname: `/canvas/[id]`,
        query: {
          id: assetId,
          teamId: router.query.teamId,
          generatorId: generatorId as string,
        },
      });
    } else {
      await unlock(assetId, true);
      void lock(assetId);
    }
    Track(AnalyticsEvents.CanvasV2.ForceUnlock, {
      assetId: assetId,
    });
    setDisplayLockModal(false);
  }, [router, unlock, lock]);

  const handleGoBack = useCallback(() => {
    void router.push("/canvas");
    setDisplayLockModal(false);
  }, [router, setDisplayLockModal]);

  const contextValues = useMemo(
    () => ({
      clearCanvasLockSessionStorage,
      isAssetLocked,
      lock,
      unlock,
      setDisplayLockModal,
    }),
    [
      clearCanvasLockSessionStorage,
      isAssetLocked,
      lock,
      unlock,
      setDisplayLockModal,
    ]
  );

  return (
    <CanvasLockContext.Provider value={contextValues}>
      <DecisionModal
        isOpen={displayLockModal}
        onClose={handleGoBack}
        onConfirm={handleForceUnlock}
        headerMessage="Edit in Progress"
        body={
          <>
            <Text mb={1} textColor={"textSecondary"}>
              A member of your workspace is currently editing this canvas
              project. Please try accessing it again later.
              <br />
              Please try accessing it again later.
            </Text>
            <Text textColor={"textSecondary"}>
              If you think this message has appeared in error,
              <br />
              you can override the canvas lock below.
            </Text>
          </>
        }
        confirmMessage="Override"
      />
      {children}
    </CanvasLockContext.Provider>
  );
}

export function useCanvasLock() {
  return useContext(CanvasLockContext);
}
