export class AnalyticsEvents {
  static readonly CreateGenerator = {
    ClickedCategory: "createModel/chooseCategory",
    ClickedClass: "createModel/chooseClass",
    HelpChooseClass: "createModel/helpChooseClass",

    AddedTrainingImage: "createModel/addMoreImages",

    DeletedTrainingImage: "createModel/deleteImage",
    DeletedAllTrainingImage: "createModel/deleteAllImages",
    UpdatedTrainingImageDescription: "createModel/updateImageDescription",

    TrainingQueued: "createModel/triggerTraining",

    TrainingSampleBackgroundRemoved: "createModel/removeImageBackground",
  };

  static readonly Navigation = {
    ClickedGenerators: "app/clickGenerators",
    ClickedImages: "app/clickImages",
    ClickedCanvas: "app/clickCanvas",
    ClickedLogout: "app/clickLogOut",
    ClickedUpgrade: "app/clickUpgrade",

    ClickedHelpCenter: "app/clickHelpCenter",
    ClickedChat: "app/clickChat",
    ClickedFeedback: "app/clickFeedback",
    ClickedSupport: "app/clickSupport",
    ClickedTeamSettings: "app/clickTeamSettings",
    ClickedProfile: "app/clickProfile",

    ClickedPin: "app/clickPin",

    ClickedUnity: "app/clickUnity",
  };

  static readonly Generator = {
    Copied: "generator/copied",
    Deleted: "generator/deleted",
    CopiedTrained: "generator/copiedTrained",
  };

  static readonly Inference = {
    SelectedGenerator: "generateInference/confirmGenerator",
    CreatedInference: "generateInference/trigger",
    SelectedPromptModifier: "generateInference/selectedPromptModifier",
    ImportedInference: "generateInference/importedInference",
    ClearPrompt: "generateInference/clearPrompt",
    EditPrompt: "generateInference/editPrompt",
    EditTag: "generateInference/editTag",
    ImportedReferenceImage: "generateInference/importedReferenceImage",
    UsedDeprecatedImportAssetId:
      "generateInference/usedDeprecatedImportAssetId",
  };

  static readonly ImageLibrary = {
    RemovedBackground: "inferences/removeBackground",
    UpscaledImage: "inferences/upscaledImage",
    DownloadedImage: "imageLibrary/downloadedImage",
    PixelatedImage: "imageLibrary/pixelatedImage",
    DeletedInference: "imageLibrary/deleteInference",
    DeletedInferenceImage: "imageLibrary/deleteInferenceImage",
    DeletedImages: "imageLibrary/deletedImages",
    UploadedImage: "imageLibrary/uploadedImage",
  };

  static readonly Signup = {
    ReferralCaptured: "signup/referralCaptured",
  };

  static readonly User = {
    PolicyAccepted: "user/policyAccepted",
    Onboarded: "user/onboarded",
  };

  static readonly Canvas = {
    ImportedImage: "canvas/importedImage",
    InpaintingInference: "canvas/inpaintingInference",
    Img2ImgInference: "canvas/img2imgInference",
    Txt2ImgInference: "canvas/txt2imgInference",
    DrawMask: "canvas/drawMask",
    Sketch: "canvas/sketch",
    Downloaded: "canvas/downloaded",
    NewCanvasCreated: "canvas/newCanvasCreated",
    UndoTriggered: "canvas/undoTriggered",
    RedoTriggered: "canvas/redoTriggered",
  };

  static readonly CanvasV2 = {
    CreatedInference: "canvasV2/inference",
    Delete: "canvasV2/delete",
    DisplayLockModal: "canvasV2/displayLockModal",
    Download: "canvasV2/download",
    Draw: "canvasV2/draw",
    Duplicate: "canvasV2/duplicate",
    ForceUnlock: "canvasV2/forceUnlock",
    ImportedFromGallery: "canvasV2/importedFromGallery",
    NewProject: "canvas/newProject",
    OpenSection: "canvasV2/openSection",
    RemoveBackground: "canvasV2/removeBackground",
    Search: "canvasV2/search",
    Upload: "canvasV2/upload",
    Upscale: "canvasV2/upscale",
  };

  static readonly Gallery = {
    ChangeGridView: "gallery/changeGridView",
    ChangeColumnsNumber: "gallery/changeColumnsNumber",
  };

  static readonly Team = {
    CreatedTeam: "team/createdTeam",
    DeletedTeam: "team/deletedTeam",
    AddedMember: "team/addedMember",
    RemovedMember: "team/removedMember",
    CreatedApiKey: "team/createdApiKey",
    DeletedApiKey: "team/deletedApiKey",
    LeftTeam: "team/memberLeft",
    RemovedPendingMember: "team/removedPendingMember",
  };

  static readonly Subscription = {
    RedirectedToPaymentPage: "subscription/redirectedToPaymentPage",
    CancelledPlan: "subscription/cancelledPlan",
    UpgradedPlan: "subscription/upgradedPlan",
    DowngradedPlan: "subscription/downgradedPlan",
    AddedSeats: "subscription/addedSeats",
    RemovedSeats: "subscription/removedSeats",
  };

  static readonly Search = {
    Searched: "search/searched",
    ImportedImage: "search/importedImage",
  };

  static readonly AssetZoom = {
    UsedDeprecatedOpenAssetId: "assetZoom/usedDeprecatedOpenAssetId",
  };
}
