import { useEffect } from "react";
import { useRouter } from "next/router";
import { useAuth0 } from "domains/auth/components/AuthProvider";
import Loading from "domains/ui/components/Loading";

import { Center } from "@chakra-ui/react";

const publicRoutes = ["/login"];

export const useIsPublicRoute = () => {
  const { pathname } = useRouter();
  return publicRoutes.includes(pathname);
};

export function AuthRedirectGuard({ children }: { children: React.ReactNode }) {
  const { isLoading, isAuthenticated } = useAuth0();
  const router = useRouter();
  const isPublicRoute = useIsPublicRoute();

  useEffect(() => {
    if (isLoading || !router.isReady) return;

    if (isPublicRoute && isAuthenticated) {
      void router.replace("/");
    }

    if (!isPublicRoute && !isAuthenticated) {
      const referral = router.query.referral;
      void router.replace({
        pathname: "/login",
        query: referral ? { referral } : {},
      });
    }
  }, [isLoading, isAuthenticated, router, isPublicRoute]);

  /** Do not flash the public route if the user is authenticated before redirection */
  const willRedirect = isPublicRoute && isAuthenticated;

  return isLoading || willRedirect ? (
    <Center h="100vh">
      <Loading />
    </Center>
  ) : (
    <>{children}</>
  );
}
