import React, { useCallback, useImperativeHandle, useState } from "react";
import Link from "next/link";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { PlanQuotaLimit } from "domains/teams/interfaces/Plan";
import Button from "domains/ui/components/Button";
import Icon from "domains/ui/components/Icon";

import {
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";

type ModalData = {
  [key: string]: {
    title: string;
    label: (isAdmin: boolean, plan?: string) => string;
    buttonType: (isAdmin: boolean) => "upgrade" | "quota" | "close";
  };
};

const MODAL_DATA: ModalData = {
  planActiveGenerators: {
    title: "Active Generator Limit Reached",
    label: (isAdmin: boolean) =>
      "You've hit the cap for active generators allowed by your current plan. " +
      `${
        isAdmin
          ? "To add more generators, you can either upgrade to a different plan or delete one of your existing generators."
          : "To add more generators, you can either ask an admin to upgrade to a different plan or delete one of your existing generators."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planGeneratorTrainings: {
    title: "Training Limit Reached",
    label: (isAdmin: boolean) =>
      "You've reached your monthly limit for training generators with your current plan. " +
      `${
        isAdmin
          ? "To continue training, consider upgrading your plan or wait for your monthly quota to reset."
          : "To continue training, ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planImageGenerations: {
    title: "Image Generation Limit Reached",
    label: (isAdmin: boolean, plan?: string) => {
      if (plan === "free") {
        return (
          "You've reached your limit for generating images with your current plan. " +
          `${
            isAdmin
              ? "To keep creating images, consider upgrading your plan."
              : "To keep creating images, ask an admin to upgrade the workspace plan"
          }`
        );
      } else {
        return (
          "You've reached your monthly limit for generating images with your current plan. " +
          `${
            isAdmin
              ? "To keep creating images, consider upgrading your plan or wait for your monthly quota to reset."
              : "To keep creating images, ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
          }`
        );
      }
    },
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planImageGenerationsRateLimit: {
    title: "Rate Limit Exceeded",
    label: (_isAdmin: boolean) =>
      "You've exceeded the rate limit for generating images. Wait for a moment and try again. Please reach out to increase your rate limit.",
    buttonType: (_isAdmin: boolean) => "close",
  },
  planBackgroundRemovals: {
    title: "Background Removal Limit Reached",
    label: (isAdmin: boolean) =>
      "You have reached the maximum number of background removals allowed by your plan. " +
      `${
        isAdmin
          ? "Consider upgrading your plan or wait for your monthly quota to reset."
          : "Ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planUpscales: {
    title: "Upscale Limit Reached",
    label: (isAdmin: boolean) =>
      "You have reached the maximum number of upscales allowed by your plan. " +
      `${
        isAdmin
          ? "Consider upgrading your plan or wait for your monthly quota to reset."
          : "Ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planTeamMembers: {
    title: "Team Member Limit Reached",
    label: (isAdmin: boolean) =>
      "You've reached the limit for team members allowed by your current plan. " +
      `${
        isAdmin
          ? "To invite more members, you can either upgrade to a different plan or delete one of your existing members."
          : "To invite more members, ask an admin to upgrade to a different plan or delete existing members."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planPixelates: {
    title: "Pixelate Limit Reached",
    label: (isAdmin: boolean) =>
      "You have reached the maximum number of pixelation allowed by your plan. " +
      `${
        isAdmin
          ? "Consider upgrading your plan or wait for your monthly quota to reset."
          : "Ask an admin to upgrade the workspace plan or wait for your monthly quota to reset."
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
  planCollections: {
    title: "Collection Limit Reached",
    label: (isAdmin: boolean, plan?: string) =>
      "You've reached the limit for collections allowed by your current plan. " +
      `${
        isAdmin
          ? `To create more collections, you can upgrade to a different plan${
              plan !== "free"
                ? " or delete one of your existing collections"
                : ""
            }.`
          : `To create more collections, ask an admin to upgrade to a different plan${
              plan !== "free"
                ? " or to delete one of your existing collections"
                : ""
            }.`
      }`,
    buttonType: (isAdmin: boolean) => (isAdmin ? "upgrade" : "close"),
  },
};

export interface PlanQuotaLimitModalHandle {
  open: (limitId: PlanQuotaLimit) => void;
  close: () => void;
}
interface PlanQuotaLimitModalProps {
  hasTrial?: boolean;
}

const PlanQuotaLimitModal = React.forwardRef<
  PlanQuotaLimitModalHandle,
  PlanQuotaLimitModalProps
>(function PlanQuotaLimitModal({ hasTrial }, ref) {
  const { selectedTeam } = useTeamContext();
  const isAdmin = selectedTeam.userRole === "admin";
  const [isOpen, setIsOpen] = useState(false);
  const [limitId, setLimitId] = useState<PlanQuotaLimit | undefined>();

  const modalData = limitId ? MODAL_DATA[limitId] : null;
  const title = modalData?.title;
  const label = modalData?.label
    ? modalData.label(isAdmin, selectedTeam?.plan)
    : undefined;
  const buttonType = modalData?.buttonType
    ? modalData.buttonType(isAdmin)
    : undefined;

  const handleCloseRequest = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleOpenRequest = useCallback(
    (limitId: PlanQuotaLimit) => {
      setLimitId(limitId);
      setIsOpen(true);
    },
    [setLimitId, setIsOpen]
  );

  useImperativeHandle(
    ref,
    () => ({
      open: handleOpenRequest,
      close: handleCloseRequest,
    }),
    [handleOpenRequest, handleCloseRequest]
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCloseRequest} size="xl">
        <ModalOverlay zIndex={"toast"} />
        <ModalContent
          containerProps={{
            zIndex: "tooltip",
          }}
        >
          <ModalCloseButton data-testid="plan-limitation-close-modal-button" />
          <ModalBody>
            <VStack
              align="center"
              px={6}
              py={10}
              textAlign="center"
              spacing={0}
            >
              <Image
                mb={6}
                alt="limit icon"
                filter="drop-shadow(0px 0px 20px rgb(0 136 204)) drop-shadow(0px 0px 20px rgb(0 136 204 / 0.2))"
                src="/ui/team-billing-limit.svg"
              />

              <Heading pb={3} data-testid="plan-limitation-header" size="lg">
                {title}
              </Heading>
              <Text
                pb={8}
                color="textSecondary"
                data-testid="plan-limitation-text"
              >
                {label &&
                  label.split("\n").reduce((memo, str, idx) => {
                    if (idx > 0) memo.push(<br key={`${idx}-br`} />);
                    memo.push(str);
                    return memo;
                  }, [] as React.ReactNode[])}
              </Text>

              {buttonType === "upgrade" && (
                <Link href={"/team?tab=plans" as "/team"}>
                  <Button
                    leftIcon={<Icon id="Ui/Upgrade" />}
                    size="lg"
                    w="100%"
                    onClick={handleCloseRequest}
                    data-testid="plan-limitation-upgrade-button"
                  >
                    {hasTrial
                      ? "Start Your Free Trial"
                      : "Upgrade Workspace Plan"}
                  </Button>
                </Link>
              )}

              {buttonType === "quota" && (
                <Link href="/team">
                  <Button
                    leftIcon={<Icon id="Ui/ArrowUp" height="16px" />}
                    size="lg"
                    w="100%"
                    onClick={handleCloseRequest}
                    data-testid="plan-limitation-increase-quota-button"
                  >
                    Increase Quota
                  </Button>
                </Link>
              )}

              {buttonType === "close" && (
                <Button size="lg" onClick={handleCloseRequest}>
                  OK
                </Button>
              )}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});
export default PlanQuotaLimitModal;
