import { useEffect, useRef, useState } from "react";
import { localStorageService } from "domains/infra/local-storage/localStorageService";
import { isEqual } from "lodash";

export enum PersistedStateKey {
  NUMBER_OF_COLUMNS = "numberOfColumns",
  GRID_VIEW = "gridView",
  COLLAPSED_TRAINING_PARAMETERS = "collapsedTrainingParameters",
  SIDEBAR_OPEN = "sidebarOpen",
  SAFETY_FILTER_ENABLED = "safetyFilterEnabled",
  SELECTED_TEAM = "selectedTeam",
  COLLAPSED_TRAINING_IMAGES = "collapsedTrainingImages",
  WAITING_SUBSCRIPTION = "waitingSubscription",
  FILE_MANAGER = "fileManager",
  FILE_MANAGER_GENERATOR = "fileManagerGenerator",
  FILE_MANAGER_IMAGE = "fileManagerImage",
  FILE_MANAGER_CANVAS = "fileManagerCanvas",
  PANEL_EXTENDED = "panelExtended",
  TRIAL_END = "trialEnd",
  CANVAS_CROP_IMAGE_WHEN_EXPORTING = "cropImageWhenExporting",
  GENERATORS_TAB = "generatorsTab",
  CANVAS_LAYER_PANEL_COLLAPSED = "canvasLayerPanelCollapsed",
  CANVAS_BRUSH_TOOL = "canvasBrushTool",
  CANVAS_TOOL_OPTIONS = "canvasToolOptions",
}

export default function usePersistedState<T>(
  key: PersistedStateKey,
  options: {
    defaultValue: T;
    transform?: (value: any) => T | undefined;
  }
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const localStorageRef = useRef(localStorageService);
  const keyRef = useRef(key);

  const [state, setState] = useState<T>(() => {
    const persistedState = localStorageRef.current.get(keyRef.current);
    const finalState =
      options.transform && persistedState !== undefined
        ? options.transform(persistedState)
        : persistedState;
    return (finalState !== undefined ? finalState : options.defaultValue) as T;
  });

  useEffect(() => {
    const persistedState = localStorageRef.current.get(keyRef.current);
    if (!isEqual(persistedState, state)) {
      localStorageRef.current.set(keyRef.current, state);
    }
  }, [state]);

  return [state, setState];
}
