import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useHorizontalScroll } from "domains/commons/hooks/useHorizontalScroll";
import { useScrollbarSize } from "domains/commons/hooks/useScrollbarSize";
import { FileImageType } from "domains/file-manager/interfaces";
import { extraTheme } from "domains/theme";
import { NsfwIndicator } from "domains/ui/components/NsfwIndicator";
import {
  PANEL_COLLAPSED_WIDTH,
  PANEL_EXTENDED_WIDTH,
} from "domains/ui/components/Panel";
import { useUser } from "domains/user/hooks/useUser";
import { GetAssetsByAssetIdApiResponse } from "infra/api/generated/api";
import { VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import { Flex, Image, Spinner } from "@chakra-ui/react";

export const IMAGE_HEIGHT = 80;
export const GAP = 6;

export interface AssetZoomImageListProps {
  currentAsset: GetAssetsByAssetIdApiResponse["asset"] | undefined;
  isPanelExtended: boolean;
  isFullScreenActive: boolean;
  assets: FileImageType[];
  hasMore: boolean;
  loadMore: () => void | undefined;
  revealed: string[];
  onReveal: (id: string) => void;
}

const AssetZoomImageList = ({
  currentAsset,
  isPanelExtended,
  isFullScreenActive,
  assets,
  hasMore,
  loadMore,
  revealed,
  onReveal,
}: AssetZoomImageListProps) => {
  const virtualLoaderRef = useRef(null);
  const router = useRouter();
  const scrollbarSize = useScrollbarSize();
  const horizontalScrollRef = useHorizontalScroll();
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [scrolledTo, setScrolledTo] = useState<string | undefined>();
  const { isNsfwFilterEnabled } = useUser();

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const assetsLength = useMemo(
    () => assets.length + (hasMore ? 1 : 0),
    [assets.length, hasMore]
  );

  const getItemSize = useCallback(
    (index: number) => {
      const height = assets[index]?.height ?? 512;
      const width = assets[index]?.width ?? 512;
      const ratio = width / height;
      return (
        IMAGE_HEIGHT * ratio + GAP + (index === assetsLength - 1 ? GAP : 0)
      );
    },
    [assets, assetsLength]
  );

  const handleImageClick = useCallback(
    (index: number) => {
      if (currentAsset?.id !== assets[index].id) {
        router.query.openAssetId = assets[index].id;
        void router.push({
          query: router.query,
        });
      }
    },
    [assets, currentAsset?.id, router]
  );

  const renderImage = useCallback(
    ({ index, style }: { index: number; style: any }) => {
      const asset: FileImageType | undefined =
        index > assets.length - 1 ? undefined : assets[index];
      const isRevealed = asset ? revealed.includes(asset.id) : false;
      const shouldShowNsfwIndicator = asset
        ? isNsfwFilterEnabled && !!asset.meta.nsfw?.length && !isRevealed
        : false;
      const currentAssetProps =
        asset && currentAsset?.id === asset.id
          ? {
              borderWidth: 2,
              borderStyle: "solid",
              borderColor: "primary.500",
            }
          : {};

      return (
        <Flex
          align={"center"}
          justify={"center"}
          mr={index === assetsLength - 1 ? GAP / 4 : 0}
          ml={GAP / 4}
          style={style}
        >
          {index === assets.length ? (
            <Spinner />
          ) : asset?.thumbnail ? (
            <>
              <Image
                h={`${IMAGE_HEIGHT}px`}
                alt={"asset image"}
                src={
                  isRevealed
                    ? asset.thumbnail.replace("&blur=100", "")
                    : asset.thumbnail
                }
                {...currentAssetProps}
                cursor="pointer"
                onClick={() => {
                  handleImageClick(index);
                }}
              />
              {shouldShowNsfwIndicator && (
                <NsfwIndicator onClick={() => onReveal(asset.id)} />
              )}
            </>
          ) : (
            <Flex
              align={"center"}
              justify={"center"}
              w={`${getItemSize(index)}px`}
              h={`${IMAGE_HEIGHT}px`}
              bg={"black"}
            >
              <Spinner />
            </Flex>
          )}
        </Flex>
      );
    },
    [
      assets,
      assetsLength,
      currentAsset?.id,
      handleImageClick,
      revealed,
      onReveal,
    ]
  );

  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    setHasRendered(true);
  }, []);

  useEffect(() => {
    const targetIndex = currentAsset?.id
      ? assets.findIndex(
          (item, index, arr) => arr[index - 0]?.id === currentAsset?.id
        )
      : -1;
    const targetAsset = assets[targetIndex];

    if (
      targetAsset &&
      hasRendered &&
      targetAsset.id !== scrolledTo &&
      targetAsset.meta.metadata.type.includes("inference")
    ) {
      if (targetIndex !== -1) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        virtualLoaderRef?.current?._listRef.scrollToItem(targetIndex, "center");
        setScrolledTo(targetAsset.id);
      } else if (hasMore && loadMore) {
        loadMore();
      }
    }
  }, [hasRendered, assets, currentAsset, hasMore, loadMore, scrolledTo]);

  return (
    <InfiniteLoader
      ref={virtualLoaderRef}
      itemCount={hasMore ? assetsLength + 1 : assetsLength}
      loadMoreItems={loadMore}
      isItemLoaded={(index) => index < assetsLength}
    >
      {({ onItemsRendered, ref }) => {
        return (
          <VariableSizeList
            outerRef={horizontalScrollRef}
            width={
              screenWidth -
              (isFullScreenActive
                ? 0
                : isPanelExtended
                ? PANEL_EXTENDED_WIDTH
                : PANEL_COLLAPSED_WIDTH)
            }
            height={`${IMAGE_HEIGHT + GAP * 2 + scrollbarSize.height}px`}
            itemCount={assetsLength}
            itemSize={getItemSize}
            layout="horizontal"
            onItemsRendered={onItemsRendered}
            ref={ref}
            style={{
              transition: extraTheme.transitions.fast,
            }}
          >
            {renderImage}
          </VariableSizeList>
        );
      }}
    </InfiniteLoader>
  );
};

export default AssetZoomImageList;
