import { UseAllAssetsArgs } from "domains/assets/hooks/useAllAssets";

export type FilterAssetTypeKey =
  | "all"
  | "inference"
  | "backgroundRemoved"
  | "pixelated"
  | "uploaded"
  | "upscaled"
  | "detection";
export interface FilterAssetType {
  label: string;
  value: UseAllAssetsArgs["type"];
}
export type FilterAssetTypeMap = {
  [key in FilterAssetTypeKey]: FilterAssetType;
};
export type FilterAssetTypeValue = FilterAssetTypeKey;

export const filterAssetTypes: FilterAssetTypeMap = {
  all: { label: "All", value: undefined },
  inference: { label: "Generated", value: "inference" },
  backgroundRemoved: {
    label: "No Background",
    value: "background-removal",
  },
  pixelated: { label: "Pixelated", value: "pixelization" },
  uploaded: { label: "Uploads", value: "uploaded" },
  upscaled: { label: "Upscales", value: "upscale" },
  detection: { label: "Mode map", value: "detection" },
};
export const filterAssetTypeKeys = Object.keys(
  filterAssetTypes
) as FilterAssetTypeKey[];
